import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { sectionIcon, staffIcon } from '../../ui/icons/business-objects';
import renderPhoneNumber from '../../ui/phone-number/render-phone-number';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';
import SectionReference from '../section/section-reference/section-reference';

export function StaffSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'STAFF', `${result.resultType} is no instance of StaffSearchResult`);
  const staff = result.result;
  const { t } = useTranslation('staff');

  return (
    <SearchResult
      icon={staffIcon}
      title={t('staffFormat', { firstName: staff.firstName, surname: staff.surname })}
      link={`/staff/${staff.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          { label: t('email'), element: staff.emailAddress.email },
          { label: t('site'), element: staff.site },
          { label: t('room'), element: staff.room },
          {
            label: t('guestServices.languages'),
            element: staff.languages
              ?.map(
                (l) =>
                  `${t(`guestServices.languageOptions.${l.language}`)} (${t('guestServices.languageLevel')}: ${t(
                    `guestServices.languageLevelOptions.${l.languageLevel}`,
                  )})`,
              )
              .join(', '),
          },
          {
            label: t('phone_number.header'),
            element: (staff.phoneNumbers?.map(renderPhoneNumber) ?? []).join(', '),
          },
          {
            label: t('guestServices.level'),
            element: staff.level != null ? t(`guestServices.levelOptions.${staff.level}`) : null,
          },
          {
            label: t('status'),
            element: t(`statusOptions.${staff.status}`),
          },
          {
            label: t('source'),
            element: t(`sourceOptions.${staff.source}`),
          },
        ]}
      />
      {staff.belongsToSections?.map((rel) => (
        <SearchResultConnection
          key={rel.section.id}
          onTargetClick={onClick}
          connection={
            <SearchResultAttributes attributes={[{ label: t('belongs_to_section.jobTitle'), element: rel.jobTitle }]} />
          }
          targetIcon={sectionIcon}
        >
          <SectionReference size="sm" sectionReference={rel.section} hideIcon />
        </SearchResultConnection>
      ))}
    </SearchResult>
  );
}
