/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PersonPermissionDto } from './PersonPermissionDto';
import {
    instanceOfPersonPermissionDto,
    PersonPermissionDtoFromJSON,
    PersonPermissionDtoFromJSONTyped,
    PersonPermissionDtoToJSON,
} from './PersonPermissionDto';

/**
 * @type PermissionDto
 * 
 * @export
 */
export type PermissionDto = { name: 'PERSON' } & PersonPermissionDto;

export function PermissionDtoFromJSON(json: any): PermissionDto {
    return PermissionDtoFromJSONTyped(json, false);
}

export function PermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDto {
    if (json == null) {
        return json;
    }
    switch (json['name']) {
        case 'PERSON':
            return {...PersonPermissionDtoFromJSONTyped(json, true), name: 'PERSON'};
        default:
            throw new Error(`No variant of PermissionDto exists with 'name=${json['name']}'`);
    }
}

export function PermissionDtoToJSON(value?: PermissionDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['name']) {
        case 'PERSON':
            return PersonPermissionDtoToJSON(value);
        default:
            throw new Error(`No variant of PermissionDto exists with 'name=${value['name']}'`);
    }

}

