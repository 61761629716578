import React from 'react';
import invariant from 'tiny-invariant';
import { mailingIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import AnchorMailingModuleControl from './mailing-editor/mailing-module/anchor-mailing-module-control';
import FlexMailingModuleControl from './mailing-editor/mailing-module/flex-mailing-module-control';
import PictureMailingModuleControl from './mailing-editor/mailing-module/picture-mailing-module-control';
import PortraitMailingModuleControl from './mailing-editor/mailing-module/portrait-mailing-module-control';
import SalutationMailingModule from './mailing-editor/mailing-module/salutation-mailing-module-control';
import TextMailingModuleControl from './mailing-editor/mailing-module/text-mailing-module-control';
import { MAILING_MODULE_TYPE_EXTENSION } from './mailing-module-type-extension';
import MailingSearchResult from './mailing-search-result';

export default function MailingPluginProvider(registry: PluginRegistry) {
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'TEXT',
    moduleControl({ module, name }) {
      invariant(module.moduleType === 'TEXT', 'Type must be text');

      return <TextMailingModuleControl module={module} name={name} />;
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'SALUTATION',
    moduleControl({ template, name }) {
      return <SalutationMailingModule name={name} template={template} />;
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'PICTURE',
    moduleControl({ name }) {
      return <PictureMailingModuleControl name={name} />;
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'PORTRAIT',
    moduleControl({ name }) {
      return <PortraitMailingModuleControl name={name} />;
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'ANCHOR',
    moduleControl({ name }) {
      return <AnchorMailingModuleControl name={name} />;
    },
  });

  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'FLEX',
    moduleControl({ template, module, name, connectionId, constraints }) {
      invariant(module.moduleType === 'FLEX', 'Type must be flex');

      return (
        <FlexMailingModuleControl
          template={template}
          settings={module}
          connectionId={connectionId}
          name={name}
          constraints={constraints}
        />
      );
    },
  });
  registry.register(SEARCH_RESULT_CONTENT, { type: 'MAILING', Component: MailingSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'MAILING',
    icon: mailingIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.MAILING" />,
  });
}
