import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum } from '../../../../api';
import { DisableableButton } from '../../../../ui/disableable-button/disableable-button';
import PageContent from '../../../../ui/page/page-content';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchCompany } from '../../../company/company-queries';
import usePermission from '../../../staff-account/use-permission/use-permission';
import CompanyFormatEditorDialog from '../company-format-editor/company-format-editor';
import CompanyFormatViewer from './company-format-viewer';

export default function CompanyFormatPageContent() {
  const { t } = useTranslation('format');
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Missing companyId');
  const company = useFetcher(fetchCompany, { id: companyId });
  const companyReference = { id: company.id, name: company.name };
  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  const canEditCompany = usePermission('COMPANY:STANDARD-EDIT');

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="md" as="h3">
              {t('lister.title')}
            </Heading>
          </HStack>
          <Spacer />
          {canEditCompany && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('companyConnections.add')}
              isDisabled={company.status !== CompanyDtoStatusEnum.ACTIVE}
              disableReason={t('companyConnections.disabled')}
            >
              {t('companyConnections.add')}
            </Button>
          )}
        </Flex>
        <CompanyFormatViewer company={company} />
        <CompanyFormatEditorDialog
          companyReference={companyReference}
          isOpen={editDialogIsOpen}
          onClose={onEditDialogClose}
        />
      </Grid>
    </PageContent>
  );
}
