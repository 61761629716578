import { Menu, MenuItem, MenuList, Portal, Tooltip } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductDto, ProductStatusDto } from '../../../api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { productFetcher } from '../product-queries';
import ProductStatusToggleButton from '../product-status-toggle/product-status-toggle-button';

export function ProductMenuList({ product }: { product: ProductDto }) {
  const { t } = useTranslation(['common', 'product']);
  const redColorIfNotArchived = product.status === ProductStatusDto.ARCHIVED ? 'text' : 'text.error';
  return (
    <Menu>
      <BurgerMenuButton />
      <Portal>
        <MenuList>
          <Tooltip label={product.status === ProductStatusDto.ARCHIVED ? t('product:action.reactivateTooltip') : ''}>
            <MenuItem
              as={ProductStatusToggleButton}
              product={product}
              onSuccess={() => productFetcher.mutate()}
              textColor={redColorIfNotArchived}
              icon={<FontAwesomeIcon icon={product.status === ProductStatusDto.ARCHIVED ? faToggleOn : faToggleOff} />}
            >
              {product.status === ProductStatusDto.ARCHIVED
                ? t('product:action.reactivate')
                : t('product:action.archive')}
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Portal>
    </Menu>
  );
}
