import { Draft, produce } from 'immer';
import React, { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { MailingDtoStatusEnum, RecipientLinkDto } from '../../../../api';
import { DataTableColumn } from '../../../../ui/data-table';
import DataTable from '../../../../ui/data-table/data-table';
import { isParentDataTableColumn, ParentDataTableColumn } from '../../../../ui/data-table/data-table-column';
import Optional from '../../../../ui/optional/optional';
import useFetcher from '../../../../util/swr/use-fetcher';
import { isPersonOnGuestListRecipientLink } from '../../../guest-list/guest-list-provider';
import { useGuestListSettingsByEventId } from '../../../guest-list/use-guest-list-settings/use-guest-list-settings';
import useRecipientLister from '../../../mailing/common/use-recipient-lister';
import { fetchMailing } from '../../../mailing/mailing-queries';
import DeleteRecipientButton from '../../../mailing/mailing-recipient-lister/delete-recipient-button';
import { isPersonRecipientLink } from '../../../mailing/mailing-recipient-util/person-recipient-link';
import { usePersonOnGuestListMailingRecipientColumns } from '../use-person-on-guest-list-mailing-recipient-columns';

type EmergencyGuestListMailingRecipientListerProps = {
  mailingId: string;
  eventId: string;
};

export default function EmergencyGuestListMailingRecipientLister({
  mailingId,
  eventId,
}: EmergencyGuestListMailingRecipientListerProps) {
  const { state, setState, recipientPage } = useRecipientLister(mailingId);
  const guestList = useGuestListSettingsByEventId(eventId);
  invariant(guestList != null, 'guestList must be present');

  const mailing = useFetcher(fetchMailing, { id: mailingId });
  const personOnGuestListColumns = usePersonOnGuestListMailingRecipientColumns(guestList);

  const columns = useMemo<DataTableColumn<RecipientLinkDto>[]>(() => {
    return produce(personOnGuestListColumns, (draft) => {
      const emailColumn = draft.find(
        (column): column is Draft<ParentDataTableColumn<RecipientLinkDto>> =>
          isParentDataTableColumn(column) && column?.key === 'linkedRecipient.email',
      );

      invariant(emailColumn != null, 'Email column must be defined');

      const originalRenderCell = emailColumn.renderCell;

      emailColumn.renderCell = (recipientLink, index) => (
        <Optional>
          {isPersonRecipientLink(recipientLink)
            ? recipientLink.linkedRecipient.emailAddresses
                ?.filter((address) => address.label === 'CONTACT')
                .map((address) => address.email)
                .join(', ')
            : originalRenderCell?.(recipientLink, index)}
        </Optional>
      );

      draft.push({
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          mailing.permissions.canEdit && (
            <DeleteRecipientButton
              mailingId={mailingId}
              recipientId={recipientLink.id}
              recipientType={recipientLink.type}
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
              displayName={
                isPersonRecipientLink(recipientLink)
                  ? `${recipientLink.linkedRecipient.firstName} ${recipientLink.linkedRecipient.surname}`
                  : isPersonOnGuestListRecipientLink(recipientLink)
                    ? `${recipientLink.linkedRecipient.person!.firstName} ${
                        recipientLink.linkedRecipient.person!.surname
                      }`
                    : ''
              }
            />
          ),
      });
    });
  }, [personOnGuestListColumns, mailing.permissions.canEdit, mailing.status, mailingId]);

  return (
    <>
      <DataTable
        rowKey={(recipient) => recipient.id!}
        page={recipientPage}
        columns={columns}
        state={state}
        onStateChange={setState}
        isPageable
      />
    </>
  );
}
