import React from 'react';
import { useTranslation } from 'react-i18next';
import { personIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { ACCOUNT_VIEWER_ITEM_EXTENSION } from '../account/account-viewer/account-viewer-item-extension';
import { AWARD_LISTER_COLUMN_EXTENSION } from '../award/award-lister/award-lister-column-extension';
import {
  PERMISSION_CLASS_GROUP_EXTENSION,
  PermissionClassGroupExtension,
} from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { STAFF_LISTER_COLUMN_EXTENSION } from '../staff/staff-lister/staff-lister-column-extension';
import { STAFF_VIEWER_ITEM_EXTENSION } from '../staff/staff-viewer/staff-viewer-item-extension';
import { accountViewerPersonItem } from './person-account-extension/person-account-viewer-item';
import { awardListerWinnerColumn } from './person-award-extension/person-award-column';
import { PersonSearchResult } from './person-search-result';
import { staffListerPersonColumn } from './person-staff-extension/person-staff-column';
import { staffViewerPersonItem } from './person-staff-extension/person-staff-viewer-item';

export default function PersonPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'PERSON', Component: PersonSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'PERSON',
    icon: personIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.PERSON" />,
  });
  registry.register(STAFF_LISTER_COLUMN_EXTENSION, staffListerPersonColumn);
  registry.register(AWARD_LISTER_COLUMN_EXTENSION, awardListerWinnerColumn);
  registry.register(STAFF_VIEWER_ITEM_EXTENSION, staffViewerPersonItem);
  registry.register(ACCOUNT_VIEWER_ITEM_EXTENSION, accountViewerPersonItem);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, usePersonPermissionClassGroupExtension);
}

export function usePersonPermissionClassGroupExtension(): PermissionClassGroupExtension<'PERSON'> {
  const { t } = useTranslation(['person', 'common']);

  return {
    name: 'PERSON',
    label: t('person:person'),
    getActionLabel: (action) => t(`person:permission.${action}`),
    getAdditionalRequirement: (action) => t(`person:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`person:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
