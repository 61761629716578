import { Button, Flex, Grid, Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { AccessGroupStatusDto } from '../../../../api';
import { DisableableButton } from '../../../../ui/disableable-button/disableable-button';
import PageContent from '../../../../ui/page/page-content';
import useFetcher from '../../../../util/swr/use-fetcher';
import useEditionFromSearchParams from '../../../../util/use-edition-from-search-param/use-edition-from-search-param';
import { fetchAccessGroup } from '../../../access-group/access-group-queries';
import EditionSelectButton from '../../../edition/edition-select-button';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import usePermission from '../../../staff-account/use-permission/use-permission';
import AccessGroupPersonEditorDialog from './access-group-person-editor-dialog';
import AccessGroupPersonViewer from './access-group-person-viewer';

export default function AccessGroupPersonPageContent() {
  const { t } = useTranslation('person');
  const { accessGroupId } = useParams<{ accessGroupId: string }>();
  invariant(accessGroupId, 'Missing accessGroupId');
  const accessGroup = useFetcher(fetchAccessGroup, { id: accessGroupId });

  const { isOpen: editDialogIsOpen, onOpen: onEditDialogOpen, onClose: onEditDialogClose } = useDisclosure();

  const activeEdition = useActiveEdition();
  const { edition, setEdition } = useEditionFromSearchParams(activeEdition);

  const canEditPerson = usePermission('PERSON:STANDARD-EDIT');

  return (
    <PageContent useFullWidth>
      <Grid gridRowGap={6} maxH="full" gridTemplateRows="auto 1fr">
        <Flex as="header">
          <HStack spacing={4}>
            <Heading size="md" as="h3">
              {t('lister.title')}
            </Heading>
            <EditionSelectButton edition={edition} onChange={setEdition} buttonLabel={edition.name} />
          </HStack>
          <Spacer />
          {accessGroup.permissions?.canEdit && canEditPerson && (
            <Button
              as={DisableableButton}
              variant="primary"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onEditDialogOpen}
              aria-label={t('accessGroupConnections.add')}
              isDisabled={accessGroup.status !== AccessGroupStatusDto.ACTIVE}
              disableReason={t('accessGroupConnections.disabled')}
            >
              {t('accessGroupConnections.add')}
            </Button>
          )}
        </Flex>
        <AccessGroupPersonViewer accessGroup={accessGroup} edition={edition} />
        <AccessGroupPersonEditorDialog
          accessGroup={accessGroup}
          isOpen={editDialogIsOpen}
          onClose={onEditDialogClose}
        />
      </Grid>
    </PageContent>
  );
}
