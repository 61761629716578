/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupStatusDto } from './AccessGroupStatusDto';
import {
    AccessGroupStatusDtoFromJSON,
    AccessGroupStatusDtoFromJSONTyped,
    AccessGroupStatusDtoToJSON,
} from './AccessGroupStatusDto';
import type { LiveTicketingPriorityDto } from './LiveTicketingPriorityDto';
import {
    LiveTicketingPriorityDtoFromJSON,
    LiveTicketingPriorityDtoFromJSONTyped,
    LiveTicketingPriorityDtoToJSON,
} from './LiveTicketingPriorityDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An access group.
 * @export
 * @interface AccessGroupDto
 */
export interface AccessGroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof AccessGroupDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof AccessGroupDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof AccessGroupDto
     */
    permissions?: PermissionsDto;
    /**
     * The name of the access group.
     * @type {string}
     * @memberof AccessGroupDto
     */
    title: string;
    /**
     * The english name of the access group.
     * @type {string}
     * @memberof AccessGroupDto
     */
    englishTitle?: string;
    /**
     * 
     * @type {LiveTicketingPriorityDto}
     * @memberof AccessGroupDto
     */
    liveTicketingPriority: LiveTicketingPriorityDto;
    /**
     * 
     * @type {AccessGroupStatusDto}
     * @memberof AccessGroupDto
     */
    status: AccessGroupStatusDto;
}

/**
 * Check if a given object implements the AccessGroupDto interface.
 */
export function instanceOfAccessGroupDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('liveTicketingPriority' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function AccessGroupDtoFromJSON(json: any): AccessGroupDto {
    return AccessGroupDtoFromJSONTyped(json, false);
}

export function AccessGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'title': json['title'],
        'englishTitle': json['englishTitle'] == null ? undefined : json['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoFromJSON(json['liveTicketingPriority']),
        'status': AccessGroupStatusDtoFromJSON(json['status']),
    };
}

export function AccessGroupDtoToJSON(value?: AccessGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'liveTicketingPriority': LiveTicketingPriorityDtoToJSON(value['liveTicketingPriority']),
        'status': AccessGroupStatusDtoToJSON(value['status']),
    };
}

