import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { FormatStatusDto, TagRelationTypeDto } from '../../../../api';
import formatApi from '../../../../data-access/format-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { formatIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePromise from '../../../../util/use-promise/use-promise';
import { FormatPageRoute } from '../../../format/format-page/format-page-route';
import { fetchFormat } from '../../../format/format-queries';

const FormatTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const formatTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <FormatPageRoute />,
    children: [
      {
        path: 'tags',
        element: <FormatTagContent />,
        handle: {
          helmet: <FormatTagsHelmet />,
        },
      },
    ],
  },
];

export default formatTagRoutes;

function FormatTagContent() {
  const relationType = TagRelationTypeDto.FORMAT;

  const params = useParams<{ formatId: string }>();
  const formatId = params.formatId;
  invariant(formatId, 'Empty formatId');

  const format = usePromise((signal) => formatApi.fetchFormat({ id: formatId }, { signal }), [formatId]);
  const formatRef = {
    id: formatId,
    name: format?.name,
  };

  const fromAnnotation = formatRef.name!;

  return (
    <FormatTagRelationContent
      isAddingTagPossible={format?.status == FormatStatusDto.ACTIVE}
      relationRef={formatRef}
      relationType={relationType}
      fromIcon={formatIcon}
      fromAnnotation={fromAnnotation}
    />
  );
}

function FormatTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ formatId: string }>();
  invariant(params.formatId, 'Empty formatId');
  const format = useFetcher(fetchFormat, { id: params.formatId });

  return (
    <Helmet
      title={t('relations.format.helmet', {
        name: format.name,
      })}
    />
  );
}
