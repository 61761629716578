/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { PlanDto } from './PlanDto';
import {
    PlanDtoFromJSON,
    PlanDtoFromJSONTyped,
    PlanDtoToJSON,
} from './PlanDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface PlacementTemplateDto
 */
export interface PlacementTemplateDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PlacementTemplateDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof PlacementTemplateDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof PlacementTemplateDto
     */
    permissions?: PermissionsDto;
    /**
     * The unique name.
     * @type {string}
     * @memberof PlacementTemplateDto
     */
    name: string;
    /**
     * 
     * @type {PlanDto}
     * @memberof PlacementTemplateDto
     */
    plan?: PlanDto;
}

/**
 * Check if a given object implements the PlacementTemplateDto interface.
 */
export function instanceOfPlacementTemplateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function PlacementTemplateDtoFromJSON(json: any): PlacementTemplateDto {
    return PlacementTemplateDtoFromJSONTyped(json, false);
}

export function PlacementTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacementTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'name': json['name'],
        'plan': json['plan'] == null ? undefined : PlanDtoFromJSON(json['plan']),
    };
}

export function PlacementTemplateDtoToJSON(value?: PlacementTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'name': value['name'],
        'plan': PlanDtoToJSON(value['plan']),
    };
}

