import { useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, PathValue, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactSelectProps } from '../../select/use-select-props';
import ValueSelect, { ValueSelectProps } from '../../select/value-select';

export interface ValueSelectControlProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ValueSelectProps<TOption>, 'onChange' | 'value' | 'name'>,
    Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'>,
    ReactSelectProps {
  label?: string;
  onChange?(value: TOption | null): void;
}

function ValueSelectControl<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    control,
    label,
    rules,
    defaultValue,
    isRequired,
    onChange,
    ...props
  }: ValueSelectControlProps<TOption, TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useTranslation('common');
  const rulesWithRequired = {
    required: isRequired ? t('validation_error.required', { field: label }) : undefined,
    ...rules,
  };
  const { field, fieldState } = useController({
    name,
    control,
    rules: rulesWithRequired,
    defaultValue: defaultValue as PathValue<TFieldValues, TName> | undefined,
  });

  const mergedRef = useMergeRefs(field.ref, ref);

  const handleChange = (value: TOption | null) => {
    field.onChange(value);
    onChange?.(value);
  };

  return (
    <ValueSelect {...field} {...props} onChange={handleChange} ref={mergedRef} isInvalid={fieldState.error != null} />
  );
}

export default React.forwardRef(ValueSelectControl);
