import { Link as ChakraLink, LinkProps as ChakraLinkProps, forwardRef } from '@chakra-ui/react';
import usePermission, { Permission } from '../../feature/permission/use-permission';

export interface LinkProps extends ChakraLinkProps {
  necessaryPermission?: Permission;
}

const Link = forwardRef<LinkProps, 'a'>(({ necessaryPermission, ...props }, ref) => {
  const hasPermission = usePermission();

  const canClickLink = necessaryPermission == null || hasPermission(necessaryPermission);

  return canClickLink ? <ChakraLink {...props} ref={ref} /> : <>{props.children}</>;
});

export default Link;
