import mailingApi from '../../data-access/mailing-api';
import recipientApi from '../../data-access/recipient-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const mailingFetcher = createFetcherScope(mailingApi);
export const searchMailings = mailingFetcher.createFetcher(mailingApi.searchMailings);
export const fetchMailing = mailingFetcher.createFetcher(mailingApi.fetchMailing);
export const fetchMailingHistory = mailingFetcher.createFetcher(mailingApi.fetchMailingHistory);
export const fetchMailingAnalytics = mailingFetcher.createFetcher(mailingApi.fetchMailingAnalytics);

export const apiKeyFetcher = createFetcherScope(mailingApi);
export const fetchApiKey = apiKeyFetcher.createFetcher(mailingApi.getApiKeyBySection);

export const recipientFetcher = createFetcherScope(recipientApi);
export const fetchRecipients = recipientFetcher.createFetcher(recipientApi.fetchRecipients);
export const fetchPossibleRecipients = recipientFetcher.createFetcher(recipientApi.fetchPossibleRecipients);
export const fetchRecipientHistory = recipientFetcher.createFetcher(recipientApi.fetchRecipientHistory);
