import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useParams } from 'react-router-dom';
import { EditionDto, EditionReferenceDto, StandDto } from '../../../api';
import standApi from '../../../data-access/stand-api';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import now from '../../../util/now';
import useFetcher from '../../../util/swr/use-fetcher';
import EditionSelectControl from '../../edition/edition-select/edition-select-control';
import { getStandId } from '../common/get-stand-id';
import { fetchStand } from '../stand-queries';

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchStand.preload({ id: getStandId(params) });
};

export interface CopyStandButtonProps extends React.ComponentPropsWithoutRef<'button'> {}

const CopyStandButton = React.forwardRef<HTMLButtonElement, CopyStandButtonProps>(({ children, ...props }, ref) => {
  const stand = useFetcher(fetchStand, { id: getStandId(useParams()) });

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <button ref={ref} {...props} onClick={onOpen}>
        {children}
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <CopyStandDialog stand={stand} onClose={onClose} />
      </Modal>
    </>
  );
});

export default CopyStandButton;

interface CopyStandDialogProps {
  stand: StandDto;
  onClose: () => void;
}
function CopyStandDialog({ stand, onClose }: CopyStandDialogProps) {
  const { t } = useTranslation(['common', 'stand']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const showSuccessToast = useToast({
    id: 'stand-copy-success-toast',
    status: 'success',
  });

  const form = useForm<CopyStandDto>({
    mode: 'all',
    defaultValues: { standId: stand.id },
  });
  const formIsValid = form.formState.isValid;

  const validateNotSameEdition = (edition: EditionDto) => {
    return edition.id != stand.edition.id || t('stand:copyStand.sameEditionError');
  };

  const validateNameNotInEdition = async (edition: EditionDto) => {
    return (
      !(await standApi.nameExistsInEdition({ name: stand.name, standId: stand.id, editionId: edition.id })) ||
      t('stand:copyStand.sameNameError')
    );
  };

  const onStandCopy = async ({ standId, edition }: CopyStandDto) => {
    await standApi.copyStand({ standCopyRequestDto: { standId, editionId: edition.id } });

    showSuccessToast({
      title: t('stand:copyStand.success.title'),
      description: t('stand:copyStand.success.description'),
    });

    onClose();
  };

  return (
    <ModalContent>
      <FormProvider<CopyStandDto> {...form}>
        <Form<CopyStandDto> onValid={onStandCopy} initialFocusRef={initialFocusRef}>
          <ModalHeader>
            {t('stand:copyStand.title', {
              name: stand.name,
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditionSelectControl
              label={t('stand:edition')}
              name="edition"
              isRequired
              filter={[`dateRange.end,gte,${new Date(now()).toISOString().slice(0, 10)}`]} // needs only date, without time
              rules={{ validate: { validateNotSameEdition, validateNameNotInEdition } }}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!formIsValid}>
                {t('common:action.save')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </ModalContent>
  );
}

interface CopyStandDto {
  standId: string;
  edition: EditionReferenceDto;
}
