/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';

/**
 * 
 * @export
 * @interface AutomaticConnectionsDto
 */
export interface AutomaticConnectionsDto {
    /**
     * 
     * @type {string}
     * @memberof AutomaticConnectionsDto
     */
    connectionType: AutomaticConnectionsDtoConnectionTypeEnum;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof AutomaticConnectionsDto
     */
    companyConnection?: CompanyReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AutomaticConnectionsDto
     */
    type?: AutomaticConnectionsDtoTypeEnum;
}


/**
 * @export
 */
export const AutomaticConnectionsDtoConnectionTypeEnum = {
    COMPANY: 'COMPANY',
    NONE: 'NONE'
} as const;
export type AutomaticConnectionsDtoConnectionTypeEnum = typeof AutomaticConnectionsDtoConnectionTypeEnum[keyof typeof AutomaticConnectionsDtoConnectionTypeEnum];

/**
 * @export
 */
export const AutomaticConnectionsDtoTypeEnum = {
    STUDENT: 'STUDENT',
    BOOTH_STAFF: 'BOOTH_STAFF',
    PRESS: 'PRESS'
} as const;
export type AutomaticConnectionsDtoTypeEnum = typeof AutomaticConnectionsDtoTypeEnum[keyof typeof AutomaticConnectionsDtoTypeEnum];


/**
 * Check if a given object implements the AutomaticConnectionsDto interface.
 */
export function instanceOfAutomaticConnectionsDto(value: object): boolean {
    if (!('connectionType' in value)) return false;
    return true;
}

export function AutomaticConnectionsDtoFromJSON(json: any): AutomaticConnectionsDto {
    return AutomaticConnectionsDtoFromJSONTyped(json, false);
}

export function AutomaticConnectionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticConnectionsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionType': json['connectionType'],
        'companyConnection': json['companyConnection'] == null ? undefined : CompanyReferenceDtoFromJSON(json['companyConnection']),
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function AutomaticConnectionsDtoToJSON(value?: AutomaticConnectionsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'connectionType': value['connectionType'],
        'companyConnection': CompanyReferenceDtoToJSON(value['companyConnection']),
        'type': value['type'],
    };
}

