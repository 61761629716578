import {
  LanguagesDto,
  PersonCommentDtoPurposeEnum,
  GenderIdentityDto,
  PersonDtoOfficiallyRegisteredGenderEnum,
  PersonDtoPostNominalEnum,
  PersonDtoPublishStageNameEnum,
  PersonDtoSalutationEnum,
  TitleDto,
  PersonDtoTypesEnum,
  PersonMarkerDto,
  PersonStatusDto,
  ProfessionDto,
  WorkingSectorDto,
} from '../../api';
// markers need to be sorted alphabetically
export const markers = [
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_FOR_ENGAGEMENT_FEDERAL_AFFAIRS_AND_MEDIA,
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_FOR_CULTURE_AND_EUROPE,
  PersonMarkerDto.HOUSE_OF_REPRESENTATIVES_COMMITTEE,
  PersonMarkerDto.BUND,
  PersonMarkerDto.BUNDESTAG_BUDGET_COMMITTEE,
  PersonMarkerDto.BUNDESTAG_CULTURE_COMMITTEE,
  PersonMarkerDto.BUNDESTAG_COMMITTEE,
  PersonMarkerDto.SERVICE_PROVIDER,
  PersonMarkerDto.SPONSOR,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_BERLINALE_SHORTS,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_FORUM,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_GENERATION,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_GERMAN_CINEMA_LOLA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_PANORAMA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE_PERSPECTIVE_GERMAN_CINEMA,
  PersonMarkerDto.IFB_SELECTION_COMMITTEE,
  PersonMarkerDto.IFB_CONSULTANT,
  PersonMarkerDto.IFB_DELEGATES,
  PersonMarkerDto.KBB_WITHOUT_IFB,
  PersonMarkerDto.KBB_SUPERVISORY_BOARD,
  PersonMarkerDto.KBB_FORMER_EMPLOYEES,
  PersonMarkerDto.KBB_MANAGEMENT,
  PersonMarkerDto.MEDIENBOARD_BERLIN_BRANDENBURG,
  PersonMarkerDto.POLITICS_INTERNATIONAL,
  PersonMarkerDto.POLITICS_NATIONAL,
  PersonMarkerDto.PRESS,
  PersonMarkerDto.VIP_ARTISTIC_DIRECTOR,
];

export const types = [PersonDtoTypesEnum.STAFF, PersonDtoTypesEnum.SERVICE_PROVIDER, PersonDtoTypesEnum.PROFESSIONAL];

export const salutationOptions = [
  PersonDtoSalutationEnum.DEFAULT,
  PersonDtoSalutationEnum.INVERTED,
  PersonDtoSalutationEnum.INDIVIDUAL,
];

export const titleOptions = [
  TitleDto.AMBASSADOR,
  TitleDto.CHANCELLOR,
  TitleDto.FEDERAL_MINISTER,
  TitleDto.FEDERAL_PRESIDENT,
  TitleDto.PRESIDENT_OF_THE_BUNDESTAG,
  TitleDto.MAYOR_MAYORESS,
  TitleDto.ENVOY,
  TitleDto.MINISTER,
  TitleDto.MINISTERIAL_DIRECTOR,
  TitleDto.MINISTERIAL_DIRECTOR_RETIRED,
  TitleDto.SENIOR_MINISTERIAL_COUNSELLOR,
  TitleDto.MINISTER_PRESIDENT,
  TitleDto.GOVERNING_MAYOR,
  TitleDto.GOVERNING_MAYOR_OF_BERLIN,
  TitleDto.SENATOR,
  TitleDto.SENATOR_RETIRED,
  TitleDto.MINISTER_OF_STATE,
  TitleDto.MINISTER_OF_STATE_RETIRED,
  TitleDto.STATE_SECRETARY,
  TitleDto.DEPUTY_MINISTER,
  TitleDto.SENATE_DIRECTOR,
  TitleDto.GOVERNING_MAYOR_RETIRED,
  TitleDto.DIPL_ING,
  TitleDto.DR,
  TitleDto.DR_H_C,
  TitleDto.DR_MED,
  TitleDto.PROF,
  TitleDto.PROF_DR,
  TitleDto.PROF_DR_DR_H_C_MULT,
  TitleDto.PROF_DR_MED,
  TitleDto.A_D_PROF_DR_H_C,
];

export const postNominalOptions = [
  PersonDtoPostNominalEnum.MDA,
  PersonDtoPostNominalEnum.MDB,
  PersonDtoPostNominalEnum.MDL,
  PersonDtoPostNominalEnum.MEP,
  PersonDtoPostNominalEnum.MP,
];

export const genderIdentityOptions = [
  GenderIdentityDto.NOT_SPECIFIED,
  GenderIdentityDto.FEMALE,
  GenderIdentityDto.MALE,
  GenderIdentityDto.FREE_HAND_TEXT,
];

export const officiallyRegisteredGenderOptions = [
  PersonDtoOfficiallyRegisteredGenderEnum.FEMALE,
  PersonDtoOfficiallyRegisteredGenderEnum.MALE,
  PersonDtoOfficiallyRegisteredGenderEnum.NON_BINARY,
  PersonDtoOfficiallyRegisteredGenderEnum.X,
];

export const publishStageNamesOptions = [
  PersonDtoPublishStageNameEnum.ONLY_NAME,
  PersonDtoPublishStageNameEnum.ONLY_STAGE_NAME,
  PersonDtoPublishStageNameEnum.NAME_AND_STAGE_NAME,
];

export const contactLanguageOptions = [LanguagesDto.GERMAN, LanguagesDto.ENGLISH];

export const commentPurposeOptions = [
  PersonCommentDtoPurposeEnum.GLOBAL,
  PersonCommentDtoPurposeEnum.GUEST_MANAGEMENT,
  PersonCommentDtoPurposeEnum.EVENT_PROTOCOL,
  PersonCommentDtoPurposeEnum.INTENDANZ,
];

export const editableStatus: PersonStatusDto[] = [
  PersonStatusDto.ACTIVE,
  PersonStatusDto.INACTIVE,
  PersonStatusDto.HISTORIC,
];

export const PROFESSION_OPTIONS = [
  ProfessionDto.AGENT,
  ProfessionDto.ANIMATION_ARTIST,
  ProfessionDto.ART_DIRECTOR,
  ProfessionDto.MEDICAL_DOCTOR,
  ProfessionDto.AUDIENCE_DESIGNER,
  ProfessionDto.PRODUCTION_DESIGNER,
  ProfessionDto.CONSULTANT,
  ProfessionDto.BLOGGER,
  ProfessionDto.AMBASSADOR,
  ProfessionDto.EMBASSY_COUNSELLOR,
  ProfessionDto.ACCOUNTANT,
  ProfessionDto.FEDERAL_MINISTER,
  ProfessionDto.SET_DESIGNER,
  ProfessionDto.MAYOR,
  ProfessionDto.CASTING_AGENT,
  ProfessionDto.CASTING_DIRECTOR,
  ProfessionDto.CHOREOGRAPHER,
  ProfessionDto.CREW_MEMBER,
  ProfessionDto.DIPLOMAT,
  ProfessionDto.INTERPRETER,
  ProfessionDto.SCRIPT_CONSULTANT,
  ProfessionDto.SCREENWRITER,
  ProfessionDto.EDITOR,
  ProfessionDto.BUYER,
  ProfessionDto.CREATOR,
  ProfessionDto.FESTIVAL_PROGRAMMER,
  ProfessionDto.FILMFESTIVAL_DIRECTOR,
  ProfessionDto.FILM_COMMISSIONER,
  ProfessionDto.FILM_HISTORIAN,
  ProfessionDto.FILM_TECHNICIAN,
  ProfessionDto.FINANCIER,
  ProfessionDto.PHOTOGRAPHER,
  ProfessionDto.CEO,
  ProfessionDto.CHARGE_DAFFAIRES,
  ProfessionDto.GRAPHIC_DESIGN,
  ProfessionDto.PRODUCTION_MANAGER,
  ProfessionDto.JOURNALIST,
  ProfessionDto.CINEMATOGRAPHER,
  ProfessionDto.CINEMA_MANAGER,
  ProfessionDto.SOUND_ARTIST,
  ProfessionDto.COLOURIST,
  ProfessionDto.COMPOSER,
  ProfessionDto.COSTUME_DESIGNER,
  ProfessionDto.CREATIVE_PRODUCER,
  ProfessionDto.CULTURAL_ATTACHE,
  ProfessionDto.CURATOR,
  ProfessionDto.ARTIST,
  ProfessionDto.ACQUISITIONS_EXECUTIVE,
  ProfessionDto.DEVELOPMENT_EXECUTIVE,
  ProfessionDto.MANAGER,
  ProfessionDto.MARKETING,
  ProfessionDto.MAKE_UP_ARTIST,
  ProfessionDto.MUSIC_ARTIST,
  ProfessionDto.GAFFER,
  ProfessionDto.ORGANISER,
  ProfessionDto.PERSONAL_ASSISTANT,
  ProfessionDto.POLITICIAN,
  ProfessionDto.PR_MANAGER,
  ProfessionDto.PUBLICIST,
  ProfessionDto.MEDIA_SPOKESPERSON,
  ProfessionDto.PRODUCER,
  ProfessionDto.PROGRAMME_MANAGER,
  ProfessionDto.RESEARCHER,
  ProfessionDto.ATTORNEY,
  ProfessionDto.COMMISSIONING_EDITOR,
  ProfessionDto.ASSISTANT_DIRECTOR,
  ProfessionDto.DIRECTOR,
  ProfessionDto.CONSERVATOR,
  ProfessionDto.TALENT_AGENT,
  ProfessionDto.ACTOR,
  ProfessionDto.AUTHOR,
  ProfessionDto.SCRIPT_DOCTOR,
  ProfessionDto.SENATOR,
  ProfessionDto.SHOWRUNNER,
  ProfessionDto.OTHER,
  ProfessionDto.MINISTER_OF_STATE,
  ProfessionDto.SECRETARY_OF_STATE,
  ProfessionDto.STRATEGY_BUSINESS_DEVELOPMENT_EXECUTIVE,
  ProfessionDto.STUDENT,
  ProfessionDto.DUBBING_ACTOR,
  ProfessionDto.TECHNICIAN_TV_RADIO,
  ProfessionDto.SOUND_DESIGNER,
  ProfessionDto.SOUND_ENGINEER,
  ProfessionDto.SOUND_EDITOR,
  ProfessionDto.TV_PRESENTER,
  ProfessionDto.TRANSLATOR,
  ProfessionDto.SUBTITLES,
  ProfessionDto.PUBLISHER,
  ProfessionDto.DISTRIBUTOR,
  ProfessionDto.SALES_AGENT,
  ProfessionDto.SALES_EXECUTIVE,
  ProfessionDto.VFX_ARTIST,
  ProfessionDto.ACADEMIC_TEACHING_STAFF,
];

export const WORKING_SECTOR_OPTIONS = [
  WorkingSectorDto.ANIMATION,
  WorkingSectorDto.EDUCATION_TRAINING,
  WorkingSectorDto.ACQUISITION,
  WorkingSectorDto.STAGE,
  WorkingSectorDto.DOCUMENTARY_FORM,
  WorkingSectorDto.AR,
  WorkingSectorDto.EXPERIMENTAL_FILMS,
  WorkingSectorDto.TV_NO_PRESS,
  WorkingSectorDto.FESTIVAL_MARKET,
  WorkingSectorDto.FILM,
  WorkingSectorDto.FILM_SUPPORT_FUNDING,
  WorkingSectorDto.FINANCE,
  WorkingSectorDto.GAMING,
  WorkingSectorDto.INTERACTIVE_MEDIA,
  WorkingSectorDto.CAMERA,
  WorkingSectorDto.SHORT_FILMS,
  WorkingSectorDto.LITERATURE,
  WorkingSectorDto.MUSIC,
  WorkingSectorDto.ONLINE,
  WorkingSectorDto.PUBLIC_SECTOR,
  WorkingSectorDto.PRINT,
  WorkingSectorDto.PRODUCTION,
  WorkingSectorDto.RADIO_AUDIO,
  WorkingSectorDto.LAW_SECTOR,
  WorkingSectorDto.SERIES,
  WorkingSectorDto.SOCIAL_MEDIA,
  WorkingSectorDto.OTHER,
  WorkingSectorDto.FICTION,
  WorkingSectorDto.FEATURE_FILMS,
  WorkingSectorDto.TV_PRESS_ONLY,
  WorkingSectorDto.DISTRIBUTION,
  WorkingSectorDto.SALES,
  WorkingSectorDto.VOD,
  WorkingSectorDto.VR,
  WorkingSectorDto.WEB_TV,
  WorkingSectorDto.WEB_SERIES,
  WorkingSectorDto.WEB_VIDEO,
  WorkingSectorDto.PROMOTION,
];
