import { Namespace, ParseKeys, TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TranslateProps<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TKey extends ParseKeys<TNamespace, {}, TPrefix>,
  TNamespace extends Namespace,
  TPrefix = undefined,
> =
  | {
      i18nKey: TKey;
      ns: TNamespace;
      suspense?: boolean;
    }
  | {
      children: (t: TFunction<TNamespace, TPrefix>) => React.ReactNode;
      ns: TNamespace;
    };

export default function Translate<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TKey extends ParseKeys<TNamespace, {}, TPrefix>,
  TNamespace extends Namespace,
  TPrefix = undefined,
>(props: TranslateProps<TKey, TNamespace, TPrefix>) {
  // Fixes a bug in i18next when changing namespaces on a mounted useTranslation hook.
  // A new key forces the component to reinitialize (new state and all)
  return <TranslateImpl key={JSON.stringify(props.ns)} {...props} />;
}

function TranslateImpl(props: TranslateProps<any, any, any>) {
  const { t, ready } = useTranslation(props.ns, {
    useSuspense: 'suspense' in props ? props.suspense : true,
  });

  if (!ready) {
    return null;
  }

  return <>{'i18nKey' in props ? t(props.i18nKey) : props.children(t)}</>;
}
