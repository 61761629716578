import { landingPageDesignIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

// TODO Replace with LandingPageReferenceDto once we defined it.
interface LandingPageReference {
  id: string;
  name: string;
}

interface LandingPageReferenceProps extends Omit<ReferenceProps, 'children' | 'icon' | 'to'> {
  reference: LandingPageReference;
}

export default function LandingPageReference({ reference, ...props }: LandingPageReferenceProps) {
  return (
    <Reference icon={landingPageDesignIcon} to={`/landing-pages/${reference.id}`} {...props}>
      {reference.name}
    </Reference>
  );
}
