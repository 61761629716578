import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { PersonReferenceDto, PersonStatusDto, TagRelationTypeDto } from '../../../../api';
import personApi from '../../../../data-access/person-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { personIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePromise from '../../../../util/use-promise/use-promise';
import PersonPage from '../../../person/person-page/person-page';
import { fetchPerson } from '../../../person/person-queries';

const PersonTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const personTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <PersonPage />,
    children: [
      {
        path: 'tags',
        element: <PersonTagContent />,
        handle: {
          helmet: <PersonTagsHelmet />,
        },
      },
    ],
  },
];

export default personTagRoutes;

function PersonTagContent() {
  const relationType = TagRelationTypeDto.PERSON;

  const { t } = useTranslation('person');
  const params = useParams<{ personId: string }>();
  const personId = params.personId;
  invariant(personId, 'Empty personId');

  const person = usePromise((signal) => personApi.fetchPerson({ id: personId }, { signal }), [personId]);
  const personRef = {
    id: personId,
    firstName: person?.firstName,
    surname: person?.surname,
    personKey: person?.personKey,
  };

  const fromAnnotation = t('personFormatWithKey', personRef as Required<PersonReferenceDto>);

  return (
    <PersonTagRelationContent
      isAddingTagPossible={person?.status == PersonStatusDto.ACTIVE}
      relationRef={personRef}
      relationType={relationType}
      fromIcon={personIcon}
      fromAnnotation={fromAnnotation}
    />
  );
}

function PersonTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ personId: string }>();
  invariant(params.personId, 'Empty personId');
  const person = useFetcher(fetchPerson, { id: params.personId });

  return (
    <Helmet
      title={t('relations.person.helmet', {
        firstName: person.firstName,
        surname: person.surname,
      })}
    />
  );
}
