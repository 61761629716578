import { Stack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PersonDto, PersonDtoPublishStageNameEnum } from '../../../api';
import { ElementFormModal, ElementListControl, useElementForm } from '../../../ui/form/element-control';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { LayoutType } from '../../common/LayoutType';
import { publishStageNamesOptions } from '../person-enum-constants';
import { DuplicateWarning } from './duplicate-warning';

/**
 * Properties for alternative names control.
 */
export interface AlternativeNameControlProps {
  layout: LayoutType;
}

/**
 * Control to edit person's alternatives names.
 */
export default function AlternativeNameControl({ layout }: AlternativeNameControlProps) {
  const { t } = useTranslation('person');

  return (
    <Stack spacing={4}>
      <Stack direction={layout} spacing={6} alignItems="flex-start">
        <InputFormControl<PersonDto> label={t('stage_name')} name="stageName" maxLength={50} setNullOnEmptyInput />
        <FormControl
          label={t('publishStageName')}
          name="publishStageName"
          w={layout === LayoutType.NORMAL ? '40%' : '100%'}
        >
          <ValueSelectControl<PersonDtoPublishStageNameEnum>
            options={publishStageNamesOptions}
            renderLabel={(value) => t(`publishStageNameLabels.${value}`)}
            name="publishStageName"
          />
        </FormControl>
      </Stack>

      <Stack direction={layout} spacing={6} alignItems="flex-start" w="100%">
        <ElementListControl<string>
          layout={layout}
          name="alternativeNames"
          label={t('alternative_name.label')}
          helperPopover={<HelperPopover children={t('alternative_names_popover')} />}
          addLabel={t('alternative_name.add_label')}
          editLabel={t('alternative_name.edit_label')}
          deleteLabel={t('alternative_name.delete_label')}
          renderDeleteMessage={(alternativeName) => (
            <Trans t={t} i18nKey="alternative_name.delete_message" values={{ alternativeName }} />
          )}
          formModal={<AlternativeNameFormModal />}
        />
      </Stack>
      <DuplicateWarning />
    </Stack>
  );
}

interface AlternativeNameFormModel {
  alternativeName: string;
}

function AlternativeNameFormModal() {
  const { t } = useTranslation('person');
  const { element: alternativeName, onSubmit } = useElementForm<string>();
  const element = React.useMemo(
    () => (alternativeName != null ? { alternativeName } : alternativeName),
    [alternativeName],
  );
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = (element: AlternativeNameFormModel) => {
    onSubmit(element.alternativeName);
  };

  return (
    <ElementFormModal<AlternativeNameFormModel>
      element={element}
      onSubmit={handleSubmit}
      initialFocusRef={initialFocusRef}
    >
      <InputFormControl<AlternativeNameFormModel>
        name="alternativeName"
        label={t('alternative_name.input_label')}
        maxLength={100}
        isRequired
        ref={initialFocusRef}
      />
    </ElementFormModal>
  );
}
