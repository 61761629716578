import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  ThemingProps,
  useMergeRefs,
} from '@chakra-ui/react';
import React from 'react';
import { FieldValues, Path, useController, ValidateResult } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControl from './form-control';
import { getHelperTextSize } from './helper-text-size';

const MAX_INPUT_NUMBER = 999_999_999_999_999;

export interface NumberInputControlProps<TFieldValues> {
  name: Path<TFieldValues>;
  label: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  min?: number;
  max?: number;
  helperText?: React.ReactNode;
  helperPopover?: React.ReactElement;
  placeholder?: string;
  showStepper?: boolean;
  size?: NumberInputProps['size'];
  variant?: ThemingProps['variant'];
  validate?(number: number, data: TFieldValues): ValidateResult | Promise<ValidateResult>;
  deps?: string[];
  onChange?(value: number): void;
  resetToZero?: boolean;
}

function NumberInputControl<TFieldValues extends FieldValues>(
  {
    isRequired,
    name,
    label,
    isDisabled,
    min,
    max,
    helperText,
    helperPopover,
    placeholder,
    showStepper = false,
    size,
    variant,
    validate,
    deps,
    onChange,
    resetToZero,
  }: NumberInputControlProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const helperTextFontSize = getHelperTextSize(size);

  const { t } = useTranslation('common');
  const { field, fieldState } = useController<TFieldValues>({
    name,
    rules: {
      deps,
      required: isRequired ? t('validation_error.required', { field: label }) : undefined,
      min:
        min != null
          ? {
              value: min,
              message: t('validation_error.min_number', {
                field: label,
                min,
              }),
            }
          : undefined,
      max:
        max != null
          ? {
              value: max,
              message: t('validation_error.max_number', {
                field: label,
                max,
              }),
            }
          : undefined,
      pattern: {
        value: /^\d*$/,
        message: t('validation_error.integer_number'),
      },
      validate,
    },
  });
  const mergedRef = useMergeRefs(field.ref, ref);

  function handleBlur() {
    if (resetToZero && field.value == null) {
      field.onChange(0);
    }

    field.onBlur();
  }

  return (
    <FormControl
      name={name}
      label={label}
      isDisabled={isDisabled}
      isRequired={isRequired}
      size={size}
      variant={variant}
      helperPopover={helperPopover}
    >
      <NumberInput
        keepWithinRange={false}
        clampValueOnBlur={false}
        max={max}
        min={min}
        size={size}
        variant={variant}
        {...field}
        value={field.value ?? ''}
        onBlur={handleBlur}
        onChange={(valueAsString, valueAsNumber) => {
          if (Math.abs(valueAsNumber) > MAX_INPUT_NUMBER) {
            return;
          }
          const numberValue = isNaN(valueAsNumber) ? null : valueAsNumber;

          field.onChange(numberValue);
          onChange?.(valueAsNumber);
        }}
      >
        <NumberInputField
          required={isRequired}
          ref={mergedRef}
          placeholder={placeholder}
          paddingInlineEnd={showStepper ? undefined : 4}
        />
        {showStepper && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
      {helperText != null && fieldState.error == null && (
        <FormHelperText fontSize={helperTextFontSize}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default React.forwardRef(NumberInputControl);
