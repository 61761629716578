import { ParseKeys } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import COMPANY_CHILD_ROUTES from './company-child-routes';
import { fetchCompany } from './company-queries';

const CompanyEditor = React.lazy(() => import('./company-editor/company-editor'));
const CompanyLister = React.lazy(() => import('./company-lister/company-lister'));
const CompanyViewer = React.lazy(() => import('./company-viewer/company-viewer'));
const CompanyPage = React.lazy(() => import('./company-page/company-page'));
const CompanyCommentViewer = React.lazy(() => import('./company-comment/company-comment-viewer'));
const CompanyHistoryRoute = React.lazy(() => import('./company-history/company-history-route'));

const companyRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'companies',
    handle: {
      breadcrumb: <Translate ns="company" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        element: <CompanyLister />,
        handle: {
          helmet: <Translate ns="company">{(t) => <Helmet title={t('lister.helmet')} />}</Translate>,
        },
      },
      {
        path: 'new',
        handle: {
          breadcrumb: <Translate ns="company" i18nKey="action.new" />,
          helmet: <Translate ns="company">{(t) => <Helmet title={t('editor.helmetNew')} />}</Translate>,
        },
        element: <CompanyEditor />,
      },
      {
        path: ':companyId',
        handle: {
          breadcrumb: <CompanyBreadcrumb />,
        },
        children: [
          {
            path: '',
            element: <CompanyPage />,
            children: [
              {
                path: '',
                element: <CompanyViewer />,
                handle: {
                  helmet: <CompanyHelmet i18nKey="viewer.helmet" />,
                },
              },
              {
                path: 'comments',
                element: <CompanyCommentViewer />,
                handle: {
                  helmet: <CompanyHelmet i18nKey="comments.helmet" />,
                },
              },
              {
                path: 'history',
                element: <CompanyHistoryRoute />,
                handle: {
                  helmet: <CompanyHelmet i18nKey="history.helmet" />,
                },
              },
            ],
          },
          {
            path: 'edit',
            element: <CompanyEditor />,
            handle: {
              helmet: <CompanyHelmet i18nKey="editor.helmetEdit" />,
            },
          },
          ...registry.getAll(COMPANY_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

interface CompanyHelmetProps {
  i18nKey: ParseKeys<'company'>;
}

function CompanyHelmet({ i18nKey }: CompanyHelmetProps) {
  const { t } = useTranslation('company');
  const params = useParams<{ companyId: string }>();
  invariant(params.companyId, 'Empty companyId');
  const { name } = useFetcher(fetchCompany, { id: params.companyId }, { suspense: true });

  return <Helmet title={t(i18nKey, { company: name })} />;
}

function CompanyBreadcrumb() {
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: companyId }, { suspense: true });

  return <>{`${company.name}`}</>;
}

export default companyRoutes;
