import { forwardRef, HTMLChakraProps, MenuItem, useDisclosure } from '@chakra-ui/react';
import { faUserMinus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import permissionRoleApi from '../../../data-access/permission-role-api';
import ConfirmDialog from '../../../ui/dialog/confirm-dialog';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import { sectionStaffFetcher } from '../../relations/section-staff/section-staff-queries';
import getSectionId from '../../section/common/get-section-id';
import { SectionStaffListItemDto } from '../../../api';

export default function UnassignStaffsToStaffRoleMenuItem({ staff }: { staff: SectionStaffListItemDto }) {
  const { t } = useTranslation('permission_role');

  return (
    <MenuItem
      as={UnassignStaffsToRoleButton}
      staffs={[staff.id]}
      isDisabled={staff.role?.isAdminRole}
      disableReason={t('assignStaffRole.isAdmin')}
      aria-label={t('assignStaffRole.unassignRole')}
      icon={<FontAwesomeIcon icon={faUserMinus} />}
    >
      {t('assignStaffRole.unassignRole')}
    </MenuItem>
  );
}

interface UnassignStaffsToStaffRoleButtonProps extends HTMLChakraProps<'button'> {
  staffs: string[];
  disableReason: string;
  onSuccess?: () => void;
}

function UnassignStaffsToStaffRoleButtonWithRef(
  { children, staffs, disableReason, onClick, onSuccess, ...props }: UnassignStaffsToStaffRoleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { t } = useTranslation(['permission_role', 'common']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sectionId = getSectionId(useParams());

  const mergedOnClick = (x: MouseEvent<HTMLButtonElement>) => {
    onOpen();
    onClick?.(x);
  };

  const showSuccessToast = useToast({
    status: 'success',
  });

  const onSubmit = async () => {
    await permissionRoleApi.removeStaffsFromStaffRole({
      removeStaffsFromStaffRoleRequestDto: {
        sectionId,
        staffIds: staffs,
      },
    });

    showSuccessToast({
      title: t('permission_role:assignStaffRole.unassignSuccess'),
    });

    // noinspection ES6MissingAwait
    sectionStaffFetcher.mutate();

    onSuccess?.();
    onClose();
  };

  return (
    <>
      <DisableableButton ref={ref} {...props} onClick={mergedOnClick} disableReason={disableReason}>
        {children}
      </DisableableButton>
      <ConfirmDialog
        onClose={(submit: boolean) => {
          if (submit) {
            onSubmit();
          }
          onClose();
        }}
        isOpen={isOpen}
        confirmActionLabel={t('permission_role:assignStaffRole.unassignRole')}
      >
        {t('permission_role:assignStaffRole.confirmUnassignText')}
      </ConfirmDialog>
    </>
  );
}

export const UnassignStaffsToRoleButton = forwardRef<UnassignStaffsToStaffRoleButtonProps, 'button'>(
  UnassignStaffsToStaffRoleButtonWithRef,
);
