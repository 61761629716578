import { ChakraProps, HStack, StackProps, Text } from '@chakra-ui/react';
import React, { cloneElement, ForwardedRef, forwardRef, isValidElement } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { Permission } from '../../feature/permission/use-permission';
import { BussinessObjectIconDefinition, renderBusinessObjectIcon } from '../icons/business-objects';
import Link from '../link/link';

export interface ReferenceProps extends ChakraProps {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  spacing?: StackProps['spacing'];
  icon: BussinessObjectIconDefinition;
  hideIcon?: boolean;
  to?: LinkProps['to'];
  id?: string;
  necessaryPermission?: Permission;
}

const Reference = forwardRef(
  (
    { children, size = 'md', to, spacing, icon, hideIcon = false, id, necessaryPermission, ...props }: ReferenceProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    let element = (
      <HStack fontSize={size} spacing={spacing ?? (size === 'sm' ? 1 : 2)}>
        {!hideIcon && renderBusinessObjectIcon(icon, { fixedWidth: true })}
        {isValidElement(children) ? (
          children
        ) : (
          <Text as="span" fontWeight="medium" noOfLines={1} maxWidth="240">
            {children}
          </Text>
        )}
      </HStack>
    );

    if (to != null) {
      element = (
        <Link as={RouterLink} to={to} size={size} necessaryPermission={necessaryPermission}>
          {element}
        </Link>
      );
    }

    return cloneElement(element, {
      display: to != null ? 'inline-block' : 'inline-flex',
      id,
      ref,
      ...props,
    });
  },
);

export default Reference;
