import { accountIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import AccountSearchResult from './account-search-result';

export default function AccountPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'ACCOUNT', Component: AccountSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'ACCOUNT',
    icon: accountIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.ACCOUNT" />,
  });
}
