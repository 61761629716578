/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivationToggleCheckDto,
  AddGuestBlockerDto,
  AddPeopleDto,
  BooleanDto,
  EditPersonOnGuestListDto,
  ExportFormatDto,
  FailureDto,
  GuestListCapacityDto,
  GuestListExportStatusCheckDto,
  GuestListSettingsDto,
  GuestListSettingsStatusChangeDto,
  GuestListStatisticsDto,
  HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto,
  HistoryPageDto,
  InvalidGuestListSettingsDto,
  OpenCloseAllAllocationsDto,
  PageableDto,
  PersonOnGuestListExportDto,
  PersonOnGuestListGroupDto,
  PersonOnGuestListPageDto,
  PersonOnGuestListStatusCheckDto,
  ReplaceMergedDuplicatesOnGuestListWithNewPersonDto,
  SavePersonOnGuestListGroupDto,
  StringPageDto,
} from '../models/index';
import {
    ActivationToggleCheckDtoFromJSON,
    ActivationToggleCheckDtoToJSON,
    AddGuestBlockerDtoFromJSON,
    AddGuestBlockerDtoToJSON,
    AddPeopleDtoFromJSON,
    AddPeopleDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    EditPersonOnGuestListDtoFromJSON,
    EditPersonOnGuestListDtoToJSON,
    ExportFormatDtoFromJSON,
    ExportFormatDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    GuestListCapacityDtoFromJSON,
    GuestListCapacityDtoToJSON,
    GuestListExportStatusCheckDtoFromJSON,
    GuestListExportStatusCheckDtoToJSON,
    GuestListSettingsDtoFromJSON,
    GuestListSettingsDtoToJSON,
    GuestListSettingsStatusChangeDtoFromJSON,
    GuestListSettingsStatusChangeDtoToJSON,
    GuestListStatisticsDtoFromJSON,
    GuestListStatisticsDtoToJSON,
    HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoFromJSON,
    HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    InvalidGuestListSettingsDtoFromJSON,
    InvalidGuestListSettingsDtoToJSON,
    OpenCloseAllAllocationsDtoFromJSON,
    OpenCloseAllAllocationsDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PersonOnGuestListExportDtoFromJSON,
    PersonOnGuestListExportDtoToJSON,
    PersonOnGuestListGroupDtoFromJSON,
    PersonOnGuestListGroupDtoToJSON,
    PersonOnGuestListPageDtoFromJSON,
    PersonOnGuestListPageDtoToJSON,
    PersonOnGuestListStatusCheckDtoFromJSON,
    PersonOnGuestListStatusCheckDtoToJSON,
    ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoFromJSON,
    ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoToJSON,
    SavePersonOnGuestListGroupDtoFromJSON,
    SavePersonOnGuestListGroupDtoToJSON,
    StringPageDtoFromJSON,
    StringPageDtoToJSON,
} from '../models/index';

export interface AddPeopleRequest {
    guestListId: string;
    addPeopleDto: AddPeopleDto;
}

export interface CanAddPersonRequest {
    guestListId: string;
    personId: string;
}

export interface CanDeleteAllocationRequest {
    guestListId: string;
    allocationId: string;
}

export interface CanToggleActivationRequest {
    guestListId: string;
}

export interface CheckExportStatusRequest {
    guestListId: string;
}

export interface CheckPersonOnGuestListStatusRequest {
    guestListId: string;
    personOnGuestListId: string;
}

export interface CreateGuestListRequest {
    eventId: string;
    guestListSettingsDto: GuestListSettingsDto;
}

export interface DeleteCouplingGroupRequest {
    couplingGroupId: string;
}

export interface DeletePersonOnGuestListRequest {
    guestListId: string;
    personOnGuestListId: string;
}

export interface EditPersonOnGuestListRequest {
    guestListId: string;
    personOnGuestListId: string;
    editPersonOnGuestListDto: EditPersonOnGuestListDto;
}

export interface ExportPeopleOnGuestListRequest {
    guestListId: string;
    personOnGuestListExportDto: PersonOnGuestListExportDto;
    pageable?: PageableDto;
}

export interface FetchCoupingGroupHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchGuestListCapacityRequest {
    guestListId: string;
}

export interface FetchGuestListHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchGuestListSettingsRequest {
    id: string;
}

export interface FetchGuestListSettingsForEventRequest {
    eventId: string;
}

export interface FetchGuestListStatisticsRequest {
    guestListId: string;
}

export interface FetchPersonOnGuestListGroupRequest {
    guestListId: string;
    personOnGuestListId: string;
}

export interface FetchPersonOnGuestListHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchPlaceholderNotInGroupWithPersonRequest {
    guestListId: string;
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface GetExportFormatsRequest {
    guestListId: string;
}

export interface HasBothMergedPeopleButNotTheMergedPersonOnGuestListRequest {
    guestListId: string;
}

export interface OpenCloseAllAllocationsRequest {
    guestListId: string;
    openCloseAllAllocationsDto: OpenCloseAllAllocationsDto;
}

export interface ReplaceMergedDuplicatesOnGuestListWithNewPersonRequest {
    guestListId: string;
    replaceMergedDuplicatesOnGuestListWithNewPersonDto: ReplaceMergedDuplicatesOnGuestListWithNewPersonDto;
}

export interface SavePersonOnGuestListGroupRequest {
    guestListId: string;
    savePersonOnGuestListGroupDto: SavePersonOnGuestListGroupDto;
}

export interface SearchAllocationsRequest {
    guestListId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchPeopleOnGuestListRequest {
    guestListId: string;
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface ToggleGuestListRequest {
    guestListId: string;
    guestListSettingsStatusChangeDto: GuestListSettingsStatusChangeDto;
}

export interface UpdateGuestListSettingsRequest {
    id: string;
    guestListSettingsDto: GuestListSettingsDto;
}

/**
 * 
 */
export class GuestListApi extends runtime.BaseAPI {

    /**
     * Add people to guest list.
     */
    async addPeopleRaw(requestParameters: AddPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling addPeople().'
            );
        }

        if (requestParameters['addPeopleDto'] == null) {
            throw new runtime.RequiredError(
                'addPeopleDto',
                'Required parameter "addPeopleDto" was null or undefined when calling addPeople().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/add-people`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPeopleDtoToJSON(requestParameters['addPeopleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add people to guest list.
     */
    async addPeople(requestParameters: AddPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPeopleRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether the specified person can be added to the guest list.
     */
    async canAddPersonRaw(requestParameters: CanAddPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling canAddPerson().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling canAddPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/can-add-person`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified person can be added to the guest list.
     */
    async canAddPerson(requestParameters: CanAddPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.canAddPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the specified allocation can be deleted.
     */
    async canDeleteAllocationRaw(requestParameters: CanDeleteAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling canDeleteAllocation().'
            );
        }

        if (requestParameters['allocationId'] == null) {
            throw new runtime.RequiredError(
                'allocationId',
                'Required parameter "allocationId" was null or undefined when calling canDeleteAllocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['allocationId'] != null) {
            queryParameters['allocationId'] = requestParameters['allocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/can-delete-allocation`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified allocation can be deleted.
     */
    async canDeleteAllocation(requestParameters: CanDeleteAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.canDeleteAllocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get if the guest list activation can be toggled.
     */
    async canToggleActivationRaw(requestParameters: CanToggleActivationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivationToggleCheckDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling canToggleActivation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/can-toggle-activation`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivationToggleCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get if the guest list activation can be toggled.
     */
    async canToggleActivation(requestParameters: CanToggleActivationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivationToggleCheckDto> {
        const response = await this.canToggleActivationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get result if guest list can be exported.
     */
    async checkExportStatusRaw(requestParameters: CheckExportStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListExportStatusCheckDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling checkExportStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/checkExportStatus`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListExportStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get result if guest list can be exported.
     */
    async checkExportStatus(requestParameters: CheckExportStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListExportStatusCheckDto> {
        const response = await this.checkExportStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current status of the guest.
     */
    async checkPersonOnGuestListStatusRaw(requestParameters: CheckPersonOnGuestListStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonOnGuestListStatusCheckDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling checkPersonOnGuestListStatus().'
            );
        }

        if (requestParameters['personOnGuestListId'] == null) {
            throw new runtime.RequiredError(
                'personOnGuestListId',
                'Required parameter "personOnGuestListId" was null or undefined when calling checkPersonOnGuestListStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{guestListId}/{personOnGuestListId}/check-status`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))).replace(`{${"personOnGuestListId"}}`, encodeURIComponent(String(requestParameters['personOnGuestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonOnGuestListStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the guest.
     */
    async checkPersonOnGuestListStatus(requestParameters: CheckPersonOnGuestListStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonOnGuestListStatusCheckDto> {
        const response = await this.checkPersonOnGuestListStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new guest list.
     */
    async createGuestListRaw(requestParameters: CreateGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListSettingsDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling createGuestList().'
            );
        }

        if (requestParameters['guestListSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'guestListSettingsDto',
                'Required parameter "guestListSettingsDto" was null or undefined when calling createGuestList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/guest-list-settings`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestListSettingsDtoToJSON(requestParameters['guestListSettingsDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Create a new guest list.
     */
    async createGuestList(requestParameters: CreateGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListSettingsDto> {
        const response = await this.createGuestListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the coupling group.
     */
    async deleteCouplingGroupRaw(requestParameters: DeleteCouplingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['couplingGroupId'] == null) {
            throw new runtime.RequiredError(
                'couplingGroupId',
                'Required parameter "couplingGroupId" was null or undefined when calling deleteCouplingGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{couplingGroupId}/coupling-group`.replace(`{${"couplingGroupId"}}`, encodeURIComponent(String(requestParameters['couplingGroupId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the coupling group.
     */
    async deleteCouplingGroup(requestParameters: DeleteCouplingGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCouplingGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the person on guest list.
     */
    async deletePersonOnGuestListRaw(requestParameters: DeletePersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling deletePersonOnGuestList().'
            );
        }

        if (requestParameters['personOnGuestListId'] == null) {
            throw new runtime.RequiredError(
                'personOnGuestListId',
                'Required parameter "personOnGuestListId" was null or undefined when calling deletePersonOnGuestList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/person-on-guest-list/{personOnGuestListId}`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))).replace(`{${"personOnGuestListId"}}`, encodeURIComponent(String(requestParameters['personOnGuestListId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the person on guest list.
     */
    async deletePersonOnGuestList(requestParameters: DeletePersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePersonOnGuestListRaw(requestParameters, initOverrides);
    }

    /**
     * Edit person on guest list.
     */
    async editPersonOnGuestListRaw(requestParameters: EditPersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling editPersonOnGuestList().'
            );
        }

        if (requestParameters['personOnGuestListId'] == null) {
            throw new runtime.RequiredError(
                'personOnGuestListId',
                'Required parameter "personOnGuestListId" was null or undefined when calling editPersonOnGuestList().'
            );
        }

        if (requestParameters['editPersonOnGuestListDto'] == null) {
            throw new runtime.RequiredError(
                'editPersonOnGuestListDto',
                'Required parameter "editPersonOnGuestListDto" was null or undefined when calling editPersonOnGuestList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/person-on-guest-list/{personOnGuestListId}`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))).replace(`{${"personOnGuestListId"}}`, encodeURIComponent(String(requestParameters['personOnGuestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditPersonOnGuestListDtoToJSON(requestParameters['editPersonOnGuestListDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit person on guest list.
     */
    async editPersonOnGuestList(requestParameters: EditPersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editPersonOnGuestListRaw(requestParameters, initOverrides);
    }

    /**
     * Export peopleOnGuestList.
     */
    async exportPeopleOnGuestListRaw(requestParameters: ExportPeopleOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling exportPeopleOnGuestList().'
            );
        }

        if (requestParameters['personOnGuestListExportDto'] == null) {
            throw new runtime.RequiredError(
                'personOnGuestListExportDto',
                'Required parameter "personOnGuestListExportDto" was null or undefined when calling exportPeopleOnGuestList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/people-on-guest-list/export`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonOnGuestListExportDtoToJSON(requestParameters['personOnGuestListExportDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export peopleOnGuestList.
     */
    async exportPeopleOnGuestList(requestParameters: ExportPeopleOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportPeopleOnGuestListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history for coupling groups.
     */
    async fetchCoupingGroupHistoryRaw(requestParameters: FetchCoupingGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchCoupingGroupHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{id}/coupling-group/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history for coupling groups.
     */
    async fetchCoupingGroupHistory(requestParameters: FetchCoupingGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchCoupingGroupHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the capacity of the allocations in the guest list.
     */
    async fetchGuestListCapacityRaw(requestParameters: FetchGuestListCapacityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListCapacityDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling fetchGuestListCapacity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/guest-list-capacity`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListCapacityDtoFromJSON(jsonValue));
    }

    /**
     * Get the capacity of the allocations in the guest list.
     */
    async fetchGuestListCapacity(requestParameters: FetchGuestListCapacityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListCapacityDto> {
        const response = await this.fetchGuestListCapacityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the guest list.
     */
    async fetchGuestListHistoryRaw(requestParameters: FetchGuestListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGuestListHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the guest list.
     */
    async fetchGuestListHistory(requestParameters: FetchGuestListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchGuestListHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the guest list business object.
     */
    async fetchGuestListSettingsRaw(requestParameters: FetchGuestListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListSettingsDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchGuestListSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Get the guest list business object.
     */
    async fetchGuestListSettings(requestParameters: FetchGuestListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListSettingsDto> {
        const response = await this.fetchGuestListSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches guest list settings for event
     */
    async fetchGuestListSettingsForEventRaw(requestParameters: FetchGuestListSettingsForEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListSettingsDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchGuestListSettingsForEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/{eventId}/guest-list-settings`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Fetches guest list settings for event
     */
    async fetchGuestListSettingsForEvent(requestParameters: FetchGuestListSettingsForEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListSettingsDto> {
        const response = await this.fetchGuestListSettingsForEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the status statistics of the guest list and allocations.
     */
    async fetchGuestListStatisticsRaw(requestParameters: FetchGuestListStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListStatisticsDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling fetchGuestListStatistics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/guest-list-statistics`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListStatisticsDtoFromJSON(jsonValue));
    }

    /**
     * Get the status statistics of the guest list and allocations.
     */
    async fetchGuestListStatistics(requestParameters: FetchGuestListStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListStatisticsDto> {
        const response = await this.fetchGuestListStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the group of people on guest list belonging to the person on guest list id.
     */
    async fetchPersonOnGuestListGroupRaw(requestParameters: FetchPersonOnGuestListGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonOnGuestListGroupDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling fetchPersonOnGuestListGroup().'
            );
        }

        if (requestParameters['personOnGuestListId'] == null) {
            throw new runtime.RequiredError(
                'personOnGuestListId',
                'Required parameter "personOnGuestListId" was null or undefined when calling fetchPersonOnGuestListGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/{personOnGuestListId}/fetch-person-on-guest-list-group`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))).replace(`{${"personOnGuestListId"}}`, encodeURIComponent(String(requestParameters['personOnGuestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonOnGuestListGroupDtoFromJSON(jsonValue));
    }

    /**
     * Get the group of people on guest list belonging to the person on guest list id.
     */
    async fetchPersonOnGuestListGroup(requestParameters: FetchPersonOnGuestListGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonOnGuestListGroupDto> {
        const response = await this.fetchPersonOnGuestListGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history for persons on guest list.
     */
    async fetchPersonOnGuestListHistoryRaw(requestParameters: FetchPersonOnGuestListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPersonOnGuestListHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{id}/person-on-guest-list/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history for persons on guest list.
     */
    async fetchPersonOnGuestListHistory(requestParameters: FetchPersonOnGuestListHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchPersonOnGuestListHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all placeholder on a guest list without a person in its coupling group
     */
    async fetchPlaceholderNotInGroupWithPersonRaw(requestParameters: FetchPlaceholderNotInGroupWithPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonOnGuestListPageDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling fetchPlaceholderNotInGroupWithPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/fetch-placeholder-not-in-group-with-person`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonOnGuestListPageDtoFromJSON(jsonValue));
    }

    /**
     * Get all placeholder on a guest list without a person in its coupling group
     */
    async fetchPlaceholderNotInGroupWithPerson(requestParameters: FetchPlaceholderNotInGroupWithPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonOnGuestListPageDto> {
        const response = await this.fetchPlaceholderNotInGroupWithPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns available export formats.
     */
    async getExportFormatsRaw(requestParameters: GetExportFormatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExportFormatDto>>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling getExportFormats().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/people-on-guest-list/export-formats`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExportFormatDtoFromJSON));
    }

    /**
     * Returns available export formats.
     */
    async getExportFormats(requestParameters: GetExportFormatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExportFormatDto>> {
        const response = await this.getExportFormatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the guest list contains both sides of a merge are on the guest list but not the new person
     */
    async hasBothMergedPeopleButNotTheMergedPersonOnGuestListRaw(requestParameters: HasBothMergedPeopleButNotTheMergedPersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto>>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling hasBothMergedPeopleButNotTheMergedPersonOnGuestList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/has-both-merged-people-but-not-the-merged-person-on-guest-list`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HasBothMergedPeopleButNotTheMergedPersonOnGuestListDtoFromJSON));
    }

    /**
     * Get whether the guest list contains both sides of a merge are on the guest list but not the new person
     */
    async hasBothMergedPeopleButNotTheMergedPersonOnGuestList(requestParameters: HasBothMergedPeopleButNotTheMergedPersonOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HasBothMergedPeopleButNotTheMergedPersonOnGuestListDto>> {
        const response = await this.hasBothMergedPeopleButNotTheMergedPersonOnGuestListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Open or close all allocations belonging to the guest list
     */
    async openCloseAllAllocationsRaw(requestParameters: OpenCloseAllAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling openCloseAllAllocations().'
            );
        }

        if (requestParameters['openCloseAllAllocationsDto'] == null) {
            throw new runtime.RequiredError(
                'openCloseAllAllocationsDto',
                'Required parameter "openCloseAllAllocationsDto" was null or undefined when calling openCloseAllAllocations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/open-close-all-allocations`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpenCloseAllAllocationsDtoToJSON(requestParameters['openCloseAllAllocationsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Open or close all allocations belonging to the guest list
     */
    async openCloseAllAllocations(requestParameters: OpenCloseAllAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.openCloseAllAllocationsRaw(requestParameters, initOverrides);
    }

    /**
     * replace merged duplicates on guest list with new person
     */
    async replaceMergedDuplicatesOnGuestListWithNewPersonRaw(requestParameters: ReplaceMergedDuplicatesOnGuestListWithNewPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling replaceMergedDuplicatesOnGuestListWithNewPerson().'
            );
        }

        if (requestParameters['replaceMergedDuplicatesOnGuestListWithNewPersonDto'] == null) {
            throw new runtime.RequiredError(
                'replaceMergedDuplicatesOnGuestListWithNewPersonDto',
                'Required parameter "replaceMergedDuplicatesOnGuestListWithNewPersonDto" was null or undefined when calling replaceMergedDuplicatesOnGuestListWithNewPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/replace-merged-duplicates-on-guest-list-with-new-person`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceMergedDuplicatesOnGuestListWithNewPersonDtoToJSON(requestParameters['replaceMergedDuplicatesOnGuestListWithNewPersonDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * replace merged duplicates on guest list with new person
     */
    async replaceMergedDuplicatesOnGuestListWithNewPerson(requestParameters: ReplaceMergedDuplicatesOnGuestListWithNewPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.replaceMergedDuplicatesOnGuestListWithNewPersonRaw(requestParameters, initOverrides);
    }

    /**
     * Couple people on guest list to form a group.
     */
    async savePersonOnGuestListGroupRaw(requestParameters: SavePersonOnGuestListGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling savePersonOnGuestListGroup().'
            );
        }

        if (requestParameters['savePersonOnGuestListGroupDto'] == null) {
            throw new runtime.RequiredError(
                'savePersonOnGuestListGroupDto',
                'Required parameter "savePersonOnGuestListGroupDto" was null or undefined when calling savePersonOnGuestListGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/save-person-on-guest-list-group`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SavePersonOnGuestListGroupDtoToJSON(requestParameters['savePersonOnGuestListGroupDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Couple people on guest list to form a group.
     */
    async savePersonOnGuestListGroup(requestParameters: SavePersonOnGuestListGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.savePersonOnGuestListGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Search for allocations within given guest list.
     */
    async searchAllocationsRaw(requestParameters: SearchAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringPageDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling searchAllocations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/allocations`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for allocations within given guest list.
     */
    async searchAllocations(requestParameters: SearchAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringPageDto> {
        const response = await this.searchAllocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for peopleOnGuestList.
     */
    async searchPeopleOnGuestListRaw(requestParameters: SearchPeopleOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonOnGuestListPageDto>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling searchPeopleOnGuestList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/people-on-guest-list`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonOnGuestListPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for peopleOnGuestList.
     */
    async searchPeopleOnGuestList(requestParameters: SearchPeopleOnGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonOnGuestListPageDto> {
        const response = await this.searchPeopleOnGuestListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new guest list.
     */
    async templateGuestListSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListSettingsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list-settings/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new guest list.
     */
    async templateGuestListSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListSettingsDto> {
        const response = await this.templateGuestListSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Change the status of the guest list, deactivating or reactivating it
     */
    async toggleGuestListRaw(requestParameters: ToggleGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['guestListId'] == null) {
            throw new runtime.RequiredError(
                'guestListId',
                'Required parameter "guestListId" was null or undefined when calling toggleGuestList().'
            );
        }

        if (requestParameters['guestListSettingsStatusChangeDto'] == null) {
            throw new runtime.RequiredError(
                'guestListSettingsStatusChangeDto',
                'Required parameter "guestListSettingsStatusChangeDto" was null or undefined when calling toggleGuestList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list/{guestListId}/toggle-activation`.replace(`{${"guestListId"}}`, encodeURIComponent(String(requestParameters['guestListId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestListSettingsStatusChangeDtoToJSON(requestParameters['guestListSettingsStatusChangeDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change the status of the guest list, deactivating or reactivating it
     */
    async toggleGuestList(requestParameters: ToggleGuestListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleGuestListRaw(requestParameters, initOverrides);
    }

    /**
     * Update the existing guest list.
     */
    async updateGuestListSettingsRaw(requestParameters: UpdateGuestListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestListSettingsDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGuestListSettings().'
            );
        }

        if (requestParameters['guestListSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'guestListSettingsDto',
                'Required parameter "guestListSettingsDto" was null or undefined when calling updateGuestListSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guest-list-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GuestListSettingsDtoToJSON(requestParameters['guestListSettingsDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing guest list.
     */
    async updateGuestListSettings(requestParameters: UpdateGuestListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestListSettingsDto> {
        const response = await this.updateGuestListSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
