import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { awardIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function AwardSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'AWARD', `${result.resultType} is no instance of AwardSearchResult`);

  const award = result.result;
  const { t } = useTranslation('award');

  return (
    <SearchResult
      icon={awardIcon}
      title={award.title}
      link={`/awards/${award.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('titleEnglish'),
            element: award.titleEnglish,
          },
          {
            label: t('comment'),
            element: award.comment,
          },
          {
            label: t('status'),
            element: t(`statusLabel.${award.status}`),
          },
          {
            label: t('juryType'),
            element: award.juryType,
          },
        ]}
      />
    </SearchResult>
  );
}
