import React from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export interface PageState {
  page?: number;
  size?: number;
}

export default function usePaginationState(defaultState?: PageState) {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultStateRef = React.useRef<PageState>({ page: 0, size: 20, ...defaultState });
  const stateOverwrittenRef = React.useRef(false);

  const state: PageState = React.useMemo(() => {
    const defaultState = defaultStateRef.current;

    return {
      page: Number(searchParams.get('page') ?? defaultState.page),
      size: Number(searchParams.get('size') ?? defaultState.size),
    };
  }, [searchParams]);

  const setState = React.useCallback(
    (state: PageState) => {
      const searchParams: URLSearchParamsInit = {};

      if (state.page != null) {
        searchParams.page = String(state.page);
      }

      if (state.size != null) {
        searchParams.size = String(state.size);
      }

      setSearchParams(searchParams, { replace: true });
      stateOverwrittenRef.current = true;
    },
    [setSearchParams],
  );

  return [state, setState] as const;
}
