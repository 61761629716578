import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { StaffListItemViewerDto, TeamDto, TeamStaffRelationDto } from '../../../../api';
import teamStaffApi from '../../../../data-access/team-staff-api';
import Form from '../../../../ui/form/form';
import SubmitButton from '../../../../ui/form/submit-button';
import useToast from '../../../../ui/use-toast/use-toast';
import { teamStaffFetcher } from '../team-staff-queries';
import TeamStaffEditorFormModal from './team-staff-editor-form-modal';

interface TeamStaffEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  team?: TeamDto;
  staff?: StaffListItemViewerDto;
}

export default function TeamStaffEditorDialog({ isOpen, onClose, team, staff }: TeamStaffEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <TeamStaffModal onClose={onClose} team={team} staff={staff} />
      </ModalContent>
    </Modal>
  );
}

interface TeamStaffModalProps {
  onClose: () => void;
  team?: TeamDto;
  staff?: StaffListItemViewerDto;
}

function TeamStaffModal({ onClose, team, staff }: TeamStaffModalProps) {
  const { t } = useTranslation('relations');
  const { t: tCommon } = useTranslation('common');
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const defaultRelation: Partial<TeamStaffRelationDto> = {
    id: uuid(),
    staff: staff,
    team: team,
  };

  const form = useForm<TeamStaffRelationDto>({
    mode: 'all',
    defaultValues: { ...defaultRelation },
  });

  const showSuccessToast = useToast({
    id: 'team-staff-editor-success-toast',
    status: 'success',
  });

  const onSave = async (teamStaff: TeamStaffRelationDto) => {
    teamStaff.staff['belongsToSections'] = [];
    const saved = await teamStaffApi.createTeamStaffRelation({
      teamStaffRelationPostDto: { ...teamStaff, staff: { ...teamStaff.staff, emailAddress: undefined } },
    });

    showSuccessToast({
      title: t('toast.add_success'),
      description: t('toast.relation_description', { name: `${saved.staff.firstName} ${saved.staff.surname}` }),
    });

    if (saved.id != null) {
      await teamStaffFetcher.mutate();
    }

    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <FormProvider {...form}>
      <Form<TeamStaffRelationDto> onValid={onSave} initialFocusRef={initialFocusRef}>
        <ModalHeader>{team == null ? t('action.connect_team') : t('action.connect_staff')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {team != null && <TeamStaffEditorFormModal team={team} initialFocusRef={initialFocusRef} />}
        </ModalBody>
        <ModalFooter>
          <Flex width="100%">
            <Spacer />
            <Button mr={3} onClick={onClose}>
              {tCommon('action.abort')}
            </Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty}>
              {tCommon('action.add')}
            </SubmitButton>
          </Flex>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
