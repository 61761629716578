import { HStack, Link, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ParticipationReferenceDto } from '../../../api';
import Optional from '../../../ui/optional/optional';

export interface ParticipationReferenceProps {
  participationReference: ParticipationReferenceDto | undefined;
  size?: 'sm' | 'md';
  maxWidth?: string;
  className?: string;
  flipName?: boolean;
  spacing?: StackProps['spacing'];
  color?: string;
}

export default function ParticipationReference({
  participationReference,
  size = 'md',
  maxWidth = '240',
  spacing,
  className,
  color,
}: ParticipationReferenceProps) {
  const { t } = useTranslation('participation');
  return (
    <Optional>
      {participationReference != null && (
        <Link
          className={className}
          display="inline-block"
          as={RouterLink}
          to={`/persons/${participationReference.person.id}/participations/${participationReference.id}`}
          size={size}
          color={color}
        >
          <HStack spacing={spacing ?? (size === 'md' ? 2 : 1)}>
            <Text as="span" fontWeight="medium" noOfLines={1} maxWidth={maxWidth}>
              {t('participationFormat', {
                firstName: participationReference.person.firstName,
                surname: participationReference.person.surname,
                edition: participationReference.edition.name,
              })}
            </Text>
          </HStack>
        </Link>
      )}
    </Optional>
  );
}
