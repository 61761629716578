/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyEmailAddressDto } from './CompanyEmailAddressDto';
import {
    CompanyEmailAddressDtoFromJSON,
    CompanyEmailAddressDtoFromJSONTyped,
    CompanyEmailAddressDtoToJSON,
} from './CompanyEmailAddressDto';
import type { CompanyPhoneNumberDto } from './CompanyPhoneNumberDto';
import {
    CompanyPhoneNumberDtoFromJSON,
    CompanyPhoneNumberDtoFromJSONTyped,
    CompanyPhoneNumberDtoToJSON,
} from './CompanyPhoneNumberDto';
import type { CompanyPostalAddressDto } from './CompanyPostalAddressDto';
import {
    CompanyPostalAddressDtoFromJSON,
    CompanyPostalAddressDtoFromJSONTyped,
    CompanyPostalAddressDtoToJSON,
} from './CompanyPostalAddressDto';
import type { CompanyPublicationNameDto } from './CompanyPublicationNameDto';
import {
    CompanyPublicationNameDtoFromJSON,
    CompanyPublicationNameDtoFromJSONTyped,
    CompanyPublicationNameDtoToJSON,
} from './CompanyPublicationNameDto';
import type { CompanySectionDto } from './CompanySectionDto';
import {
    CompanySectionDtoFromJSON,
    CompanySectionDtoFromJSONTyped,
    CompanySectionDtoToJSON,
} from './CompanySectionDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { TaxExemptionDto } from './TaxExemptionDto';
import {
    TaxExemptionDtoFromJSON,
    TaxExemptionDtoFromJSONTyped,
    TaxExemptionDtoToJSON,
} from './TaxExemptionDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An industry-specific company.
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyDto
     */
    version?: VersionDto;
    /**
     * Name of a company
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * List of synonyms of the company.
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    synonyms?: Array<string>;
    /**
     * The publication names of the company.
     * @type {Array<CompanyPublicationNameDto>}
     * @memberof CompanyDto
     */
    namesForPublication?: Array<CompanyPublicationNameDto>;
    /**
     * List of email addresses
     * @type {Array<CompanyEmailAddressDto>}
     * @memberof CompanyDto
     */
    emailAddresses?: Array<CompanyEmailAddressDto>;
    /**
     * List of phone numbers
     * @type {Array<CompanyPhoneNumberDto>}
     * @memberof CompanyDto
     */
    phoneNumbers?: Array<CompanyPhoneNumberDto>;
    /**
     * List of postal addresses
     * @type {Array<CompanyPostalAddressDto>}
     * @memberof CompanyDto
     */
    postalAddresses?: Array<CompanyPostalAddressDto>;
    /**
     * List of company sections with own addresses
     * @type {Array<CompanySectionDto>}
     * @memberof CompanyDto
     */
    companySections?: Array<CompanySectionDto>;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof CompanyDto
     */
    countries?: Array<CountryReferenceDto>;
    /**
     * List of fields of interest of the company, at the moment same as of person but may differ in the future
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    fieldsOfInterest?: Array<CompanyDtoFieldsOfInterestEnum>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    detailedDescription?: string;
    /**
     * 
     * @type {Array<TaxExemptionDto>}
     * @memberof CompanyDto
     */
    taxExemptions: Array<TaxExemptionDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    status: CompanyDtoStatusEnum;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof CompanyDto
     */
    permissions?: PermissionsDto;
}


/**
 * @export
 */
export const CompanyDtoFieldsOfInterestEnum = {
    ACTION: 'ACTION',
    ADULT: 'ADULT',
    ADVANCED_TRAINING: 'ADVANCED_TRAINING',
    ADVENTURE: 'ADVENTURE',
    ANIMATION: 'ANIMATION',
    ARAB_CINEMA: 'ARAB_CINEMA',
    ARTHOUSE: 'ARTHOUSE',
    BOLLYWOOD: 'BOLLYWOOD',
    CHILDRENS_FILM: 'CHILDRENS_FILM',
    COMEDY: 'COMEDY',
    CRIME: 'CRIME',
    DATA_ANALYST: 'DATA_ANALYST',
    DOCUMENTARY_FORM: 'DOCUMENTARY_FORM',
    DRAMA: 'DRAMA',
    EUROPEAN_CINEMA: 'EUROPEAN_CINEMA',
    EXPERIMENTAL: 'EXPERIMENTAL',
    FANTASY: 'FANTASY',
    FICTION: 'FICTION',
    FINANCING: 'FINANCING',
    FUNDING: 'FUNDING',
    GREEN_PRODUCTION: 'GREEN_PRODUCTION',
    HISTORICAL: 'HISTORICAL',
    HORROR: 'HORROR',
    INDIGENOUS: 'INDIGENOUS',
    INTERNATIONAL_CO_PRODUCTION: 'INTERNATIONAL_CO_PRODUCTION',
    MUSIC_VIDEO: 'MUSIC_VIDEO',
    MUSICAL_AND_DANCE: 'MUSICAL_AND_DANCE',
    NEW_TECH: 'NEW_TECH',
    QUEER: 'QUEER',
    ROMANCE: 'ROMANCE',
    SATIRE: 'SATIRE',
    SCIENCE_FICTION: 'SCIENCE_FICTION',
    SERIES: 'SERIES',
    SHORT_FORM: 'SHORT_FORM',
    SHOWRUNNER: 'SHOWRUNNER',
    THRILLER: 'THRILLER',
    US_INDIES: 'US_INDIES',
    WESTERN: 'WESTERN',
    WRITERS_ROOM: 'WRITERS_ROOM'
} as const;
export type CompanyDtoFieldsOfInterestEnum = typeof CompanyDtoFieldsOfInterestEnum[keyof typeof CompanyDtoFieldsOfInterestEnum];

/**
 * @export
 */
export const CompanyDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    HISTORIC: 'HISTORIC',
    UNCONFIRMED: 'UNCONFIRMED'
} as const;
export type CompanyDtoStatusEnum = typeof CompanyDtoStatusEnum[keyof typeof CompanyDtoStatusEnum];


/**
 * Check if a given object implements the CompanyDto interface.
 */
export function instanceOfCompanyDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('taxExemptions' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'synonyms': json['synonyms'] == null ? undefined : json['synonyms'],
        'namesForPublication': json['namesForPublication'] == null ? undefined : ((json['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoFromJSON)),
        'emailAddresses': json['emailAddresses'] == null ? undefined : ((json['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoFromJSON)),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoFromJSON)),
        'postalAddresses': json['postalAddresses'] == null ? undefined : ((json['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoFromJSON)),
        'companySections': json['companySections'] == null ? undefined : ((json['companySections'] as Array<any>).map(CompanySectionDtoFromJSON)),
        'countries': json['countries'] == null ? undefined : ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'fieldsOfInterest': json['fieldsOfInterest'] == null ? undefined : json['fieldsOfInterest'],
        'shortDescription': json['shortDescription'] == null ? undefined : json['shortDescription'],
        'detailedDescription': json['detailedDescription'] == null ? undefined : json['detailedDescription'],
        'taxExemptions': ((json['taxExemptions'] as Array<any>).map(TaxExemptionDtoFromJSON)),
        'status': json['status'],
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'synonyms': value['synonyms'],
        'namesForPublication': value['namesForPublication'] == null ? undefined : ((value['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoToJSON)),
        'emailAddresses': value['emailAddresses'] == null ? undefined : ((value['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoToJSON)),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoToJSON)),
        'postalAddresses': value['postalAddresses'] == null ? undefined : ((value['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoToJSON)),
        'companySections': value['companySections'] == null ? undefined : ((value['companySections'] as Array<any>).map(CompanySectionDtoToJSON)),
        'countries': value['countries'] == null ? undefined : ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'fieldsOfInterest': value['fieldsOfInterest'],
        'shortDescription': value['shortDescription'],
        'detailedDescription': value['detailedDescription'],
        'taxExemptions': ((value['taxExemptions'] as Array<any>).map(TaxExemptionDtoToJSON)),
        'status': value['status'],
        'permissions': PermissionsDtoToJSON(value['permissions']),
    };
}

