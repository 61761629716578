import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import GroupTemplateBreadcrumb from './common/group-template-breadcrumb';

const groupTemplateRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'group-templates',
    handle: {
      breadcrumb: <Translate ns="group_template" i18nKey="group_template" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./group-template-lister/group-template-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./group-template-editor/new-group-template-route'),
      },
      {
        path: ':groupTemplateId',
        handle: {
          breadcrumb: <GroupTemplateBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./group-template-page/group-template-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./group-template-viewer/group-template-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./group-template-history/group-template-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./group-template-editor/edit-group-template-route'),
          },
        ],
      },
    ],
  },
];

export default groupTemplateRoutes;
