import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import ProductBundleBreadcrumb from './common/product-bundle-breadcrumb';

const productBundleRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'product-bundles',
    handle: {
      breadcrumb: <Translate ns="product_bundle" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./product-bundle-lister/product-bundle-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./product-bundle-editor/new-product-bundle-route'),
      },
      {
        path: ':productBundleId',
        handle: {
          breadcrumb: <ProductBundleBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./product-bundle-page/product-bundle-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./product-bundle-viewer/product-bundle-viewer-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./product-bundle-editor/edit-product-bundle-route'),
          },
        ],
      },
    ],
  },
];

export default productBundleRoutes;
