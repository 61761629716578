import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { ProductBundleDtoFromJSON } from '../../api';
import { productBundleIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function ProductBundleSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'PRODUCT_BUNDLE', `${result.resultType} is no instance of ProductSearchResult`);

  const productBundle = ProductBundleDtoFromJSON(result.result);
  const { t } = useTranslation(['product_bundle', 'common']);

  return (
    <SearchResult
      icon={productBundleIcon}
      title={productBundle.title}
      link={`/product-bundles/${productBundle.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('product_bundle:titleEnglish'),
            element: productBundle.titleEnglish,
          },
          {
            label: t('product_bundle:articleNumber'),
            element: productBundle.articleNumber,
          },
          {
            label: t('product_bundle:owners'),
            element: productBundle.owners.map((owner) => owner.section.name).join(', '),
          },
          {
            label: t('product_bundle:price.label'),
            element: t('common:format.currency', { value: productBundle.price.amount }),
          },
          {
            label: t('product_bundle:price.availabilityShort'),
            element: t('common:format.datetime_range', { dateTimeRange: productBundle.price.availability }),
          },
        ]}
      />
    </SearchResult>
  );
}
