import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import {
  faBroom,
  faCalendarCheck,
  faCheck,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCodeMerge,
  faCopy,
  faDoorClosed,
  faDoorOpen,
  faFileAlt,
  faFilter,
  faHistory,
  faHome,
  faHorizontalRule,
  faLink,
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowUp,
  faMagic,
  faMobile,
  faPencil,
  faPlug,
  faQuestionCircle,
  faSignOut,
  faTableColumns,
  faToggleOn,
  faTrashAlt,
  faUnlink,
  faUpRightFromSquare,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  accessGroupIcon,
  awardIcon,
  bodyguardIcon,
  BussinessObjectIconDefinition,
  companyIcon,
  editionIcon,
  evaluationIcon,
  eventIcon,
  groupIcon,
  groupTemplateIcon,
  guestlistIcon,
  keyPlayerListIcon,
  locationIcon,
  mailingBlocklistIcon,
  mailingDesignIcon,
  mailingIcon,
  mailingTemplateIcon,
  personIcon,
  placeholderIcon,
  placementIcon,
  renderBusinessObjectIcon,
  rundownIcon,
  sectionIcon,
  staffIcon,
  systemMailingIcon,
  teamIcon,
} from '../../../ui/icons/business-objects';

export default function IconHelpViewer() {
  const { t } = useTranslation('app');

  return (
    <Stack spacing={8}>
      <Heading as="h3" size="md">
        {t('help.headline.functions')}
      </Heading>
      <Stack>
        <IconHelpItem icon={faCheck} color="text.success" text={t('help.positive')} />
        <IconHelpItem icon={faXmark} color="text.error" text={t('help.negative')} />

        <IconHelpItem icon={faUpRightFromSquare} text={t('help.external_link')} />
        <IconHelpItem icon={faHorizontalRule} text={t('help.internal_link')} />

        <IconHelpItem icon={faPencil} color="text.interactive" text={t('help.edit')} />
        <IconHelpItem icon={faTrashAlt} color="text.error" text={t('help.delete')} />

        <IconHelpItem icon={faLink} color="text.interactive" text={t('help.linkS')} />
        <IconHelpItem icon={faUnlink} color="text.interactive" text={t('help.unlink')} />

        <IconHelpItem icon={faFilter} color="text.interactive" text={t('help.filter')} />
        <IconHelpItem icon={faLongArrowUp} color="text.interactive" text={t('help.sort_asc')} />
        <IconHelpItem icon={faLongArrowDown} color="text.interactive" text={t('help.sort_desc')} />

        <IconHelpItem icon={faLongArrowRight} text={t('help.browse')} color="text.interactive" />
        <IconHelpItem icon={faLongArrowLeft} text={t('help.browse_back')} color="text.interactive" />
        <IconHelpItem icon={faChevronDown} text={t('help.menu_open')} color="text.interactive" />
        <IconHelpItem icon={faChevronUp} text={t('help.menu_close')} color="text.interactive" />

        <IconHelpItem icon={faQuestionCircle} color="text.interactive" text={t('help.helpP')} />
        <IconHelpItem icon={faChevronDoubleRight} color="text.interactive" text={t('help.nav_open')} />
        <IconHelpItem icon={faChevronDoubleLeft} color="text.interactive" text={t('help.nav_close')} />

        <IconHelpItem icon={faSignOut} color="text.interactive" text={t('help.log_out')} />
        <IconHelpItem icon={faPlug} color="text.interactive" text={t('help.coupling')} />

        <IconHelpItem icon={faBroom} color="text.interactive" text={t('help.cleaning')} />
        <IconHelpItem icon={faMagic} color="text.interactive" text={t('help.magic')} />

        <IconHelpItem icon={faToggleOn} text={t('help.toggle')} />
        <IconHelpItem icon={faCopy} color="text.interactive" text={t('help.copy')} />

        <IconHelpItem icon={faDoorOpen} text={t('help.door_open')} />
        <IconHelpItem icon={faDoorClosed} text={t('help.door_closed')} />

        <IconHelpItem icon={faFileAlt} color="text.interactive" text={t('help.clipboard')} />
        <IconHelpItem icon={faCircleNotch} color="text.interactive" text={t('help.spinner')} />
        <IconHelpItem icon={faMobile} text={t('help.mobile')} />
        <IconHelpItem icon={faTableColumns} text={t('help.compare_placement')} />
        <IconHelpItem icon={faCodeMerge} color="text.interactive" text={t('help.duplicate')} />
      </Stack>

      <Heading as="h3" size="md">
        {t('help.headline.objects')}
      </Heading>

      <Stack>
        <IconHelpItem icon={personIcon} text={t('help.person')} description={t('help.person_desc')} />
        <IconHelpItem icon={companyIcon} text={t('help.company')} description={t('help.company_desc')} />

        <IconHelpItem icon={eventIcon} text={t('help.event')} description={t('help.event_desc')} />
        <IconHelpItem icon={guestlistIcon} text={t('help.guestList')} description={t('help.guestList_desc')} />

        <IconHelpItem icon={placementIcon} text={t('help.placement')} description={t('help.placement_desc')} />
        <IconHelpItem icon={locationIcon} text={t('help.location')} description={t('help.location_desc')} />

        <IconHelpItem icon={placeholderIcon} text={t('help.placeholder')} description={t('help.placeholder_desc')} />
        <IconHelpItem icon={bodyguardIcon} text={t('help.bodyguard')} description={t('help.bodyguard_desc')} />

        <IconHelpItem icon={sectionIcon} text={t('help.section')} description={t('help.section_desc')} />
        <IconHelpItem icon={evaluationIcon} text={t('help.evaluation')} description={t('help.evaluation_desc')} />

        <IconHelpItem icon={rundownIcon} text={t('help.rundown')} description={t('help.rundown_desc')} />
        <IconHelpItem
          icon={keyPlayerListIcon}
          text={t('help.key-player-list')}
          description={t('help.key-player-list_desc')}
        />

        <IconHelpItem
          icon={accessGroupIcon}
          text={t('help.icon.accessGroup.name')}
          description={t('help.icon.accessGroup.desc')}
        />
        <IconHelpItem icon={awardIcon} text={t('help.icon.award.name')} description={t('help.icon.award.desc')} />
        <IconHelpItem icon={groupIcon} text={t('help.icon.group.name')} description={t('help.icon.group.desc')} />
        <IconHelpItem
          icon={groupTemplateIcon}
          text={t('help.icon.groupTemplate.name')}
          description={t('help.icon.groupTemplate.desc')}
        />
        <IconHelpItem icon={editionIcon} text={t('help.icon.edition.name')} description={t('help.icon.edition.desc')} />
        <IconHelpItem
          icon={mailingBlocklistIcon}
          text={t('help.icon.mailingBlocklist.name')}
          description={t('help.icon.mailingBlocklist.desc')}
        />
        <IconHelpItem icon={mailingDesignIcon} text={t('help.icon.mailingDesign.name')} />
        <IconHelpItem icon={mailingIcon} text={t('help.icon.mailing.name')} />
        <IconHelpItem icon={mailingTemplateIcon} text={t('help.icon.mailingTemplate.name')} />
        <IconHelpItem icon={staffIcon} text={t('help.icon.staff.name')} description={t('help.icon.staff.desc')} />
        <IconHelpItem icon={systemMailingIcon} text={t('help.icon.systemMailing.name')} />
        <IconHelpItem icon={teamIcon} text={t('help.icon.team.name')} description={t('help.icon.team.desc')} />
      </Stack>

      <Heading as="h3" size="md">
        {t('help.headline.tabs')}
      </Heading>

      <Stack>
        <IconHelpItem icon={faHome} text={t('help.startpage')} description={t('help.startpage_desc')} />
        <IconHelpItem icon={faHistory} text={t('help.history')} description={t('help.history_desc')} />
        <IconHelpItem icon={faCalendarCheck} text={t('help.calendar')} description={t('help.calendar_desc')} />
      </Stack>
    </Stack>
  );
}

interface IconHelpItemProps {
  icon: BussinessObjectIconDefinition;
  text: string;
  description?: string;
  color?: string;
}

function IconHelpItem({ icon, text, description, color }: IconHelpItemProps) {
  return (
    <>
      <HStack alignItems="start">
        <Text width={6} color={color}>
          {renderBusinessObjectIcon(icon, { fixedWidth: true })}
        </Text>
        <Stack>
          <Text fontWeight="semibold">{text}</Text>
          <Text>{description}</Text>
        </Stack>
      </HStack>
    </>
  );
}
