import { createContext, ReactNode, useCallback } from 'react';
import { PermissionDto } from '../../api';
import useFetcher from '../../util/swr/use-fetcher';
import { fetchPermissions } from './permission-queries';
import useDefinedContext from '../../util/context/use-defined-context/use-defined-context';

export type PermissionName = PermissionDto['name'];
export type PermissionByName<TName extends PermissionName> = PermissionDto & { name: TName };
export type PermissionString<TName extends PermissionName> = TName extends PermissionName
  ? `${TName}:${PermissionByName<TName>['action']}`
  : never;
export type Permission = PermissionString<PermissionName>;

export default function usePermission(): (permission: Permission) => boolean {
  return useDefinedContext(PermissionContext);
}

type PermissionContextType = (permission: Permission) => boolean;
export const PermissionContext = createContext<PermissionContextType | null>(null);

export function PermissionContextProvider({ children }: { children: ReactNode }) {
  const permissions = useFetcher(fetchPermissions, {}, { immutable: true });

  const hasPermission = useCallback((permission: Permission) => permissions.includes(permission), [permissions]);

  return <PermissionContext.Provider value={hasPermission}>{children}</PermissionContext.Provider>;
}
