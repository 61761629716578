import React from 'react';
import { awardIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import {
  AWARD_WINNER_RELATION_EXTENSION,
  AwardWinnerRelationExtension,
} from '../../award/award-relation/award-winner-extension';
import PERSON_CHILD_ROUTES from '../../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../../person/person-page/person-page';
import { AWARD_WINNER_HISTORY_EXTENSION } from '../../award/award-relation/award-winner-history-extension';
import { awardWinnerHistoryExtension } from './award-winner-history/award-winner-history';
import AwardWinnerPageContent from './award-winner-page-content';
import personAwardRoutes from './person-award-lister/person-award-routes';

export const awardPersonTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="award" i18nKey="lister.title" />,
  icon: awardIcon,
  to: './awards',
};

export default function AwardWinnerPluginProvider(registry: PluginRegistry) {
  registry.register(AWARD_WINNER_RELATION_EXTENSION, awardWinnerPageContentExtension);
  registry.register(PERSON_TAB_ITEM, awardPersonTabItem);
  registry.register(PERSON_CHILD_ROUTES, personAwardRoutes);
  registry.register(AWARD_WINNER_HISTORY_EXTENSION, awardWinnerHistoryExtension);
}

const awardWinnerPageContentExtension: AwardWinnerRelationExtension = {
  pageContent: (award) => <AwardWinnerPageContent award={award} />,
};
