import { Flex, Grid, GridItem, IconButton, Tooltip } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyAdminListItemDto, CompanyDto, CompanyDtoStatusEnum } from '../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../ui/data-table';
import useRequestParams from '../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../util/swr/fallback-middleware';
import useFetcher from '../../../util/swr/use-fetcher';
import PersonReference from '../../person/person-reference/person-reference';
import CompanyAdminDeleteButton from '../company-admin-delete-button/company-admin-delete-button';
import { companyAdminFetcher, fetchCompanyAdminListItems } from '../company-admin-queries';

interface CompanyAdminListerProps {
  company: CompanyDto;
}

function useCompanyAdminListItems(state: DataTableState, companyId: string) {
  const requestParams = useRequestParams(state, [{ property: 'modifiedAt', direction: 'DESC' }]);
  return useFetcher(
    fetchCompanyAdminListItems,
    {
      ...requestParams,
      id: companyId,
      filter: [...requestParams.filter],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function CompanyAdminLister({ company }: CompanyAdminListerProps) {
  const { t } = useTranslation(['common', 'company', 'person']);
  const [state, setState] = useDataTableState();
  const page = useCompanyAdminListItems(state, company.id!);

  const columns: DataTableColumn<CompanyAdminListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<CompanyAdminListItemDto>[] = [
      {
        key: 'person',
        name: t('person:surname'),
        renderCell: (item: CompanyAdminListItemDto) => (
          <PersonReference
            personReference={{
              id: item.personId,
              firstName: item.firstName,
              surname: item.surname,
            }}
            hidePersonKey
          />
        ),
      },
      {
        key: 'jobTitle',
        name: t('person:occupations.jobTitle'),
        renderCell: (item: CompanyAdminListItemDto) => item.jobTitle,
      },
    ];

    return columns;
  }, [t]);

  const actions = useCallback(
    (relation: CompanyAdminListItemDto) => (
      <Flex>
        <Tooltip label={t('company:externalAdmin.delete')} placement="right-end">
          <IconButton
            as={CompanyAdminDeleteButton}
            relation={relation}
            companyId={company.id}
            isLastAdmin={page.totalElements == 1}
            onSuccess={() => companyAdminFetcher.mutate()}
            isDeletable={company.status === CompanyDtoStatusEnum.ACTIVE}
            size="sm"
            colorScheme="red"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            aria-label={t('company:externalAdmin.delete')}
          />
        </Tooltip>
      </Flex>
    ),
    [company.id, company.status, page.totalElements, t],
  );

  const rowKey = React.useCallback((relation: CompanyAdminListItemDto) => {
    invariant(relation.occupationId != null, 'Missing occupationId');

    return relation.occupationId;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          actions={actions}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
