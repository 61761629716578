import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api/models/CompanyDto';
import { refundApi } from '../../../data-access/invoice-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';

interface InvoiceRefundButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  company: CompanyDto;
}

export const InvoiceRefundTaxButton = React.forwardRef<HTMLButtonElement, InvoiceRefundButtonProps>(
  ({ company, children, ...props }, ref) => {
    const { t } = useTranslation(['invoice']);
    const showExemptionSuccessToast = useToast({
      id: 'invoice-refund-tax-success-toast',
      status: 'success',
    });

    const handleClick = async () => {
      await refundApi.exemptCompanyFromValueAddedTax({ companyId: company.id });

      showExemptionSuccessToast({
        title: t('invoice:action.refundTax.title'),
        description: t('invoice:action.refundTax.description', {
          companyName: company.name,
        }),
      });
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={handleClick}
          aria-label={t('invoice:action.refundTax.label')}
          isDisabled={company.taxExemptions && company.taxExemptions.length < 1}
          disableReason={t('invoice:action.refundTax.disabled', { companyName: company.name })}
        >
          <>
            {children}
            {t('invoice:action.refundTax.label')}
          </>
        </DisableableButton>
      </>
    );
  },
);
