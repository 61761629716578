import { Center, HStack, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StaffListItemViewerDto, StaffReferenceDto } from '../../../api';
import { staffIcon } from '../../../ui/icons/business-objects';

export interface StaffReferenceProps {
  staff: StaffReferenceDto | StaffListItemViewerDto;
  flipName?: boolean;
  fixedWidthIcon?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isInline?: boolean;
  spacing?: number;
  maxWidth?: number | string;
  hideIcon?: boolean;
  className?: string;
  color?: string;
  colorScheme?: string;
}

export default function StaffReference({
  staff,
  size,
  flipName,
  fixedWidthIcon,
  spacing,
  isInline,
  maxWidth,
  hideIcon = false,
  className,
  color,
  colorScheme,
}: StaffReferenceProps) {
  const displayName = flipName ? `${staff.surname}, ${staff.firstName}` : `${staff.firstName} ${staff.surname}`;

  return (
    <Link
      as={RouterLink}
      className={className}
      to={'/staff/' + staff.id}
      textColor={color}
      display={isInline ? 'inline-block' : 'block'}
      size={size}
      colorScheme={colorScheme}
    >
      <HStack fontSize={size} alignItems={isInline ? 'baseline' : undefined} spacing={spacing}>
        {!hideIcon && (
          <Center w={fixedWidthIcon ? 4 : undefined}>
            <FontAwesomeIcon icon={staffIcon} />
          </Center>
        )}
        <HStack spacing={1} overflow="hidden">
          <Text as="span" fontWeight="medium" maxWidth={maxWidth} noOfLines={maxWidth ? 1 : undefined}>
            {displayName}
          </Text>
        </HStack>
      </HStack>
    </Link>
  );
}
