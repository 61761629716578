import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const awardMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.award" />,
    path: '/awards',
    hasAccess: (permissions) => permissions.includes('AWARD:STANDARD-READ'),
  },
];

export default awardMenuItems;
