import { Alert, AlertIcon, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonDto, PersonDtoSalutationEnum } from '../../../api';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import useWatchChange from '../../../ui/form/use-watch-change/use-watch-change';
import { LayoutType } from '../../common/LayoutType';
import { postNominalOptions, salutationOptions, titleOptions } from '../person-enum-constants';

export interface SalutationControlProps {
  layout: LayoutType;
}

export default function SalutationControl({ layout }: SalutationControlProps) {
  const { t } = useTranslation(['common', 'person']);

  return (
    <>
      <Stack spacing={4}>
        <PartialSalutationControl layout={layout} />

        <Stack direction={layout} spacing={6} alignItems="flex-start">
          <FormControl<PersonDto> label={t('person:title')} name="title" helperText={t('person:titleExplanation')}>
            <ValueSelectControl
              options={titleOptions}
              renderLabel={(option) => t(`person:titleLabels.${option}`)}
              name="title"
              isClearable={true}
            />
          </FormControl>

          <FormControl<PersonDto> label={t('person:postNominal')} name="postNominal">
            <ValueSelectControl
              options={postNominalOptions}
              renderLabel={(option) => t(`person:postNominalLabels.${option}`)}
              name="postNominal"
              isClearable={true}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}

export function PartialSalutationControl({ layout }: { layout: LayoutType }) {
  const { t } = useTranslation(['common', 'person']);

  const salutation = useWatch({ name: 'salutation' });
  const { resetField } = useFormContext();

  useWatchChange<PersonDto>(['salutation'], (data) => {
    if (data.salutation !== PersonDtoSalutationEnum.INDIVIDUAL) {
      resetField('salutationIndividual', { defaultValue: null });
    }
  });

  return (
    <>
      <Stack direction={layout} spacing={6}>
        <FormControl label={t('person:salutation')} name="gender">
          <ValueSelectControl
            options={salutationOptions}
            renderLabel={(option) => t(`person:salutationLabels.${option}`)}
            name="salutation"
            defaultValue={PersonDtoSalutationEnum.DEFAULT}
          />
        </FormControl>

        <InputFormControl<PersonDto>
          name="salutationIndividual"
          label={t('person:salutation_individual')}
          maxLength={100}
          minLength={3}
          isRequired={salutation === PersonDtoSalutationEnum.INDIVIDUAL}
          isDisabled={salutation !== PersonDtoSalutationEnum.INDIVIDUAL}
        />
      </Stack>

      {salutation === PersonDtoSalutationEnum.INDIVIDUAL && (
        <HStack spacing={6} alignItems="flex-start">
          <Alert status="info">
            <AlertIcon />
            {t('person:salutation_individual_alert')}
          </Alert>
        </HStack>
      )}
    </>
  );
}
