import React from 'react';
import { personIcon } from '../../ui/icons/business-objects';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import COMPANY_CHILD_ROUTES from '../company/company-child-routes';
import { COMPANY_PAGE_TAB } from '../company/company-page/company-page-tab';
import companyAdminRoutes from './company-admin-routes';

export default function CompanyAdminPluginProvider(registry: PluginRegistry) {
  registry.register(COMPANY_PAGE_TAB, (builder) =>
    builder.before('history', {
      key: 'admins',
      element: (
        <PageTab to="./admins" icon={personIcon}>
          <Translate ns="company" i18nKey="externalAdmin.lister" />
        </PageTab>
      ),
    }),
  );
  registry.register(COMPANY_CHILD_ROUTES, companyAdminRoutes);
}
