/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  FormatDto,
  FormatPageDto,
  FormatStatusCheckDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FormatDtoFromJSON,
    FormatDtoToJSON,
    FormatPageDtoFromJSON,
    FormatPageDtoToJSON,
    FormatStatusCheckDtoFromJSON,
    FormatStatusCheckDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CheckFormatStatusRequest {
    formatId: string;
}

export interface CreateFormatRequest {
    formatDto: FormatDto;
}

export interface DeleteFormatRequest {
    id: string;
}

export interface FetchFormatRequest {
    id: string;
}

export interface FetchFormatHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchFormatsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateFormatRequest {
    id: string;
    formatDto: FormatDto;
}

/**
 * 
 */
export class FormatApi extends runtime.BaseAPI {

    /**
     * Get the current status of the format.
     */
    async checkFormatStatusRaw(requestParameters: CheckFormatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatStatusCheckDto>> {
        if (requestParameters['formatId'] == null) {
            throw new runtime.RequiredError(
                'formatId',
                'Required parameter "formatId" was null or undefined when calling checkFormatStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{formatId}/check-status`.replace(`{${"formatId"}}`, encodeURIComponent(String(requestParameters['formatId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the format.
     */
    async checkFormatStatus(requestParameters: CheckFormatStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatStatusCheckDto> {
        const response = await this.checkFormatStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new format.
     */
    async createFormatRaw(requestParameters: CreateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['formatDto'] == null) {
            throw new runtime.RequiredError(
                'formatDto',
                'Required parameter "formatDto" was null or undefined when calling createFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormatDtoToJSON(requestParameters['formatDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new format.
     */
    async createFormat(requestParameters: CreateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFormatRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the format.
     */
    async deleteFormatRaw(requestParameters: DeleteFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the format.
     */
    async deleteFormat(requestParameters: DeleteFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFormatRaw(requestParameters, initOverrides);
    }

    /**
     * Get the format business object.
     */
    async fetchFormatRaw(requestParameters: FetchFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatDtoFromJSON(jsonValue));
    }

    /**
     * Get the format business object.
     */
    async fetchFormat(requestParameters: FetchFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatDto> {
        const response = await this.fetchFormatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of a format.
     */
    async fetchFormatHistoryRaw(requestParameters: FetchFormatHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFormatHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of a format.
     */
    async fetchFormatHistory(requestParameters: FetchFormatHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFormatHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for formats.
     */
    async searchFormatsRaw(requestParameters: SearchFormatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for formats.
     */
    async searchFormats(requestParameters: SearchFormatsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatPageDto> {
        const response = await this.searchFormatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new format.
     */
    async templateFormatRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormatDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormatDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new format.
     */
    async templateFormat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormatDto> {
        const response = await this.templateFormatRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing format.
     */
    async updateFormatRaw(requestParameters: UpdateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateFormat().'
            );
        }

        if (requestParameters['formatDto'] == null) {
            throw new runtime.RequiredError(
                'formatDto',
                'Required parameter "formatDto" was null or undefined when calling updateFormat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/formats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FormatDtoToJSON(requestParameters['formatDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing format.
     */
    async updateFormat(requestParameters: UpdateFormatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFormatRaw(requestParameters, initOverrides);
    }

}
