import { Box, HStack, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { SectionReferenceDto } from '../../../api';
import { sectionIcon } from '../../../ui/icons/business-objects';

export interface SectionReferenceProps {
  sectionReference: SectionReferenceDto;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  hideIcon?: boolean;
}

export default function SectionReference({ sectionReference, size = 'md', hideIcon = false }: SectionReferenceProps) {
  return (
    <Link display="inline-block" as={RouterLink} to={'/sections/' + sectionReference.id} size={size}>
      <HStack spacing={0} fontSize={size}>
        {!hideIcon && <Box as={FontAwesomeIcon} mr={1} icon={sectionIcon} />}
        <Text as="span" mr={1} fontWeight="medium">
          {sectionReference.name}
        </Text>
      </HStack>
    </Link>
  );
}
