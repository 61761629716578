import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum, TagRelationTypeDto } from '../../../../api';
import companyApi from '../../../../data-access/company-api';
import { BreadcrumbRouteObject } from '../../../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../../../ui/helmet/helmet-outlet';
import { companyIcon } from '../../../../ui/icons/business-objects';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePromise from '../../../../util/use-promise/use-promise';
import CompanyPage from '../../../company/company-page/company-page';
import { fetchCompany } from '../../../company/company-queries';

const CompanyTagRelationContent = React.lazy(() => import('../tag-relation-page-content'));

const companyTagRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: '',
    element: <CompanyPage />,
    children: [
      {
        path: 'tags',
        element: <CompanyTagContent />,
        handle: {
          helmet: <CompanyTagsHelmet />,
        },
      },
    ],
  },
];

export default companyTagRoutes;

function CompanyTagContent() {
  const relationType = TagRelationTypeDto.COMPANY;
  const params = useParams<{ companyId: string }>();
  const companyId = params.companyId;
  invariant(companyId, 'Empty companyId');

  const company = usePromise((signal) => companyApi.fetchCompany({ id: companyId }, { signal }), [companyId]);
  const companyRef = {
    id: companyId,
    name: company?.name,
  };

  const fromAnnotation = companyRef.name!;

  return (
    <CompanyTagRelationContent
      isAddingTagPossible={company?.status == CompanyDtoStatusEnum.ACTIVE}
      relationRef={companyRef}
      relationType={relationType}
      fromIcon={companyIcon}
      fromAnnotation={fromAnnotation}
    />
  );
}

function CompanyTagsHelmet() {
  const { t } = useTranslation('tag');
  const params = useParams<{ companyId: string }>();
  invariant(params.companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: params.companyId });

  return (
    <Helmet
      title={t('relations.company.helmet', {
        name: company.name,
      })}
    />
  );
}
