import { chakra, forwardRef, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EventDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import EventDeleteDialog from './event-delete-dialog';

export interface EventDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  event: EventDto;
  isDeletable: boolean;
  hasNoPermissionToDelete: boolean;
  connectedEvents: Array<string>;
  hasGuestList: boolean;
  hasPersonInRundown: boolean;
  hasKeyPlayer: boolean;
  hasLiveTicketingAttendee: boolean;
  hasMailing: boolean;
  onSuccess?(): void;
}

function EventDeleteButton(
  {
    children,
    event,
    isDeletable,
    hasNoPermissionToDelete,
    hasGuestList,
    hasPersonInRundown,
    hasKeyPlayer,
    hasLiveTicketingAttendee,
    hasMailing,
    connectedEvents,
    onSuccess,
    onClick,
    ...props
  }: EventDeleteButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { t } = useTranslation('event');
  const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

  const showDeleteSuccessToast = useToast({
    id: 'event-editor-success-toast',
    status: 'success',
  });

  const showDeleteFailedToast = useToast({
    id: 'event-editor-failed-toast',
    status: 'error',
  });

  const deleteEvent = async (clickEvent: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(clickEvent);

    if (clickEvent.defaultPrevented) {
      return;
    }

    invariant(event.id != null, 'Missing event id');

    const checked = await openDeleteDialog();
    if (checked) {
      try {
        await eventApi.deleteEvent(
          {
            id: event.id,
          },
          { allowedErrorCodes: [409] } as RequestInit,
        );

        showDeleteSuccessToast({
          title: t('lister.toast.delete_success.title'),
          description: t('lister.toast.delete_success.description', {
            event: event.title,
          }),
        });
        onSuccess?.();
      } catch (e) {
        showDeleteFailedToast({
          title: t('lister.toast.delete_failed.title'),
          description: t('lister.toast.delete_failed.description', {
            event: event.title,
          }),
        });
      }
    }
  };

  return (
    <>
      <DisableableButton
        {...props}
        ref={ref}
        disableReason={
          <EventNotDeletableReason
            event={event}
            hasGuestList={hasGuestList}
            hasPersonInRundown={hasPersonInRundown}
            hasKeyPlayer={hasKeyPlayer}
            hasLiveTicketingAttendee={hasLiveTicketingAttendee}
            hasNoPermissionToDelete={hasNoPermissionToDelete}
            hasMailing={hasMailing}
          />
        }
        onClick={deleteEvent}
        isDisabled={!isDeletable || hasNoPermissionToDelete || undefined}
      >
        {children}
      </DisableableButton>
      <EventDeleteDialog
        event={event}
        isOpen={deleteDialogIsOpen}
        onClose={onDeleteDialogClose}
        connectedEvents={connectedEvents}
      />
    </>
  );
}

export default forwardRef<EventDeleteButtonProps, 'button'>(EventDeleteButton);

interface EventNotDeletableDialogProps {
  event: EventDto;
  hasGuestList: boolean;
  hasPersonInRundown: boolean;
  hasKeyPlayer: boolean;
  hasLiveTicketingAttendee: boolean;
  hasNoPermissionToDelete: boolean;
  hasMailing: boolean;
}

function EventNotDeletableReason({
  event,
  hasGuestList,
  hasPersonInRundown,
  hasKeyPlayer,
  hasLiveTicketingAttendee,
  hasNoPermissionToDelete,
  hasMailing,
}: EventNotDeletableDialogProps) {
  const { t } = useTranslation('event');

  return (
    <Stack>
      <Text>{t('lister.toast.delete_failed.conclusion')}</Text>
      {hasGuestList && (
        <Text>
          {t('lister.toast.delete_failed.guestList.description', {
            event: event.title,
          })}
        </Text>
      )}
      {hasPersonInRundown && <Text>{t('lister.toast.delete_failed.rundown.description')}</Text>}
      {hasKeyPlayer && <Text>{t('lister.toast.delete_failed.key_player.description')}</Text>}
      {hasLiveTicketingAttendee && (
        <chakra.div> {t('lister.toast.delete_failed.live_ticketing.description')} </chakra.div>
      )}
      {hasMailing && <chakra.div> {t('lister.toast.delete_failed.mailing.description')} </chakra.div>}
      {hasNoPermissionToDelete && (
        <Text>
          {t('lister.toast.delete_failed.permission.description', {
            event: event.title,
          })}
        </Text>
      )}
    </Stack>
  );
}
