import { TFunction } from 'i18next';
import { isEmpty } from 'lodash-es';

export function validateUrl(t: TFunction, link: string) {
  if (isEmpty(link)) {
    return true;
  }

  try {
    const url = new URL(link);
    return (
      ((url.protocol === 'http:' || url.protocol === 'https:') && !isEmpty(url.host) && !link.includes(' ')) ||
      t('validation_error.invalidUrlFormat')
    );
  } catch (e) {
    return t('validation_error.invalidUrlFormat');
  }
}
