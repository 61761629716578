import { Stack } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { EventArchivedLinkDto, EventDto, EventLinkDto, EventLinkDtoInternalSoftwareEnum } from '../../../api';
import DateInputFormControl from '../../../ui/form/date-input-control/date-input-form-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../ui/form/element-control';
import AddElementButton from '../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../ui/form/element-control/edit-element-button';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import now from '../../../util/now';
import archivedLinkColumns from '../event-table-columns/archived-link-columns';
import linkColumns from '../event-table-columns/link-columns';

/**
 * Represents a form control for security inside {@link EventForm}
 */
export default function LinksControl() {
  const { t } = useTranslation('event');

  return (
    <Stack spacing={4}>
      <ElementTableControl<EventDto, EventLinkDto>
        label={t('links.label')}
        addButton={<AddElementButton label={t('links.add')} formModal={<LinkFormModal />} />}
        editButton={<EditElementButton label={t('links.edit')} formModal={<LinkFormModal />} />}
        deleteButton={
          <DeleteElementButton<EventLinkDto>
            label={t('links.delete')}
            renderDeleteMessage={(link) => <Trans t={t} i18nKey="links.delete_message" values={{ link: link.url }} />}
          />
        }
        name="links"
        columns={linkColumns}
      />
      <ElementTableControl<EventDto, EventArchivedLinkDto>
        label={t('archived_links.label')}
        addButton={<AddElementButton label={t('archived_links.add')} formModal={<ArchivedLinkFormModal />} />}
        editButton={<EditElementButton label={t('archived_links.edit')} formModal={<ArchivedLinkFormModal />} />}
        deleteButton={
          <DeleteElementButton<EventArchivedLinkDto>
            label={t('archived_links.delete')}
            renderDeleteMessage={(link) => (
              <Trans t={t} i18nKey="archived_links.delete_message" values={{ link: link.url }} />
            )}
          />
        }
        name="archivedLinks"
        columns={archivedLinkColumns}
      />
    </Stack>
  );
}

function ArchivedLinkFormModal() {
  const { t } = useTranslation('event');
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { element: link, onSubmit } = useElementForm<EventArchivedLinkDto>();
  const { register } = useFormContext<EventArchivedLinkDto>();
  register('timestamp');

  return (
    <ElementFormModal<EventArchivedLinkDto>
      onSubmit={onSubmit}
      element={link}
      initialFocusRef={initialFocusRef}
      defaultElement={{ timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <InputFormControl<EventArchivedLinkDto>
          label={t('archived_links.link')}
          name="url"
          validate={validateLink(t, null)}
          isRequired
          maxLength={1000}
          ref={initialFocusRef}
          helperText={t('archived_links.helper_text')}
          showRequiredValidation={false}
        />
        <DateInputFormControl<EventArchivedLinkDto> label={t('archived_links.expiration_date')} name="expirationDate" />
        <InputFormControl<EventArchivedLinkDto> label={t('comment')} name="comment" maxLength={100} />
      </Stack>
    </ElementFormModal>
  );
}

function LinkFormModal() {
  const { t } = useTranslation(['event', 'common']);
  const initialFocusRef = React.useRef<HTMLSelectElement>(null);
  const { element: link, onSubmit } = useElementForm<EventLinkDto>();
  const internalSoftwareOptions = [
    EventLinkDtoInternalSoftwareEnum.FILE_SERVER,
    EventLinkDtoInternalSoftwareEnum.WIKI,
    EventLinkDtoInternalSoftwareEnum.FF,
  ];
  const { register } = useFormContext<EventLinkDto>();
  const [internalSoftware] = useWatch<EventLinkDto, ['internalSoftware']>({
    name: ['internalSoftware'],
  });
  register('timestamp');

  return (
    <ElementFormModal<EventLinkDto>
      onSubmit={onSubmit}
      element={link}
      initialFocusRef={initialFocusRef}
      defaultElement={{ timestamp: new Date(now()) }}
    >
      <Stack spacing={4}>
        <FormControl<EventLinkDto> label={t('event:links.internal_software.label')} name="internalSoftware" isRequired>
          <ValueSelectControl
            options={internalSoftwareOptions}
            renderLabel={(option) => t(`event:links.internalSoftwareOptions.${option}`)}
            name="internalSoftware"
            ref={initialFocusRef}
            rules={{
              deps: ['url'],
              required: t('common:validation_error.required', {
                field: t('event:links.internal_software.label'),
              }),
            }}
          />
        </FormControl>
        <InputFormControl<EventLinkDto>
          helperPopover={<HelperPopover>{t('event:links.link_helper_popover')}</HelperPopover>}
          label={t('event:links.link')}
          name="url"
          isRequired
          maxLength={1000}
          validate={validateLink(t, internalSoftware)}
          helperText={t('event:links.link_helper_text')}
          showRequiredValidation={false}
        />
      </Stack>
    </ElementFormModal>
  );
}

function validateLink(t: TFunction<'event'>, internalSoftware: EventLinkDtoInternalSoftwareEnum | null) {
  const REGEX_FILE_SERVER = /^[A-Za-z]:\\+.*$/;
  const REGEX_URL_SCHEME = /^([a-z])+:(\/{2})+.*$/;

  return async (fieldValue: string) => {
    if (!fieldValue) {
      return internalSoftware === null ? t('validation_error.empty_link_archived') : t('validation_error.empty_link');
    }

    fieldValue = fieldValue.trim();

    switch (internalSoftware) {
      case EventLinkDtoInternalSoftwareEnum.WIKI:
        return fieldValue.startsWith('https://wiki.kbb.eu') || t('validation_error.invalid_link');
      case EventLinkDtoInternalSoftwareEnum.FF:
        return fieldValue.startsWith('https://ff.kbb.intern') || t('validation_error.invalid_link');
      case EventLinkDtoInternalSoftwareEnum.FILE_SERVER:
        return REGEX_FILE_SERVER.test(fieldValue) || t('validation_error.invalid_link');
      default: {
        let isValidUrl: boolean;

        try {
          new URL(fieldValue);
          isValidUrl = true;
        } catch (e) {
          isValidUrl = false;
        }
        const isValidUrlScheme = REGEX_FILE_SERVER.test(fieldValue) || REGEX_URL_SCHEME.test(fieldValue);
        return (isValidUrl && isValidUrlScheme) || t('validation_error.invalid_link');
      }
    }
  };
}
