/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminRoleForClassGroupPageDto,
  AdminSectionRoleDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  RemoveStaffsFromStaffRoleRequestDto,
  RoleReferenceDto,
  RoleReferencePageDto,
  RolesForStaffPageDto,
  SectionRolePageDto,
  StaffSectionRoleDto,
  StaffsForSectionRolePageDto,
} from '../models/index';
import {
    AdminRoleForClassGroupPageDtoFromJSON,
    AdminRoleForClassGroupPageDtoToJSON,
    AdminSectionRoleDtoFromJSON,
    AdminSectionRoleDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    RemoveStaffsFromStaffRoleRequestDtoFromJSON,
    RemoveStaffsFromStaffRoleRequestDtoToJSON,
    RoleReferenceDtoFromJSON,
    RoleReferenceDtoToJSON,
    RoleReferencePageDtoFromJSON,
    RoleReferencePageDtoToJSON,
    RolesForStaffPageDtoFromJSON,
    RolesForStaffPageDtoToJSON,
    SectionRolePageDtoFromJSON,
    SectionRolePageDtoToJSON,
    StaffSectionRoleDtoFromJSON,
    StaffSectionRoleDtoToJSON,
    StaffsForSectionRolePageDtoFromJSON,
    StaffsForSectionRolePageDtoToJSON,
} from '../models/index';

export interface ActivateStaffRoleRequest {
    id: string;
}

export interface AddStaffsToStaffRoleRequest {
    id: string;
    requestBody: Array<string>;
}

export interface ArchiveStaffRoleRequest {
    id: string;
}

export interface CreateStaffSectionRoleRequest {
    staffSectionRoleDto: StaffSectionRoleDto;
}

export interface ExistsStaffRoleNameRequest {
    name: string;
    id: string;
    sectionId: string;
}

export interface FetchAdminSectionRoleRequest {
    sectionId: string;
}

export interface FetchAdminSectionRoleHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchStaffRoleMemberHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchStaffSectionRoleRequest {
    id: string;
}

export interface FetchStaffSectionRoleHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface RemoveStaffsFromStaffRoleRequest {
    removeStaffsFromStaffRoleRequestDto: RemoveStaffsFromStaffRoleRequestDto;
}

export interface SearchAdminRolesForClassGroupRequest {
    classGroupId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchPermissionRoleListItemsRequest {
    sectionId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchRoleReferencesForSectionRequest {
    sectionId: string;
    includeAdminRole: boolean;
    pageable?: PageableDto;
    searchquery?: string;
}

export interface SearchRolesForStaffRequest {
    staffId: string;
    pageable?: PageableDto;
    filter?: Array<string>;
}

export interface SearchStaffsForRoleRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface TemplateStaffSectionRoleRequest {
    sectionId: string;
}

export interface UpdateAdminSectionRoleRequest {
    sectionId: string;
    adminSectionRoleDto: AdminSectionRoleDto;
}

export interface UpdateStaffSectionRoleRequest {
    id: string;
    staffSectionRoleDto: StaffSectionRoleDto;
}

/**
 * 
 */
export class PermissionRoleApi extends runtime.BaseAPI {

    /**
     * Activate the role
     */
    async activateStaffRoleRaw(requestParameters: ActivateStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateStaffRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the role
     */
    async activateStaffRole(requestParameters: ActivateStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateStaffRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addStaffsToStaffRoleRaw(requestParameters: AddStaffsToStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addStaffsToStaffRole().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling addStaffsToStaffRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/assign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addStaffsToStaffRole(requestParameters: AddStaffsToStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addStaffsToStaffRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Archive the role
     */
    async archiveStaffRoleRaw(requestParameters: ArchiveStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling archiveStaffRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive the role
     */
    async archiveStaffRole(requestParameters: ArchiveStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveStaffRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createStaffSectionRoleRaw(requestParameters: CreateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['staffSectionRoleDto'] == null) {
            throw new runtime.RequiredError(
                'staffSectionRoleDto',
                'Required parameter "staffSectionRoleDto" was null or undefined when calling createStaffSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffSectionRoleDtoToJSON(requestParameters['staffSectionRoleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createStaffSectionRole(requestParameters: CreateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createStaffSectionRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async existsStaffRoleNameRaw(requestParameters: ExistsStaffRoleNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleReferenceDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling existsStaffRoleName().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling existsStaffRoleName().'
            );
        }

        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling existsStaffRoleName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/{sectionId}/exists-staff-role-name`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters['sectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleReferenceDtoFromJSON(jsonValue));
    }

    /**
     */
    async existsStaffRoleName(requestParameters: ExistsStaffRoleNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleReferenceDto | null | undefined > {
        const response = await this.existsStaffRoleNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     */
    async fetchAdminSectionRoleRaw(requestParameters: FetchAdminSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminSectionRoleDto>> {
        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling fetchAdminSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/{sectionId}`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters['sectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSectionRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchAdminSectionRole(requestParameters: FetchAdminSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminSectionRoleDto> {
        const response = await this.fetchAdminSectionRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchAdminSectionRoleHistoryRaw(requestParameters: FetchAdminSectionRoleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAdminSectionRoleHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchAdminSectionRoleHistory(requestParameters: FetchAdminSectionRoleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchAdminSectionRoleHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchStaffRoleMemberHistoryRaw(requestParameters: FetchStaffRoleMemberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStaffRoleMemberHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/member-versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchStaffRoleMemberHistory(requestParameters: FetchStaffRoleMemberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStaffRoleMemberHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchStaffSectionRoleRaw(requestParameters: FetchStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffSectionRoleDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStaffSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffSectionRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchStaffSectionRole(requestParameters: FetchStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffSectionRoleDto> {
        const response = await this.fetchStaffSectionRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchStaffSectionRoleHistoryRaw(requestParameters: FetchStaffSectionRoleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchStaffSectionRoleHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchStaffSectionRoleHistory(requestParameters: FetchStaffSectionRoleHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchStaffSectionRoleHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeStaffsFromStaffRoleRaw(requestParameters: RemoveStaffsFromStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['removeStaffsFromStaffRoleRequestDto'] == null) {
            throw new runtime.RequiredError(
                'removeStaffsFromStaffRoleRequestDto',
                'Required parameter "removeStaffsFromStaffRoleRequestDto" was null or undefined when calling removeStaffsFromStaffRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/unassign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveStaffsFromStaffRoleRequestDtoToJSON(requestParameters['removeStaffsFromStaffRoleRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeStaffsFromStaffRole(requestParameters: RemoveStaffsFromStaffRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeStaffsFromStaffRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Search admin roles for class group.
     */
    async searchAdminRolesForClassGroupRaw(requestParameters: SearchAdminRolesForClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminRoleForClassGroupPageDto>> {
        if (requestParameters['classGroupId'] == null) {
            throw new runtime.RequiredError(
                'classGroupId',
                'Required parameter "classGroupId" was null or undefined when calling searchAdminRolesForClassGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/by-class-group/{classGroupId}`.replace(`{${"classGroupId"}}`, encodeURIComponent(String(requestParameters['classGroupId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminRoleForClassGroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search admin roles for class group.
     */
    async searchAdminRolesForClassGroup(requestParameters: SearchAdminRolesForClassGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminRoleForClassGroupPageDto> {
        const response = await this.searchAdminRolesForClassGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchPermissionRoleListItemsRaw(requestParameters: SearchPermissionRoleListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionRolePageDto>> {
        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling searchPermissionRoleListItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sectionId'] != null) {
            queryParameters['sectionId'] = requestParameters['sectionId'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionRolePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchPermissionRoleListItems(requestParameters: SearchPermissionRoleListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionRolePageDto> {
        const response = await this.searchPermissionRoleListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRoleReferencesForSectionRaw(requestParameters: SearchRoleReferencesForSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleReferencePageDto>> {
        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling searchRoleReferencesForSection().'
            );
        }

        if (requestParameters['includeAdminRole'] == null) {
            throw new runtime.RequiredError(
                'includeAdminRole',
                'Required parameter "includeAdminRole" was null or undefined when calling searchRoleReferencesForSection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['sectionId'] != null) {
            queryParameters['sectionId'] = requestParameters['sectionId'];
        }

        if (requestParameters['includeAdminRole'] != null) {
            queryParameters['includeAdminRole'] = requestParameters['includeAdminRole'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleReferencePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchRoleReferencesForSection(requestParameters: SearchRoleReferencesForSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleReferencePageDto> {
        const response = await this.searchRoleReferencesForSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRolesForStaffRaw(requestParameters: SearchRolesForStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RolesForStaffPageDto>> {
        if (requestParameters['staffId'] == null) {
            throw new runtime.RequiredError(
                'staffId',
                'Required parameter "staffId" was null or undefined when calling searchRolesForStaff().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['staffId'] != null) {
            queryParameters['staffId'] = requestParameters['staffId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/for-staff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RolesForStaffPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchRolesForStaff(requestParameters: SearchRolesForStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RolesForStaffPageDto> {
        const response = await this.searchRolesForStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchStaffsForRoleRaw(requestParameters: SearchStaffsForRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffsForSectionRolePageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchStaffsForRole().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}/staffs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffsForSectionRolePageDtoFromJSON(jsonValue));
    }

    /**
     */
    async searchStaffsForRole(requestParameters: SearchStaffsForRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffsForSectionRolePageDto> {
        const response = await this.searchStaffsForRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new staff role.
     */
    async templateStaffSectionRoleRaw(requestParameters: TemplateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffSectionRoleDto>> {
        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling templateStaffSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/{sectionId}/template-staff-role`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters['sectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffSectionRoleDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new staff role.
     */
    async templateStaffSectionRole(requestParameters: TemplateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffSectionRoleDto> {
        const response = await this.templateStaffSectionRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAdminSectionRoleRaw(requestParameters: UpdateAdminSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sectionId'] == null) {
            throw new runtime.RequiredError(
                'sectionId',
                'Required parameter "sectionId" was null or undefined when calling updateAdminSectionRole().'
            );
        }

        if (requestParameters['adminSectionRoleDto'] == null) {
            throw new runtime.RequiredError(
                'adminSectionRoleDto',
                'Required parameter "adminSectionRoleDto" was null or undefined when calling updateAdminSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/admin/{sectionId}`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters['sectionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminSectionRoleDtoToJSON(requestParameters['adminSectionRoleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAdminSectionRole(requestParameters: UpdateAdminSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAdminSectionRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateStaffSectionRoleRaw(requestParameters: UpdateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateStaffSectionRole().'
            );
        }

        if (requestParameters['staffSectionRoleDto'] == null) {
            throw new runtime.RequiredError(
                'staffSectionRoleDto',
                'Required parameter "staffSectionRoleDto" was null or undefined when calling updateStaffSectionRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-roles/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StaffSectionRoleDtoToJSON(requestParameters['staffSectionRoleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateStaffSectionRole(requestParameters: UpdateStaffSectionRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStaffSectionRoleRaw(requestParameters, initOverrides);
    }

}
