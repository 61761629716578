import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum, CompanyReferenceDto } from '../../../../api';
import companyApi from '../../../../data-access/company-api';
import ValueAsyncSelectControl, {
  ValueAsyncSelectControlProps,
} from '../../../../ui/form/select-control/value-async-select-control';

export interface CompanySelectControlProps
  extends Omit<
    ValueAsyncSelectControlProps<CompanyReferenceDto>,
    'loadOptions' | 'renderLabel' | 'optionIdentifier' | 'defaultOptions'
  > {
  filter?: string[];
}

export default function CompanySelectControl({
  filter = [`status,eq,${CompanyDtoStatusEnum.ACTIVE}`],
  isRequired,
  label,
  ...props
}: CompanySelectControlProps) {
  const { t } = useTranslation('common');

  return (
    <ValueAsyncSelectControl<CompanyReferenceDto>
      {...props}
      loadOptions={async (q: string, size: number) => {
        const { content } = await companyApi.searchCompanies({
          pageable: { size },
          filter,
          q,
        });
        return content.map(({ name, id }) => {
          invariant(id != null);
          return {
            id,
            name,
          };
        });
      }}
      rules={{
        required: isRequired
          ? t('validation_error.required', {
              field: label,
            })
          : false,
      }}
      defaultOptions={false}
      renderLabel={(company) => company.name}
      optionIdentifier={(company) => company.id}
    />
  );
}
