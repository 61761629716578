import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { FieldPathByValue } from 'react-hook-form/dist/types/path/eager';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';
import { EditionReferenceDto } from '../../../api';
import FormControl, { FormControlProps } from '../../../ui/form/form-control';
import EditionSelect from '../../edition/edition-select/edition-select';

interface EditionSelectControlProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EditionReferenceDto | null>,
> extends Omit<FormControlProps<TFieldValues, TName>, 'children'> {
  filter?: string[];
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  isClearable?: boolean;
}

export default function EditionSelectControl<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EditionReferenceDto | null>,
>({ name, filter, rules, initialFocusRef, isClearable, ...props }: EditionSelectControlProps<TFieldValues, TName>) {
  const { t } = useTranslation(['edition']);
  const { field } = useController({
    name,
    rules: { ...rules, required: props.isRequired },
  });

  return (
    <FormControl<TFieldValues> name={name} label={t('edition:edition')} {...props}>
      <EditionSelect
        {...field}
        filter={filter}
        ref={initialFocusRef}
        isClearable={isClearable}
        isRequired={props.isRequired}
      />
    </FormControl>
  );
}
