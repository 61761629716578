import { Wrap, WrapItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SectionStaffListItemDto, StaffListItemDto } from '../../api';
import permissionRoleApi from '../../data-access/permission-role-api';
import { DataTableState } from '../../ui/data-table';
import InFilter from '../../ui/data-table/filter/in-filter';
import StringFilter from '../../ui/data-table/filter/string-filter';
import Optional from '../../ui/optional/optional';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SECTION_STAFF_LISTER_ACTION_EXTENSION } from '../relations/section-staff/section-staff-lister/section-staff-lister-action-extension';
import {
  SECTION_STAFF_LISTER_COLUMN_EXTENSION,
  SectionStaffListerColumnExtension,
} from '../relations/section-staff/section-staff-lister/section-staff-lister-column-extension';
import { SECTION_STAFF_LISTER_SELECTION_ACTION_EXTENSION } from '../relations/section-staff/section-staff-lister/section-staff-selection-action-extension';
import getSectionId from '../section/common/get-section-id';
import { SECTION_PAGE_ROUTES_CALLBACK } from '../section/section-page/section-page-route';
import { SECTION_PAGE_TAB } from '../section/section-page/section-page-tab';
import { SECTION_ROUTES_CALLBACK } from '../section/section-route';
import {
  STAFF_LISTER_COLUMN_EXTENSION,
  StaffListerColumnExtension,
} from '../staff/staff-lister/staff-lister-column-extension';
import { STAFF_PAGE_ROUTES_CALLBACK, STAFF_PAGE_TAB } from '../staff/staff-page/staff-page-tab-extension';
import { sectionPageRoutes, staffPageRoutes } from './permission-role-route-extensions';
import { sectionRoutes } from './permission-role-routes';
import RoleReference from './role-reference/role-reference';
import AssignStaffsToStaffRoleMenuItem from './staff-role-member/assign-staffs-role-button';
import AssignStaffsRoleSelectionButton from './staff-role-member/assign-staffs-role-selection-button';
import UnassignStaffsToStaffRoleMenuItem from './staff-role-member/unassign-staffs-role-button';
import UnassignStaffsRoleSelectionButton from './staff-role-member/unassign-staffs-role-selection-button';

export default function PermissionRolePluginProvider(registry: PluginRegistry) {
  registry.register(SECTION_PAGE_TAB, (builder) =>
    builder.before('history', {
      key: 'roles',
      element: (
        <PageTab to="./roles">
          <Translate ns="permission_role" i18nKey="roles" />
        </PageTab>
      ),
    }),
  );

  registry.register(STAFF_PAGE_TAB, (builder) =>
    builder.before('history', {
      key: 'roles',
      element: (
        <PageTab to="./roles">
          <Translate ns="permission_role" i18nKey="roles" />
        </PageTab>
      ),
    }),
  );

  registry.register(SECTION_STAFF_LISTER_COLUMN_EXTENSION, roleColumn);
  registry.register(SECTION_PAGE_ROUTES_CALLBACK, sectionPageRoutes);
  registry.register(SECTION_ROUTES_CALLBACK, sectionRoutes);
  registry.register(STAFF_PAGE_ROUTES_CALLBACK, staffPageRoutes);
  registry.register(STAFF_LISTER_COLUMN_EXTENSION, staffListerRolesColumn);
  registry.register(
    SECTION_STAFF_LISTER_ACTION_EXTENSION,
    (staff: SectionStaffListItemDto) =>
      staff.role == null && <AssignStaffsToStaffRoleMenuItem key="assign" staffId={staff.id} />,
  );
  registry.register(
    SECTION_STAFF_LISTER_ACTION_EXTENSION,
    (staff: SectionStaffListItemDto) =>
      staff.role != null && <UnassignStaffsToStaffRoleMenuItem key="unassign" staff={staff} />,
  );
  registry.register(
    SECTION_STAFF_LISTER_SELECTION_ACTION_EXTENSION,
    (staffs: SectionStaffListItemDto[], patchState: (patchedState: Partial<DataTableState>) => void) => (
      <AssignStaffsRoleSelectionButton staffs={staffs} patchState={patchState} />
    ),
  );
  registry.register(
    SECTION_STAFF_LISTER_SELECTION_ACTION_EXTENSION,
    (staffs: SectionStaffListItemDto[], patchState: (patchedState: Partial<DataTableState>) => void) => (
      <UnassignStaffsRoleSelectionButton staffs={staffs} patchState={patchState} />
    ),
  );
}

export const roleColumn: SectionStaffListerColumnExtension = {
  columnToAdd: {
    key: 'role',
    name: 'Rolle',
    cellProps: {
      whiteSpace: 'nowrap',
    },
    renderCell: (staff: SectionStaffListItemDto) => (
      <Optional>{staff.role && <RoleReference roleReference={staff.role} />}</Optional>
    ),
    sortProperty: 'role.name',
    filterProperty: 'role.id',
    filter: <SectionStaffFilter />,
    isSortable: true,
  },
};

function SectionStaffFilter() {
  const sectionId = getSectionId(useParams());
  const { t } = useTranslation('permission_role');

  return (
    <InFilter
      label={<Translate ns="permission_role" i18nKey="assignStaffRole.role" />}
      operator="in"
      loadOptions={async (query: string) => {
        const roles = await permissionRoleApi.searchRoleReferencesForSection({
          includeAdminRole: true,
          sectionId,
          searchquery: query,
        });

        return roles.content.map((role) => ({
          value: role.id,
          label: role.name ?? t('adminRole'),
        }));
      }}
    />
  );
}

export const staffListerRolesColumn: StaffListerColumnExtension = {
  columnToAdd: {
    key: 'roles',
    name: <Translate ns="staff" i18nKey="roles.title" />,
    renderCell: (data: StaffListItemDto) => (
      <Wrap spacingX={4} spacingY={2}>
        <Optional>
          {data.roles.map((role) => (
            <WrapItem key={role.id}>
              <RoleReference roleReference={role} size="md" />
            </WrapItem>
          ))}
        </Optional>
      </Wrap>
    ),
    filterProperty: 'roles[*].name',
    filter: (
      <StringFilter
        label={<Translate ns="staff" i18nKey="roles.title" />}
        availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
      />
    ),
  },
};
