import { Draft, produce } from 'immer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GuestListSettingsDto, RecipientLinkDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import { DataTableColumn } from '../../../ui/data-table';
import { isParentDataTableColumn, ParentDataTableColumn } from '../../../ui/data-table/data-table-column';
import InFilter from '../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import useGuestTableColumns from '../../guest-list/guest-table-columns/guest-table-columns';
import { isPersonRecipientLink } from '../../mailing/mailing-recipient-util/person-recipient-link';
import PersonReference from '../../person/person-reference/person-reference';
import { isPeopleOnGuestListRecipientLink } from './event-mailing-extensions-utils';

export function usePersonOnGuestListMailingRecipientColumns(guestList: GuestListSettingsDto) {
  const { t } = useTranslation(['mailing', 'guest_list']);
  const guestListColumns = useGuestTableColumns({
    guestList,
    showCoupledColumn: false,
    keyPrefix: 'linkedRecipient.',
    accessor(data: RecipientLinkDto) {
      return isPeopleOnGuestListRecipientLink(data) ? data.linkedRecipient : undefined;
    },
  });

  return useMemo<DataTableColumn<RecipientLinkDto>[]>(() => {
    return produce(guestListColumns, (draft) => {
      const guestColumn = draft.find(
        (column): column is Draft<ParentDataTableColumn<RecipientLinkDto>> =>
          isParentDataTableColumn(column) && column?.key === 'linkedRecipient.guest',
      );

      invariant(guestColumn != null, 'Guest column must be defined');

      guestColumn.filter = (
        <InFilter
          label={t('mailing:recipients.person')}
          loadOptions={async (value: string) => {
            if (!value) {
              return [];
            }
            const page = await personApi.searchPersons({
              pageable: { size: 10 },
              q: value,
            });

            return page.content.map((person) => ({
              value: person.id!,
              label: `${person.firstName} ${person.surname}`,
            }));
          }}
        />
      );

      guestColumn.filterProperty = 'linkedRecipient.person.id';
      guestColumn.sortProperty = 'linkedRecipient.name';

      const originalRenderCell = guestColumn.renderCell;

      guestColumn.renderCell = (recipientLink, index) => (
        <Optional>
          {isPersonRecipientLink(recipientLink) ? (
            <PersonReference
              hidePersonKey
              flipName
              personReference={recipientLink.linkedRecipient}
              isTruncated
              usePortalForCard
            />
          ) : (
            originalRenderCell?.(recipientLink, index)
          )}
        </Optional>
      );

      draft.splice(draft.indexOf(guestColumn) + 1, 0, {
        key: 'linkedRecipient.email',
        name: t('guest_list:email_addresses'),
        isSortable: true,
        renderCell: (recipientLink) => {
          if (!isPeopleOnGuestListRecipientLink(recipientLink)) {
            return <Optional />;
          }

          invariant(
            recipientLink.linkedRecipient.person != null,
            'Person on guest list cannot be a placeholder or bodyguard',
          );

          return recipientLink.linkedRecipient.person.emailAddresses?.join(', ');
        },
        filter: (
          <StringFilter
            label={t('guest_list:email_addresses')}
            availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
          />
        ),
      });

      draft.splice(
        draft.findIndex((column) => isParentDataTableColumn(column) && column.key === 'linkedRecipient.guestType'),
        1,
      );
    });
  }, [guestListColumns, t]);
}
