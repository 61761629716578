import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompanyReferenceDto,
  EventArchivedLinkDto,
  EventDto,
  EventDtoCateringEnum,
  EventDtoDressCodeEnum,
  EventDtoSecurityLevelEnum,
  EventEventConnectionDto,
  EventLinkDto,
  EventLinkDtoInternalSoftwareEnum,
  EventPartnerDtoPartnerTypeEnum,
  EventSecurityStaffDto,
  EventSecurityStaffDtoTypeEnum,
  EventStatusDto,
  EventTypeDto,
  EventVenueDtoRealisationEnum,
} from '../../../api';
import HistoryDisplaySettings from '../../../ui/history/history-display-settings';
import { getOwnerSettings } from '../../common/owner/owner-history-settings';

export default function useEventHistorySettings(): HistoryDisplaySettings<EventDto> {
  const { t } = useTranslation('event');
  const { t: tCommon } = useTranslation('common');

  return useMemo<HistoryDisplaySettings<EventDto>>(() => {
    return {
      attributeLabels: {
        title: t('title'),
        englishTitle: t('englishTitle'),
        edition: t('edition.label'),
        dateTimeRange: t('date_time_range.label'),
        owners: tCommon('owners.label'),
        eventEventConnections: t('eventConnections.label'),
        'venue.realisation': t('realisation'),
        'venue.location': t('venue.location'),
        'venue.digitalVenue': t('venue.digital_venue'),
        'venue.comment': t('venue.comment'),
        'partners.partnerType': t('partner.title_history'),
        'partners.companies': t('partner.title'),
        type: t('type.label'),
        accessControl: t('access_control.label'),
        accessGroups: t('access_group.label'),
        securityLevel: t('securityLevel.label'),
        eventSecurityStaff: t('securityStaff.label'),
        links: t('links.label'),
        archivedLinks: t('archived_links.label'),
        catering: t('catering.label'),
        dressCode: t('dress_code.label'),
        visible: t('visibility.label'),
        status: t('status.label'),
      },
      valueFormatter: {
        edition: (value) => value?.name,
        'venue.realisation': (value) =>
          value == null ? undefined : t(`realisationLabels.${value as EventVenueDtoRealisationEnum}`),
        'venue.location': (value) => value?.name,
        'partners.partnerType': (value) =>
          value == null ? undefined : t(`partner.labels.${value as EventPartnerDtoPartnerTypeEnum}`),
        dateTimeRange: (value) =>
          value == null ? undefined : tCommon('format.datetime_range', { dateTimeRange: value }),
        status: (value) => (value == null ? undefined : t(`statusOptions.${value as EventStatusDto}`)),
        type: (value) => (value == null ? undefined : t(`type.labels.${value as EventTypeDto}`)),
        accessControl: (value) =>
          value == null || value.length === 0 ? undefined : value.map((v) => t(`accessControlOptions.${v}`)).join(', '),
        accessGroups: (value) =>
          value == null || value.length === 0 ? undefined : value.map((v) => v.title).join(', '),
        visible: (value) => (value == null ? undefined : t(`visibility.visibility_label.${value as boolean}`)),
        securityLevel: (value) =>
          value == null ? undefined : t(`securityLevelOptions.${value as EventDtoSecurityLevelEnum}`),
        catering: (value) => (value == null ? undefined : t(`cateringOptions.${value as EventDtoCateringEnum}`)),
        dressCode: (value) => (value == null ? undefined : t(`dressCodeOptions.${value as EventDtoDressCodeEnum}`)),
      },
      subElementSettings: {
        owners: getOwnerSettings(tCommon),
        eventSecurityStaff: getEventSecurityStaffSettings(t),
        links: getLinksSettings(t),
        archivedLinks: getArchivedLinksSettings(t, tCommon),
        eventEventConnections: getEventEventConnectionSettings(t),
        'partners.companies': getPartnersCompaniesSettings(t),
      },
      diffEntireWord: {
        status: true,
        visible: true,
        type: true,
        securityLevel: true,
        'venue.realisation': true,
        'venue.location': true,
        catering: true,
        dressCode: true,
        'partners.partnerType': true,
      },
      name: 'title',
    };
  }, [t, tCommon]);
}

function getEventSecurityStaffSettings(t: TFunction<'event'>) {
  return (): HistoryDisplaySettings<EventSecurityStaffDto> => ({
    attributeLabels: {
      type: t('securityStaff.label'),
      count: t('securityStaff.count'),
      comment: t('comment'),
    },
    valueFormatter: {
      type: (value) => (value == null ? undefined : t(`securityStaff.type.${value as EventSecurityStaffDtoTypeEnum}`)),
      count: (value) => (value == null ? undefined : value.toString()),
    },
    diffEntireWord: {
      count: true,
      type: true,
    },
    name: 'type',
  });
}

function getLinksSettings(t: TFunction<'event'>) {
  return (): HistoryDisplaySettings<EventLinkDto> => ({
    attributeLabels: {
      url: t('links.label'),
      internalSoftware: t('links.internal_software.label'),
    },
    valueFormatter: {
      internalSoftware: (value) =>
        value == null ? undefined : t(`links.internalSoftwareOptions.${value as EventLinkDtoInternalSoftwareEnum}`),
    },
    diffEntireWord: {
      internalSoftware: true,
    },
    name: 'url',
  });
}

function getArchivedLinksSettings(t: TFunction<'event'>, tCommon: TFunction<'common'>) {
  return (): HistoryDisplaySettings<EventArchivedLinkDto> => ({
    attributeLabels: {
      url: t('links.label'),
      comment: t('comment'),
      expirationDate: t('links.internal_software.label'),
    },
    valueFormatter: {
      expirationDate: (value) => (value == null ? undefined : tCommon('format.date', { date: value })),
    },
    diffEntireWord: {},
    name: 'url',
  });
}

function getEventEventConnectionSettings(t: TFunction<'event'>) {
  return (): HistoryDisplaySettings<EventEventConnectionDto> => ({
    attributeLabels: {
      connectedEvent: t('eventConnections.label'),
    },
    valueFormatter: {
      connectedEvent: (value) =>
        value == null
          ? t('eventConnections.deleted_event_title')
          : value.title == null
            ? t('forbiddenEventTitle')
            : value.title,
    },
    diffEntireWord: {},
    name: 'connectedEvent',
    keyAttribute: 'connectedEvent.id',
  });
}

function getPartnersCompaniesSettings(t: TFunction<'event', undefined>) {
  return (): HistoryDisplaySettings<CompanyReferenceDto> => ({
    attributeLabels: {
      name: t('partner.company'),
    },
    valueFormatter: {},
    diffEntireWord: { name: true },
    name: 'name',
    keyAttribute: 'id',
  });
}
