import { Grid, GridItem } from '@chakra-ui/react';
import { concat, isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AwardDto, AwardWinnerRelationDto, EditionDto } from '../../../../api';
import awardWinnerApi from '../../../../data-access/award-winner-api';
import { DataTableColumn, DataTableState, useDataTableState } from '../../../../ui/data-table';
import DataTable from '../../../../ui/data-table/data-table';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import PersonReference from '../../../person/person-reference/person-reference';
import usePermission from '../../../staff-account/use-permission/use-permission';
import DeleteRelationsButton from '../../common/delete-relations-button';
import { awardWinnerFetcher, fetchAwardWinnerRelations } from '../award-winner-queries';

function useAwardWinnerRelations(state: DataTableState, awardId: string, editionId: string) {
  const requestParams = useRequestParams(state, [
    { property: 'winner.surname', direction: 'ASC' },
    { property: 'winner.firstName', direction: 'ASC' },
  ]);

  return useFetcher(
    fetchAwardWinnerRelations,
    {
      ...requestParams,
      filter: concat(requestParams.filter as string[], [`award.id,eq,${awardId}`, `edition.id,eq,${editionId}`]),
    },
    { use: [fallbackMiddleware] },
  );
}

export default function AwardWinnerRelationDataTable({ award, edition }: { award: AwardDto; edition: EditionDto }) {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'person', 'relations', 'award']);
  const page = useAwardWinnerRelations(state, award.id!, edition.id!);
  const canEditAward = usePermission('AWARD:FULL-EDIT');

  const columns: DataTableColumn<AwardWinnerRelationDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<AwardWinnerRelationDto>[] = [
      {
        key: 'winner.surname',
        name: t('person:surname'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (relation) => (
          <PersonReference
            personReference={{
              id: relation.winner.id,
              firstName: relation.winner.firstName,
              surname: relation.winner.surname,
            }}
            usePortalForCard={true}
            surnameOnly
            hidePersonKey
            hideIcon
          />
        ),
        filter: (
          <StringFilter
            label={t('person:surname')}
            loadOptions={async (value: string) => {
              const page = await awardWinnerApi.searchAwardWinnerRelations({
                pageable: { size: 10 },
                filter: [`award.id,eq,${award.id}`, `winner.surname,contain,${value}`],
              });

              return page.content.map((relation) => relation.winner.surname!);
            }}
          />
        ),
      },
      {
        key: 'winner.firstName',
        name: t('person:firstName'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (relation) => (
          <PersonReference
            personReference={{
              id: relation.winner.id,
              firstName: relation.winner.firstName,
              surname: relation.winner.surname,
            }}
            usePortalForCard={true}
            firstNameOnly
            hidePersonKey
            hideIcon
          />
        ),
        filter: (
          <StringFilter
            label={t('person:firstName')}
            loadOptions={async (value: string) => {
              const page = await awardWinnerApi.searchAwardWinnerRelations({
                pageable: { size: 10 },
                filter: [`award.id,eq,${award.id}`, `winner.firstName,contain,${value}`],
              });

              return page.content.map((relation) => relation.winner.firstName!);
            }}
          />
        ),
      },
    ];

    return columns;
  }, [t, award]);

  const rowKey = React.useCallback((awardWinner: AwardWinnerRelationDto) => {
    invariant(awardWinner.id != null, 'Missing award winner relation id');

    return awardWinner.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          selection={canEditAward ? { keySelector: rowKey } : undefined}
          onStateChange={setState}
          isPageable
        />
      </GridItem>
      <GridItem display="flex" flexDir="row" justifyContent="end">
        {canEditAward && (
          <DeleteRelationsButton
            selection={state.selection}
            isDisabled={isEmpty(state.selection)}
            deleteRelations={(ids) => awardWinnerApi.deleteAwardWinnersByIds({ ids: ids })}
            mutate={() => awardWinnerFetcher.mutate()}
            resetSelection={() => {
              setState({ ...state, selection: [] });
            }}
          />
        )}
      </GridItem>
    </Grid>
  );
}
