import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { tagIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function TagSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'TAG', `${result.resultType} is no instance of TagSearchResult`);

  const tag = result.result;
  const { t } = useTranslation('tag');

  return (
    <SearchResult icon={tagIcon} title={tag.name} link={`/tags/${tag.id}`} score={result.score} onClick={onClick}>
      <SearchResultAttributes
        attributes={[
          {
            label: t('type'),
            element: t(`typeOptions.${tag.type}`),
          },
          {
            label: t('status'),
            element: t(`statusOptions.${tag.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
