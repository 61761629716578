import { Grid, GridItem, Link, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { AccessGroupDto, AccessGroupPersonListItemDto, EditionDto } from '../../../../api';
import personApi from '../../../../data-access/person-api';
import productApi from '../../../../data-access/product-api';
import DataTable, {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../ui/data-table';
import DateFilter from '../../../../ui/data-table/filter/date-filter';
import InFilter from '../../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import Optional from '../../../../ui/optional/optional';
import LastModifiedLabel from '../../../../ui/version/last-modified-label';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import CompanyReference from '../../../company/company-reference/company-reference';
import ProductReference from '../../../product/product-reference/product-reference';
import { fetchPersonsByAccessGroup } from '../person-access-group-queries';

function useAccessGroupPersonListItems(state: DataTableState, accessGroupId: string, editionId: string) {
  const requestParams = useRequestParams(state, [{ property: 'modifiedAt', direction: 'DESC' }]);
  return useFetcher(
    fetchPersonsByAccessGroup,
    {
      ...requestParams,
      id: accessGroupId,
      filter: [...requestParams.filter, `edition.id,eq,${editionId}`],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function AccessGroupPersonViewer({
  accessGroup,
  edition,
}: {
  accessGroup: AccessGroupDto;
  edition: EditionDto;
}) {
  const { t } = useTranslation(['common', 'person', 'relations']);
  const [state, setState] = useDataTableState();
  const page = useAccessGroupPersonListItems(state, accessGroup.id!, edition.id!);

  const columns: DataTableColumn<AccessGroupPersonListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<AccessGroupPersonListItemDto>[] = [
      {
        key: 'surname',
        sticky: true,
        name: t('person:surname'),
        isSortable: true,
        renderCell: (item: AccessGroupPersonListItemDto) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`/persons/${item.id!}`}>
              {item.surname}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('person:surname')}
            loadOptions={async (value: string) => {
              const page = await personApi.searchPersons({
                pageable: { size: 10 },
                filter: [`surname,contain,${value}`],
              });
              return page.content.map((person) => person.surname);
            }}
          />
        ),
      },
      {
        key: 'firstName',
        name: t('person:firstName'),
        isSortable: true,
        renderCell: (item: AccessGroupPersonListItemDto) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`/persons/${item.id!}`}>
              {item.firstName}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('person:firstName')}
            loadOptions={async (value: string) => {
              const page = await personApi.searchPersons({
                pageable: { size: 10 },
                filter: [`firstName,contain,${value}`],
              });
              return page.content.map((person) => person.firstName);
            }}
          />
        ),
      },
      {
        key: 'company',
        name: t('person:occupations.company'),
        isSortable: true,
        renderCell: (item) => (
          <Optional isEmpty={item.company == null}>
            <CompanyReference companyReference={item.company} />
          </Optional>
        ),
        filter: <StringFilter label={t('person:occupations.company')} />,
      },
      {
        key: 'products',
        filterProperty: 'products[*].id',
        name: <Translate ns="product" i18nKey="lister.title" />,
        renderCell: (item) => (
          <Optional isEmpty={item.products.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {item.products.map((product, idx) => (
                <WrapItem key={idx}>
                  <ProductReference product={product} />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="products" />}
            operator="allin"
            loadOptions={async (query: string) => {
              const products = await productApi.searchProducts({ filter: [`title,contain,${query}`] });

              return products.content.map((product) => ({
                label: product.title,
                value: product.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'modifiedAt',
        name: t('common:viewer.last_modification'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (item) => <LastModifiedLabel version={item.version} />,
        isSortable: true,
        sortProperty: 'modifiedAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [t]);

  const rowKey = React.useCallback((relation: AccessGroupPersonListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
