import React from 'react';
import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';
import groupTemplateMenuItems from '../group-template/group-template-menu-items';

const personMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.person" />,
    path: '/persons',
    neccessaryPermission: 'PERSON:CAN_SEE_LIST_VIEW',
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.account" />,
    path: '/accounts',
    hasAccess: () => true,
  },
  ...groupTemplateMenuItems,
  {
    name: <Translate ns="app" i18nKey="main_navigation.group" />,
    path: '/groups',
    hasAccess: () => true,
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.participations" />,
    path: '/participations',
  },
];

export default personMenuItems;
