import { Button, ButtonGroup, Menu, MenuItem, MenuList, Tag } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { LocationStatusCheckDto, LocationStatusDto } from '../../../api';
import locationApi from '../../../data-access/location-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import LocationDeleteButton from '../location-delete/location-delete-button';
import useLocation from '../use-location/use-location';
import { NewPlacementButton } from './new-placement-button';

export interface LocationTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const LOCATION_TAB_ITEM = new PluginToken<LocationTabItem>('LocationTabItem');

export default function LocationPage() {
  const { t } = useTranslation('location');
  const { locationId } = useParams<{ locationId: string }>();
  const [isDeletable, setDeletable] = React.useState<boolean>(false);
  const [locationStatusCheck, setLocationStatusCheck] = React.useState<LocationStatusCheckDto>({
    isDeletable: false,
    rundownReferences: [],
    eventReferences: [],
  });
  // lh: Not the prettiest way to decide the primary action for the page header.
  // Maybe we should solve this via routing somehow?
  const showingPlacementTemplates = useMatch('/locations/:locationId/placement-templates') != null;
  const navigate = useNavigate();

  invariant(locationId, 'Empty locationId');
  const location = useLocation(locationId);

  const checkStatus = async () => {
    setDeletable(false);
    invariant(location.id != null, 'Missing locationId');
    const statusCheck = await locationApi.checkLocationStatus({ locationId: location.id });
    setDeletable(statusCheck.isDeletable);
    setLocationStatusCheck(statusCheck);
  };

  const tabs = <LocationPageTabs />;

  return (
    <Page>
      <PageHeader
        title={
          <>
            {location.name}
            {location.status === LocationStatusDto.HISTORIC && <Tag m={2}>{t('statusLabel.HISTORIC')}</Tag>}
          </>
        }
        actions={
          showingPlacementTemplates ? (
            <NewPlacementButton isDisabled={location.status !== LocationStatusDto.ACTIVE} />
          ) : location.permissions?.canEdit ? (
            <ButtonGroup>
              {location.permissions?.canDelete && (
                <Menu onOpen={checkStatus}>
                  <BurgerMenuButton />
                  <MenuList>
                    <MenuItem
                      color="text.error"
                      as={LocationDeleteButton}
                      location={location}
                      isDeletable={isDeletable}
                      locationStatusCheck={locationStatusCheck}
                      onSuccess={() => navigate(`/locations`)}
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    >
                      {t('action.delete')}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
              <Button as={RouterLink} to="edit" variant="primary">
                {t('action.edit')}
              </Button>
            </ButtonGroup>
          ) : undefined
        }
        tabs={tabs}
      />
      <Outlet />
    </Page>
  );
}

function LocationPageTabs() {
  const { t: tAttachment } = useTranslation('attachment');
  const { t: tCommon } = useTranslation('common');
  const { t: tPlacementTemplate } = useTranslation('placement_template');

  const tabItems = usePlugins(LOCATION_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./attachments">{tAttachment('attachments')}</PageTab>
      <PageTab to="./placement-templates">{tPlacementTemplate('placement_templates')}</PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {tCommon('history.label')}
      </PageTab>
    </>
  );
}
