import { ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { endOfDay } from 'date-fns';
import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CountryDto, CountryDtoStatusEnum, CountryReferenceDto, GenderIdentityDto, PersonDto } from '../../../api';
import countryApi from '../../../data-access/country-api';
import DateInputControl from '../../../ui/form/date-input-control/date-input-control';
import FormControl from '../../../ui/form/form-control';
import InputFormControl from '../../../ui/form/input-form-control';
import MultiValueAsyncSelectControl from '../../../ui/form/select-control/multi-value-async-select-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { LATIN_ALPHABET_AND_NUMBERS_AND_PUNCATIONS } from '../../../util/constants';
import now from '../../../util/now';
import { LayoutType } from '../../common/LayoutType';
import { genderIdentityOptions, officiallyRegisteredGenderOptions } from '../person-enum-constants';
import { DuplicateWarning } from './duplicate-warning';

export interface AdditionalPersonalDataControlProps {
  layout: LayoutType;
}

export default function AdditionalPersonalDataControl({ layout }: AdditionalPersonalDataControlProps) {
  const { t } = useTranslation('person');
  const today = endOfDay(new Date(now()));

  return (
    <Stack spacing={4}>
      <Stack spacing={6} alignItems="flex-start">
        <Stack direction={layout} spacing={6} width="100%">
          <PartialGenderTypeControl />
        </Stack>

        <FormControl<PersonDto>
          label={t('officiallyRegisteredGender')}
          name="officiallyRegisteredGender"
          helperText={t('officiallyRegisteredGenderHelper')}
        >
          <ValueSelectControl
            options={officiallyRegisteredGenderOptions}
            renderLabel={(option) => t(`officiallyRegisteredGenderLabels.${option}`)}
            name="officiallyRegisteredGender"
            isClearable
          />
        </FormControl>
      </Stack>

      <Stack direction={layout} spacing={6} alignItems="flex-start">
        <FormControl label={t('date_of_birth')} name="dateOfBirth">
          <DateInputControl<PersonDto>
            name="dateOfBirth"
            showYearDropdown
            autocompletePastOnly
            max={{ value: today, message: t('date_of_birth_validation_error') }}
          />
        </FormControl>

        <InputFormControl<PersonDto> label={t('place_of_birth')} name="placeOfBirth" maxLength={30} />

        <NationalitiesControl />
      </Stack>
      <DuplicateWarning />
    </Stack>
  );
}

export function NationalitiesControl<T extends FieldValues>({ path }: { path?: FieldPath<T> }) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');
  const nationalities: CountryDto[] = useWatch({ name: 'nationalities' });
  const NATIONALITY_PAGE_SIZE = 500;

  return (
    <FormControl label={t('nationalities')} name={prefixWithPath('nationalities')}>
      <MultiValueAsyncSelectControl<CountryReferenceDto>
        loadOptions={async (value: string) => {
          const page = await countryApi.searchCountries({
            pageable: { size: NATIONALITY_PAGE_SIZE },
            filter: [`status,eq,${CountryDtoStatusEnum.ACTIVE}`],
            q: value,
          });

          return page.content
            .map((country) => ({
              id: country.id!,
              name: country.name,
              englishName: country.englishName,
            }))
            .filter((country) =>
              nationalities ? !nationalities.map((nationality) => nationality.id!).includes(country.id) : true,
            );
        }}
        renderLabel={(country) => country.name}
        optionIdentifier={(country) => country.id}
        defaultOptions
        name={prefixWithPath('nationalities')}
      />
    </FormControl>
  );
}

export function PartialGenderTypeControl<T extends FieldValues>({ path }: { path?: FieldPath<T> }) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation(['person', 'common']);
  const { watch, setValue, clearErrors } = useFormContext<PersonDto>();

  const genderIdentity = watch(prefixWithPath('genderIdentity'));

  return (
    <Stack spacing={4}>
      <FormControl
        label={t('person:genderIdentity')}
        name={prefixWithPath('genderIdentity')}
        helperPopover={
          <HelperPopover header={t('person:genderIdentityPopoverTitle')}>
            <Trans t={t} i18nKey="person:genderIdentityInfo" components={{ ul: <UnorderedList />, li: <ListItem /> }} />
          </HelperPopover>
        }
      >
        <ValueSelectControl
          options={genderIdentityOptions}
          renderLabel={(option) => t(`person:genderIdentityLabels.${option}`)}
          name={prefixWithPath('genderIdentity')}
          defaultValue={GenderIdentityDto.NOT_SPECIFIED}
          onChange={(value) => {
            if (value !== GenderIdentityDto.FREE_HAND_TEXT) {
              // React Hook Forms cannot work with undefined. The value of genderIdentityText has to be set to null. But our API does not accept null. Hence, we cast it to undefined.
              setValue(prefixWithPath('genderIdentityText'), null as unknown as undefined);
              clearErrors(prefixWithPath('genderIdentityText'));
            }
          }}
        />
      </FormControl>
      <InputFormControl
        label={t('person:genderIdentityLabels.FREE_HAND_TEXT')}
        name={prefixWithPath('genderIdentityText')}
        maxLength={50}
        isDisabled={genderIdentity !== GenderIdentityDto.FREE_HAND_TEXT}
        isRequired={genderIdentity === GenderIdentityDto.FREE_HAND_TEXT}
        pattern={{
          value: LATIN_ALPHABET_AND_NUMBERS_AND_PUNCATIONS,
          message: t('common:validation_error.latin_alphabet_and_numbers_and_punctuation', {
            field: t('person:genderIdentityLabels.FREE_HAND_TEXT'),
          }),
        }}
      />
    </Stack>
  );
}
