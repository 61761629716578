import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { locationIcon } from '../../ui/icons/business-objects';
import { postalAddressToString } from '../../ui/postal-address/render-postal-address';
import PersonReference from '../person/person-reference/person-reference';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';
import SearchResultConnection from '../search/search-result-connection';

export default function LocationSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'LOCATION', `${result.resultType} is no instance of LocationSearchResult`);

  const location = result.result;
  const { t } = useTranslation('location');

  return (
    <SearchResult
      icon={locationIcon}
      title={`${location.name} (${location.abbreviation})`}
      link={`/locations/${location.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('contacts.postal_address.header'),
            element: location.postalAddresses.map(postalAddressToString).join(', '),
          },
          { label: t('nameEnglish'), element: location.englishName },
          {
            label: t('utilisations'),
            element: (location.utilisations ?? []).map((util) => t(`utilisationsLabel.${util}`)).join(', '),
          },
        ]}
      />
      {location.locationPersonRelations?.map((rel) => (
        <SearchResultConnection
          key={rel.timestamp?.toISOString()}
          onTargetClick={onClick}
          connection={
            <SearchResultAttributes
              attributes={[
                {
                  element: t('contacts.relations.relationType'),
                },
                {
                  label: t('contacts.relations.comment'),
                  element: rel.comment,
                },
              ]}
            />
          }
          targetIcon={locationIcon}
        >
          <PersonReference size="sm" personReference={rel.connectedPerson} hideIcon />
        </SearchResultConnection>
      ))}
    </SearchResult>
  );
}
