import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePrompt from '../../util/use-prompt/use-prompt';

export default function useUnsavedChangesPrompt(
  { dirtyFields, isSubmitting, isSubmitted }: FormState<any>,
  promptExemptions?: RegExp,
) {
  const { t } = useTranslation('common');

  usePrompt({
    message: t('misc.unsaved_changes'),
    when: Object.keys(dirtyFields).length > 0 && !isSubmitting && !isSubmitted,
    exemption: promptExemptions,
  });
}
