import { Box, chakra, useToken } from '@chakra-ui/react';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isHotkey from 'is-hotkey';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useRef, useState } from 'react';
import { BasePoint, BaseRange, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import invariant from 'tiny-invariant';
import { LinkElement } from './format-types';
import { RichTextOptions } from './rich-text-options';
import { Element, Leaf, Toolbar } from './slate-components';
import createEditor, { WithFunction } from './with/create-editor';
import withBold from './with/with-bold';
import withCitation from './with/with-citation';
import withEnhancedBreaks from './with/with-enhanced-breaks';
import withH1 from './with/with-h1';
import withH2 from './with/with-h2';
import withItalic from './with/with-italic';
import withLink from './with/with-link';
import withLinkButton from './with/with-link-button';
import withParagraph from './with/with-paragraph';

interface RichTextProps {
  onChange: (value: Descendant[]) => void;
  value: Descendant[];
  labelId: string;
  defaultOptions: RichTextOptions[];
}

const editorPropsBuilder: Record<RichTextOptions, WithFunction> = {
  HEADLINE: withH1({
    render: ({ children, attributes }) => (
      <chakra.h1
        sx={{
          fontSize: '30px',
          lineHeight: '40px',
          fontFamily: 'inherit',

          '* + &': {
            marginTop: '1em',
          },
        }}
        {...attributes}
      >
        {children}
      </chakra.h1>
    ),
  }),
  SUB_HEADLINE: withH2({
    render: ({ children, attributes }) => (
      <chakra.h2
        sx={{
          fontSize: '26px',
          lineHeight: '30px',
          fontFamily: 'inherit',

          '* + &': {
            marginTop: '1em',
          },
        }}
        {...attributes}
      >
        {children}
      </chakra.h2>
    ),
  }),
  PARAGRAPH: withParagraph({
    render: ({ children, attributes }) => (
      <chakra.p
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          fontFamily: 'inherit',
          color: 'inherit',
          '* + &': {
            marginTop: '1em',
          },
        }}
        {...attributes}
      >
        {children}
      </chakra.p>
    ),
  }),
  BOLD: withBold({
    render: (children) => <strong>{children}</strong>,
  }),
  ITALIC: withItalic({
    render: (children) => <em>{children}</em>,
  }),
  HYPERLINK: withLink({
    render: ({ children, attributes, element }) => {
      element = element as LinkElement;

      return (
        <>
          <FontAwesomeIcon style={{ marginRight: '4px' }} icon={faArrowUpRight} color="#D62E4A" />
          <chakra.a
            href={element.url}
            sx={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              fontFamily: 'inherit',
              color: '#D62E4A',
            }}
            {...attributes}
          >
            {children}
          </chakra.a>
        </>
      );
    },
  }),
  HYPERLINK_BUTTON: withLinkButton(),
  CITATION: withCitation(),
};

const blockOptions = [
  RichTextOptions.HEADLINE,
  RichTextOptions.SUB_HEADLINE,
  RichTextOptions.CITATION,
  RichTextOptions.PARAGRAPH,
];

// wraps slate with styling to make it look and feel like a chakra component
export default React.forwardRef<HTMLDivElement, RichTextProps>(
  ({ onChange, value, labelId, defaultOptions }: RichTextProps, ref) => {
    const [initialValue] = useState(isEmpty(value) ? getInitialValue(defaultOptions) : value);
    const initialOptions = useRef(defaultOptions);
    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
    const focusBorderColor = useToken('colors', 'border.interactive');

    const [editor] = useState(() => {
      const amountOfBlockOptions = blockOptions.filter((option) => initialOptions.current.includes(option)).length;
      invariant(amountOfBlockOptions > 0, 'at least one block option must be chosen');

      const config = {
        onlyOneBlockOption: amountOfBlockOptions === 1,
        paragraphPresent: initialOptions.current.includes(RichTextOptions.PARAGRAPH),
      };

      return initialOptions.current.reduce(
        (editor, option) => editorPropsBuilder[option](editor, config),
        withHistory(withReact(withEnhancedBreaks(createEditor(), config))),
      );
    });

    if (editor.selection != null && !isRangeInNodes(editor.selection, value)) {
      editor.selection = null;
    }
    editor.children = isEmpty(value) ? initialValue : value;

    return (
      <Box ref={ref}>
        <Slate editor={editor} onChange={onChange} initialValue={initialValue}>
          <Toolbar />
          <Box
            as={Editable}
            aria-labelledby={labelId}
            typeof="textarea"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '8px',
              paddingBottom: '8px',
              border: '1px solid',
              borderColor: 'border.01',
              bgColor: 'layer.01',
              borderTopLeftRadius: defaultOptions.length > 1 ? '0' : '0.25rem',
              borderTopRightRadius: defaultOptions.length > 1 ? '0' : '0.25rem',
              borderBottomLeftRadius: '0.25rem',
              borderBottomRightRadius: '0.25rem',
              minHeight: '50px',
              maxHeight: '300px',
              overflow: 'auto',
              resize: 'vertical',
              // TODO
              fontFamily: 'arial',
              color: 'text',
              _focus: {
                borderColor: 'border.interactive',
                boxShadow: `0 0 0 1px ${focusBorderColor}`,
                outline: 'none',
              },
            }}
            onKeyDown={(event) => {
              const pressedHotkey = editor.hotkeys.find((hotkey) => isHotkey(hotkey.hotkey, event));
              if (pressedHotkey === undefined) {
                return;
              }
              event.preventDefault();
              pressedHotkey.action();
            }}
          />
        </Slate>
      </Box>
    );
  },
);

function isRangeInNodes(range: BaseRange, nodes: Descendant[]): boolean {
  return isPointInNodes(range.anchor, nodes) && isPointInNodes(range.focus, nodes);
}

function isPointInNodes(point: BasePoint, nodes: Descendant[]): boolean {
  if (point.path.length === 0 || nodes == null || nodes.length === 0) {
    return false;
  }

  if (point.path[0] > nodes.length) {
    return false;
  }

  const node = nodes[point.path[0]];
  if (node.type === 'text') {
    return node.text.length >= point.offset;
  }

  return isPointInNodes({ path: point.path.slice(1), offset: point.offset }, node.children);
}

function getInitialValue(options: RichTextOptions[]): Descendant[] {
  const blockOptions = options.filter((option) => ['HEADLINE', 'SUB_HEADLINE', 'PARAGRAPH'].includes(option));
  invariant(blockOptions.length > 0, 'block option needed');

  if (blockOptions.includes(RichTextOptions.PARAGRAPH)) {
    return [
      {
        type: 'paragraph',
        children: [{ text: '', type: 'text' }],
      },
    ];
  }

  if (blockOptions.includes(RichTextOptions.HEADLINE)) {
    return [
      {
        type: 'heading1',
        children: [{ text: '', type: 'text' }],
      },
    ];
  } else {
    return [
      {
        type: 'heading2',
        children: [{ text: '', type: 'text' }],
      },
    ];
  }
}
