import { InitOverrideFunction } from '../../api';
import permissionApi from '../../data-access/permission-api';
import { createFetcherScope } from '../../util/swr/fetcher-scope';

export const groupTemplateFetcher = createFetcherScope(permissionApi);

export const fetchPermissions = groupTemplateFetcher.createFetcher(
  (initOverrides?: RequestInit | InitOverrideFunction | undefined) =>
    permissionApi
      .fetchSelf(initOverrides)
      .then((x) => x.map((permission) => `${permission.name}:${permission.action}`)),
);
