/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { ProductBundleListItemAccessGroupsInnerDto } from './ProductBundleListItemAccessGroupsInnerDto';
import {
    ProductBundleListItemAccessGroupsInnerDtoFromJSON,
    ProductBundleListItemAccessGroupsInnerDtoFromJSONTyped,
    ProductBundleListItemAccessGroupsInnerDtoToJSON,
} from './ProductBundleListItemAccessGroupsInnerDto';
import type { ProductBundleStatusDto } from './ProductBundleStatusDto';
import {
    ProductBundleStatusDtoFromJSON,
    ProductBundleStatusDtoFromJSONTyped,
    ProductBundleStatusDtoToJSON,
} from './ProductBundleStatusDto';

/**
 * 
 * @export
 * @interface ProductBundleListItemDto
 */
export interface ProductBundleListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ProductBundleListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListItemDto
     */
    articleNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListItemDto
     */
    title: string;
    /**
     * 
     * @type {Array<ProductBundleListItemAccessGroupsInnerDto>}
     * @memberof ProductBundleListItemDto
     */
    accessGroups: Array<ProductBundleListItemAccessGroupsInnerDto>;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListItemDto
     */
    amount: number;
    /**
     * 
     * @type {ProductBundleStatusDto}
     * @memberof ProductBundleListItemDto
     */
    status: ProductBundleStatusDto;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof ProductBundleListItemDto
     */
    availability: ClosedDateTimeRangeDto;
}

/**
 * Check if a given object implements the ProductBundleListItemDto interface.
 */
export function instanceOfProductBundleListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('articleNumber' in value)) return false;
    if (!('title' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('status' in value)) return false;
    if (!('availability' in value)) return false;
    return true;
}

export function ProductBundleListItemDtoFromJSON(json: any): ProductBundleListItemDto {
    return ProductBundleListItemDtoFromJSONTyped(json, false);
}

export function ProductBundleListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNumber': json['articleNumber'],
        'title': json['title'],
        'accessGroups': ((json['accessGroups'] as Array<any>).map(ProductBundleListItemAccessGroupsInnerDtoFromJSON)),
        'amount': json['amount'],
        'status': ProductBundleStatusDtoFromJSON(json['status']),
        'availability': ClosedDateTimeRangeDtoFromJSON(json['availability']),
    };
}

export function ProductBundleListItemDtoToJSON(value?: ProductBundleListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'articleNumber': value['articleNumber'],
        'title': value['title'],
        'accessGroups': ((value['accessGroups'] as Array<any>).map(ProductBundleListItemAccessGroupsInnerDtoToJSON)),
        'amount': value['amount'],
        'status': ProductBundleStatusDtoToJSON(value['status']),
        'availability': ClosedDateTimeRangeDtoToJSON(value['availability']),
    };
}

