import { StaffListItemDto } from '../../../api';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Translate from '../../../util/translate/translate';
import { StaffListerColumnExtension } from '../../staff/staff-lister/staff-lister-column-extension';
import PersonStaffReference from './person-staff-reference';

export const staffListerPersonColumn: StaffListerColumnExtension = {
  columnToAdd: {
    key: 'person',
    name: <Translate ns="staff" i18nKey="person" />,
    cellProps: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    renderCell: (data: StaffListItemDto) => <PersonStaffReference staff={data} />,
    filter: (
      <StringFilter
        label={<Translate ns="staff" i18nKey="person" />}
        availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
      />
    ),
    isSortable: true,
    sortProperty: 'person',
  },
};
