/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessToBuildingDto } from './AccessToBuildingDto';
import {
    AccessToBuildingDtoFromJSON,
    AccessToBuildingDtoFromJSONTyped,
    AccessToBuildingDtoToJSON,
} from './AccessToBuildingDto';
import type { BookingDto } from './BookingDto';
import {
    BookingDtoFromJSON,
    BookingDtoFromJSONTyped,
    BookingDtoToJSON,
} from './BookingDto';
import type { BoxOfficeDto } from './BoxOfficeDto';
import {
    BoxOfficeDtoFromJSON,
    BoxOfficeDtoFromJSONTyped,
    BoxOfficeDtoToJSON,
} from './BoxOfficeDto';
import type { CapacityDto } from './CapacityDto';
import {
    CapacityDtoFromJSON,
    CapacityDtoFromJSONTyped,
    CapacityDtoToJSON,
} from './CapacityDto';
import type { ConferenceRoomDto } from './ConferenceRoomDto';
import {
    ConferenceRoomDtoFromJSON,
    ConferenceRoomDtoFromJSONTyped,
    ConferenceRoomDtoToJSON,
} from './ConferenceRoomDto';
import type { ContractPeriodDto } from './ContractPeriodDto';
import {
    ContractPeriodDtoFromJSON,
    ContractPeriodDtoFromJSONTyped,
    ContractPeriodDtoToJSON,
} from './ContractPeriodDto';
import type { InternetAccessDto } from './InternetAccessDto';
import {
    InternetAccessDtoFromJSON,
    InternetAccessDtoFromJSONTyped,
    InternetAccessDtoToJSON,
} from './InternetAccessDto';
import type { LocationLinkDto } from './LocationLinkDto';
import {
    LocationLinkDtoFromJSON,
    LocationLinkDtoFromJSONTyped,
    LocationLinkDtoToJSON,
} from './LocationLinkDto';
import type { LocationPersonRelationDto } from './LocationPersonRelationDto';
import {
    LocationPersonRelationDtoFromJSON,
    LocationPersonRelationDtoFromJSONTyped,
    LocationPersonRelationDtoToJSON,
} from './LocationPersonRelationDto';
import type { LocationPhoneNumberDto } from './LocationPhoneNumberDto';
import {
    LocationPhoneNumberDtoFromJSON,
    LocationPhoneNumberDtoFromJSONTyped,
    LocationPhoneNumberDtoToJSON,
} from './LocationPhoneNumberDto';
import type { LocationPostalAddressDto } from './LocationPostalAddressDto';
import {
    LocationPostalAddressDtoFromJSON,
    LocationPostalAddressDtoFromJSONTyped,
    LocationPostalAddressDtoToJSON,
} from './LocationPostalAddressDto';
import type { LocationStatusDto } from './LocationStatusDto';
import {
    LocationStatusDtoFromJSON,
    LocationStatusDtoFromJSONTyped,
    LocationStatusDtoToJSON,
} from './LocationStatusDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { ServiceHoursDto } from './ServiceHoursDto';
import {
    ServiceHoursDtoFromJSON,
    ServiceHoursDtoFromJSONTyped,
    ServiceHoursDtoToJSON,
} from './ServiceHoursDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';
import type { ZoneDto } from './ZoneDto';
import {
    ZoneDtoFromJSON,
    ZoneDtoFromJSONTyped,
    ZoneDtoToJSON,
} from './ZoneDto';

/**
 * A location for the Berlinale festival.
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LocationDto
     */
    id?: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LocationDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof LocationDto
     */
    permissions?: PermissionsDto;
    /**
     * The unique name.
     * @type {string}
     * @memberof LocationDto
     */
    name: string;
    /**
     * The unique name in english.
     * @type {string}
     * @memberof LocationDto
     */
    englishName?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    abbreviation: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationDto
     */
    utilisations?: Array<LocationDtoUtilisationsEnum>;
    /**
     * List of phone numbers
     * @type {Array<LocationPhoneNumberDto>}
     * @memberof LocationDto
     */
    phoneNumbers?: Array<LocationPhoneNumberDto>;
    /**
     * List of postal addresses
     * @type {Array<LocationPostalAddressDto>}
     * @memberof LocationDto
     */
    postalAddresses: Array<LocationPostalAddressDto>;
    /**
     * List of links
     * @type {Array<LocationLinkDto>}
     * @memberof LocationDto
     */
    links?: Array<LocationLinkDto>;
    /**
     * List of internet accesses
     * @type {Array<InternetAccessDto>}
     * @memberof LocationDto
     */
    internetAccesses?: Array<InternetAccessDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationDto
     */
    accessibilities?: Array<LocationDtoAccessibilitiesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationDto
     */
    protocols?: Array<LocationDtoProtocolsEnum>;
    /**
     * An amount of retreating rooms
     * @type {number}
     * @memberof LocationDto
     */
    retreatingRoomsAmount?: number;
    /**
     * 
     * @type {Array<ServiceHoursDto>}
     * @memberof LocationDto
     */
    serviceHours?: Array<ServiceHoursDto>;
    /**
     * 
     * @type {Array<AccessToBuildingDto>}
     * @memberof LocationDto
     */
    accessToBuilding?: Array<AccessToBuildingDto>;
    /**
     * 
     * @type {Array<BoxOfficeDto>}
     * @memberof LocationDto
     */
    boxOffices?: Array<BoxOfficeDto>;
    /**
     * 
     * @type {CapacityDto}
     * @memberof LocationDto
     */
    capacity: CapacityDto;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    visible?: boolean;
    /**
     * 
     * @type {LocationStatusDto}
     * @memberof LocationDto
     */
    status: LocationStatusDto;
    /**
     * 
     * @type {Array<ConferenceRoomDto>}
     * @memberof LocationDto
     */
    conferenceRooms?: Array<ConferenceRoomDto>;
    /**
     * 
     * @type {BookingDto}
     * @memberof LocationDto
     */
    booking?: BookingDto;
    /**
     * 
     * @type {Array<ZoneDto>}
     * @memberof LocationDto
     */
    zones?: Array<ZoneDto>;
    /**
     * 
     * @type {Array<ContractPeriodDto>}
     * @memberof LocationDto
     */
    contractPeriods?: Array<ContractPeriodDto>;
    /**
     * List of location person relations
     * @type {Array<LocationPersonRelationDto>}
     * @memberof LocationDto
     */
    locationPersonRelations?: Array<LocationPersonRelationDto>;
}


/**
 * @export
 */
export const LocationDtoUtilisationsEnum = {
    CONFERENCE_ROOMS: 'CONFERENCE_ROOMS',
    OFFICE_SPACES: 'OFFICE_SPACES',
    HOTEL: 'HOTEL',
    INTERVIEW_SPACES: 'INTERVIEW_SPACES',
    LOUNGES: 'LOUNGES',
    MEDIA_CENTRE: 'MEDIA_CENTRE',
    SERVICE_POINT: 'SERVICE_POINT',
    CINEMA: 'CINEMA',
    EVENT_LOCATION: 'EVENT_LOCATION'
} as const;
export type LocationDtoUtilisationsEnum = typeof LocationDtoUtilisationsEnum[keyof typeof LocationDtoUtilisationsEnum];

/**
 * @export
 */
export const LocationDtoAccessibilitiesEnum = {
    LIFT_PUBLIC: 'LIFT_PUBLIC',
    LIFT_UPON_REQUEST: 'LIFT_UPON_REQUEST',
    STAGE_DOOR: 'STAGE_DOOR',
    FIRE_RESCUE_PATH: 'FIRE_RESCUE_PATH',
    MAIN_ENTRANCE: 'MAIN_ENTRANCE',
    WHEELCHAIR_SPACES: 'WHEELCHAIR_SPACES',
    SIDE_ENTRANCE: 'SIDE_ENTRANCE',
    TOILETS: 'TOILETS'
} as const;
export type LocationDtoAccessibilitiesEnum = typeof LocationDtoAccessibilitiesEnum[keyof typeof LocationDtoAccessibilitiesEnum];

/**
 * @export
 */
export const LocationDtoProtocolsEnum = {
    RED_CARPET: 'RED_CARPET',
    RETREATING_ROOMS: 'RETREATING_ROOMS',
    VIP_TOILETS_WOMEN: 'VIP_TOILETS_WOMEN',
    VIP_TOILETS_MEN: 'VIP_TOILETS_MEN',
    VIP_TOILETS_UNISEX: 'VIP_TOILETS_UNISEX',
    RIGHT_OF_WAY: 'RIGHT_OF_WAY'
} as const;
export type LocationDtoProtocolsEnum = typeof LocationDtoProtocolsEnum[keyof typeof LocationDtoProtocolsEnum];


/**
 * Check if a given object implements the LocationDto interface.
 */
export function instanceOfLocationDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('abbreviation' in value)) return false;
    if (!('postalAddresses' in value)) return false;
    if (!('capacity' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function LocationDtoFromJSON(json: any): LocationDto {
    return LocationDtoFromJSONTyped(json, false);
}

export function LocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'permissions': json['permissions'] == null ? undefined : PermissionsDtoFromJSON(json['permissions']),
        'name': json['name'],
        'englishName': json['englishName'] == null ? undefined : json['englishName'],
        'abbreviation': json['abbreviation'],
        'utilisations': json['utilisations'] == null ? undefined : json['utilisations'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(LocationPhoneNumberDtoFromJSON)),
        'postalAddresses': ((json['postalAddresses'] as Array<any>).map(LocationPostalAddressDtoFromJSON)),
        'links': json['links'] == null ? undefined : ((json['links'] as Array<any>).map(LocationLinkDtoFromJSON)),
        'internetAccesses': json['internetAccesses'] == null ? undefined : ((json['internetAccesses'] as Array<any>).map(InternetAccessDtoFromJSON)),
        'accessibilities': json['accessibilities'] == null ? undefined : json['accessibilities'],
        'protocols': json['protocols'] == null ? undefined : json['protocols'],
        'retreatingRoomsAmount': json['retreatingRoomsAmount'] == null ? undefined : json['retreatingRoomsAmount'],
        'serviceHours': json['serviceHours'] == null ? undefined : ((json['serviceHours'] as Array<any>).map(ServiceHoursDtoFromJSON)),
        'accessToBuilding': json['accessToBuilding'] == null ? undefined : ((json['accessToBuilding'] as Array<any>).map(AccessToBuildingDtoFromJSON)),
        'boxOffices': json['boxOffices'] == null ? undefined : ((json['boxOffices'] as Array<any>).map(BoxOfficeDtoFromJSON)),
        'capacity': CapacityDtoFromJSON(json['capacity']),
        'visible': json['visible'] == null ? undefined : json['visible'],
        'status': LocationStatusDtoFromJSON(json['status']),
        'conferenceRooms': json['conferenceRooms'] == null ? undefined : ((json['conferenceRooms'] as Array<any>).map(ConferenceRoomDtoFromJSON)),
        'booking': json['booking'] == null ? undefined : BookingDtoFromJSON(json['booking']),
        'zones': json['zones'] == null ? undefined : ((json['zones'] as Array<any>).map(ZoneDtoFromJSON)),
        'contractPeriods': json['contractPeriods'] == null ? undefined : ((json['contractPeriods'] as Array<any>).map(ContractPeriodDtoFromJSON)),
        'locationPersonRelations': json['locationPersonRelations'] == null ? undefined : ((json['locationPersonRelations'] as Array<any>).map(LocationPersonRelationDtoFromJSON)),
    };
}

export function LocationDtoToJSON(value?: LocationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
        'name': value['name'],
        'englishName': value['englishName'],
        'abbreviation': value['abbreviation'],
        'utilisations': value['utilisations'],
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(LocationPhoneNumberDtoToJSON)),
        'postalAddresses': ((value['postalAddresses'] as Array<any>).map(LocationPostalAddressDtoToJSON)),
        'links': value['links'] == null ? undefined : ((value['links'] as Array<any>).map(LocationLinkDtoToJSON)),
        'internetAccesses': value['internetAccesses'] == null ? undefined : ((value['internetAccesses'] as Array<any>).map(InternetAccessDtoToJSON)),
        'accessibilities': value['accessibilities'],
        'protocols': value['protocols'],
        'retreatingRoomsAmount': value['retreatingRoomsAmount'],
        'serviceHours': value['serviceHours'] == null ? undefined : ((value['serviceHours'] as Array<any>).map(ServiceHoursDtoToJSON)),
        'accessToBuilding': value['accessToBuilding'] == null ? undefined : ((value['accessToBuilding'] as Array<any>).map(AccessToBuildingDtoToJSON)),
        'boxOffices': value['boxOffices'] == null ? undefined : ((value['boxOffices'] as Array<any>).map(BoxOfficeDtoToJSON)),
        'capacity': CapacityDtoToJSON(value['capacity']),
        'visible': value['visible'],
        'status': LocationStatusDtoToJSON(value['status']),
        'conferenceRooms': value['conferenceRooms'] == null ? undefined : ((value['conferenceRooms'] as Array<any>).map(ConferenceRoomDtoToJSON)),
        'booking': BookingDtoToJSON(value['booking']),
        'zones': value['zones'] == null ? undefined : ((value['zones'] as Array<any>).map(ZoneDtoToJSON)),
        'contractPeriods': value['contractPeriods'] == null ? undefined : ((value['contractPeriods'] as Array<any>).map(ContractPeriodDtoToJSON)),
        'locationPersonRelations': value['locationPersonRelations'] == null ? undefined : ((value['locationPersonRelations'] as Array<any>).map(LocationPersonRelationDtoToJSON)),
    };
}

