import { mailingBlocklistIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import MailingBlocklistSearchResult from './mailing-blocklist-search-result';

export default function MailingBlocklistPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'BLOCKLIST', Component: MailingBlocklistSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'BLOCKLIST',
    icon: mailingBlocklistIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.BLOCKLIST" />,
  });
}
