import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { codeIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function CodeSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'CODE', `${result.resultType} is no instance of CodeSearchResult`);

  const code = result.result;
  const { t } = useTranslation(['code', 'common']);

  return (
    <SearchResult icon={codeIcon} title={code.code} link={`/codes/${code.id}`} score={result.score} onClick={onClick}>
      <SearchResultAttributes
        attributes={[
          {
            label: t('code:products'),
            element: code.products?.map((product) => product.title).join(', '),
          },
          {
            label: t('code:usedBy'),
            element: code.usedBy != null ? code.usedBy.firstName + ' ' + code.usedBy.surname : null,
          },
        ]}
      />
    </SearchResult>
  );
}
