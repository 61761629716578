/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailabilityDto,
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  ProductDto,
  ProductExtendedReferencePageDto,
  ProductListItemPageDto,
  ProductPageDto,
  ProductReferencePageDto,
} from '../models/index';
import {
    AvailabilityDtoFromJSON,
    AvailabilityDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    ProductExtendedReferencePageDtoFromJSON,
    ProductExtendedReferencePageDtoToJSON,
    ProductListItemPageDtoFromJSON,
    ProductListItemPageDtoToJSON,
    ProductPageDtoFromJSON,
    ProductPageDtoToJSON,
    ProductReferencePageDtoFromJSON,
    ProductReferencePageDtoToJSON,
} from '../models/index';

export interface ActivateProductRequest {
    id: string;
}

export interface ArchiveProductRequest {
    id: string;
}

export interface CreateProductRequest {
    productDto: ProductDto;
}

export interface FetchProductRequest {
    id: string;
}

export interface FetchProductHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchRecommendedProductsRequest {
    ids: Array<string>;
    pageable?: PageableDto;
}

export interface GetRelatedProductBundleAvailabilityRequest {
    id: string;
}

export interface SearchProductListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchProductReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchProductsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateProductRequest {
    id: string;
    productDto: ProductDto;
}

/**
 * 
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     * Activate the product
     */
    async activateProductRaw(requestParameters: ActivateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the product
     */
    async activateProduct(requestParameters: ActivateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateProductRaw(requestParameters, initOverrides);
    }

    /**
     * Archive the product
     */
    async archiveProductRaw(requestParameters: ArchiveProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling archiveProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive the product
     */
    async archiveProduct(requestParameters: ArchiveProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveProductRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether the product with type Invoice Fee can be created.
     */
    async canCreateInvoiceFeeProductRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/can-create-invoice-fee-product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the product with type Invoice Fee can be created.
     */
    async canCreateInvoiceFeeProduct(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.canCreateInvoiceFeeProductRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new product.
     */
    async createProductRaw(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productDto'] == null) {
            throw new runtime.RequiredError(
                'productDto',
                'Required parameter "productDto" was null or undefined when calling createProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDtoToJSON(requestParameters['productDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * Create a new product.
     */
    async createProduct(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.createProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the product business object.
     */
    async fetchProductRaw(requestParameters: FetchProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * Get the product business object.
     */
    async fetchProduct(requestParameters: FetchProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.fetchProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the product.
     */
    async fetchProductHistoryRaw(requestParameters: FetchProductHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchProductHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the product.
     */
    async fetchProductHistory(requestParameters: FetchProductHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchProductHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get recommended product for given list of ids
     */
    async fetchRecommendedProductsRaw(requestParameters: FetchRecommendedProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductExtendedReferencePageDto>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling fetchRecommendedProducts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/recommended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductExtendedReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Get recommended product for given list of ids
     */
    async fetchRecommendedProducts(requestParameters: FetchRecommendedProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductExtendedReferencePageDto> {
        const response = await this.fetchRecommendedProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the availability for all related product bundles
     */
    async getRelatedProductBundleAvailabilityRaw(requestParameters: GetRelatedProductBundleAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailabilityDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRelatedProductBundleAvailability().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/product-bundle-availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailabilityDtoFromJSON(jsonValue));
    }

    /**
     * Get the availability for all related product bundles
     */
    async getRelatedProductBundleAvailability(requestParameters: GetRelatedProductBundleAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailabilityDto> {
        const response = await this.getRelatedProductBundleAvailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product list items.
     */
    async searchProductListItemsRaw(requestParameters: SearchProductListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductListItemPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-list-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListItemPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product list items.
     */
    async searchProductListItems(requestParameters: SearchProductListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListItemPageDto> {
        const response = await this.searchProductListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for product references.
     */
    async searchProductReferencesRaw(requestParameters: SearchProductReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for product references.
     */
    async searchProductReferences(requestParameters: SearchProductReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductReferencePageDto> {
        const response = await this.searchProductReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for products.
     */
    async searchProductsRaw(requestParameters: SearchProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for products.
     */
    async searchProducts(requestParameters: SearchProductsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductPageDto> {
        const response = await this.searchProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new product.
     */
    async templateProductRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new product.
     */
    async templateProduct(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.templateProductRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing product.
     */
    async updateProductRaw(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateProduct().'
            );
        }

        if (requestParameters['productDto'] == null) {
            throw new runtime.RequiredError(
                'productDto',
                'Required parameter "productDto" was null or undefined when calling updateProduct().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductDtoToJSON(requestParameters['productDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing product.
     */
    async updateProduct(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.updateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
