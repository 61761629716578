/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanySectionDto
 */
export interface CompanySectionDto {
    /**
     * 
     * @type {string}
     * @memberof CompanySectionDto
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanySectionDto
     */
    selectedPostalAddress?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanySectionDto
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the CompanySectionDto interface.
 */
export function instanceOfCompanySectionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function CompanySectionDtoFromJSON(json: any): CompanySectionDto {
    return CompanySectionDtoFromJSONTyped(json, false);
}

export function CompanySectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'selectedPostalAddress': json['selectedPostalAddress'] == null ? undefined : (new Date(json['selectedPostalAddress'])),
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function CompanySectionDtoToJSON(value?: CompanySectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'selectedPostalAddress': value['selectedPostalAddress'] == null ? undefined : ((value['selectedPostalAddress']).toISOString()),
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

