import { useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MultiValueAsyncSelect, { MultiValueAsyncSelectProps } from '../../select/multi-value-async-select';

export interface MultiValueAsyncSelectControlProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<MultiValueAsyncSelectProps<TOption>, 'onChange' | 'values' | 'name'>,
    Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> {
  label?: string;
}

function MultiValueAsyncSelectControl<TOption>(
  { name, control, rules, defaultValue, isRequired, label, ...props }: MultiValueAsyncSelectControlProps<TOption>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { t } = useTranslation('common');

  const rulesWithRequired = {
    required: isRequired ? t('validation_error.required', { field: label }) : undefined,
    ...rules,
  };
  const { field, fieldState } = useController({
    name,
    control,
    rules: rulesWithRequired,
    defaultValue,
  });

  const mergedRef = useMergeRefs(field.ref, ref);

  return (
    <MultiValueAsyncSelect {...field} values={field.value} {...props} ref={mergedRef} isInvalid={fieldState.invalid} />
  );
}

export default React.forwardRef(MultiValueAsyncSelectControl);
