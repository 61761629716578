import { Box, Button, Heading, HStack, Stack, Text, useMergeRefs } from '@chakra-ui/react';
import { isArray, isEmpty } from 'lodash-es';
import React, { useId, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SearchResultDto } from '../../api';
import { BussinessObjectIconDefinition, renderBusinessObjectIcon } from '../../ui/icons/business-objects';
import isFocusable from '../../util/dom/is-focusable';
import usePermission, { Permission } from '../permission/use-permission';
import ResultHighlight from './result-highlight';
import { sortAttributesByHighlightQuery } from './sort-attributes-by-highlight-query';
import { useGlobalSearchContext, useGlobalSearchDescendant } from './use-global-search-state';

interface SearchResultContentProps {
  icon: BussinessObjectIconDefinition;
  title: string;
  link: string;
  score: number;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  necessaryPermission?: Permission;
}

export function SearchResult({
  icon,
  children,
  link,
  score,
  title,
  onClick,
  necessaryPermission,
}: SearchResultContentProps) {
  const labelId = useId();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const searchResultRef = useRef<HTMLDivElement>(null);
  const hasPermission = usePermission();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isFocusable(event.target as Element)) {
      return;
    }

    // Propagate click event to link
    linkRef.current?.click();
  };

  const { index, register } = useGlobalSearchDescendant();
  const { highlightedIndex, highlightIndex, phrase } = useGlobalSearchContext();
  const mergedSearchResultRef = useMergeRefs<HTMLDivElement>(register, searchResultRef);
  const highlightQuery = phrase != null ? phrase.split(' ') : '';

  const toggleHighlight = (event: React.MouseEvent) => {
    highlightIndex(
      event.type === 'mouseover' &&
        searchResultRef.current?.contains(event.target as Element) &&
        (linkRef.current?.contains(event.target as Element) || !isFocusable(event.target as Element))
        ? index
        : null,
    );
  };

  const isHighlighted = index === highlightedIndex;
  const content = (
    <Heading fontSize="md" size="md" lineHeight={6} id={labelId}>
      <HStack as="span">
        <span>{renderBusinessObjectIcon(icon, { fixedWidth: true })}</span>
        <span>
          <ResultHighlight query={highlightQuery}>{title}</ResultHighlight>
        </span>
      </HStack>
    </Heading>
  );

  return (
    <Box
      role="option"
      sx={{
        w: 'full',
        px: 3,
        py: 2,
        borderRadius: 'md',
        cursor: 'pointer',
        transition: 'background-color 0.15s ease-in-out',
        '&.is-highlighted': {
          bgColor: 'layer.02',
        },
      }}
      data-score={score}
      onClick={handleClick}
      aria-selected={isHighlighted}
      aria-labelledby={labelId}
      className={isHighlighted ? 'is-highlighted' : undefined}
      onMouseOver={toggleHighlight}
      onMouseOut={toggleHighlight}
      ref={mergedSearchResultRef}
    >
      {necessaryPermission == null || hasPermission(necessaryPermission) ? (
        <Button variant="link" as={RouterLink} to={link} onClick={onClick} ref={linkRef} whiteSpace="initial">
          {content}
        </Button>
      ) : (
        content
      )}
      <Stack ml={7} mt={0.5}>
        {children}
      </Stack>
    </Box>
  );
}

interface SearchResultAttributesProps {
  attributes: {
    label?: string;
    element: string | undefined | null;
  }[];
}

export function SearchResultAttributes({ attributes }: SearchResultAttributesProps) {
  const definedAttributes = attributes.filter(
    ({ element }) => element !== '' && element != null && !(isArray(element) && isEmpty(element)),
  );

  const { phrase } = useGlobalSearchContext();
  const highlightQuery = phrase != null ? phrase.split(' ') : '';
  const sortedAttributes = sortAttributesByHighlightQuery(definedAttributes, highlightQuery);

  return (
    <Text as="span" fontSize="sm" textColor="text.muted" sx={{ hyphens: 'auto' }} noOfLines={3} lineHeight={5}>
      {sortedAttributes.map(({ label, element }, index) => (
        <React.Fragment key={index}>
          {index > 0 && <> &middot; </>}
          <span>
            {label != null && `${label}: `}
            {element != null ? <ResultHighlight query={highlightQuery}>{element}</ResultHighlight> : undefined}
          </span>
        </React.Fragment>
      ))}
    </Text>
  );
}

export interface SearchResultProps {
  result: SearchResultDto;
  onClick: () => void;
}
