import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { FormatDto, FormatListItemDto } from '../../../api';
import formatApi from '../../../data-access/format-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import FormatDeleteDialog from './format-delete-dialog';

export interface FormatDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  format: FormatListItemDto | FormatDto;
  isDeletable: boolean;
  onSuccess?(): void;
}

const FormatDeleteButton = React.forwardRef<HTMLButtonElement, FormatDeleteButtonProps>(
  ({ children, format, isDeletable, onSuccess, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('format');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

    const showDeleteSuccessToast = useToast({
      id: 'format-editor-success-toast',
      status: 'success',
    });

    const showDeleteFailedToast = useToast({
      id: 'format-editor-failed-toast',
      status: 'error',
    });

    const deleteFormat = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      invariant(format.id != null, 'Missing format id');

      const checked = await openDeleteDialog();
      if (checked) {
        try {
          await formatApi.deleteFormat(
            {
              id: format.id,
            },
            { allowedErrorCodes: [409] } as RequestInit,
          );

          showDeleteSuccessToast({
            title: t('lister.toast.delete_success.title'),
            description: t('lister.toast.delete_success.description', {
              name: format.name,
            }),
          });
          onSuccess?.();
        } catch (e) {
          showDeleteFailedToast({
            title: t('lister.toast.delete_failed.title'),
            description: t('lister.toast.delete_failed.description', {
              name: format.name,
            }),
          });
        }
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deleteFormat}
          disableReason={<Text>{t('action.deleteFailedReason')}</Text>}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <FormatDeleteDialog format={format} isOpen={deleteDialogIsOpen} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default FormatDeleteButton;
