import { Button, ButtonGroup, Menu, MenuDivider, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useHref, useLocation, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { LockDto } from '../../../../api';
import { BurgerMenuButton } from '../../../../ui/burger-menu-button/burger-menu-button';
import Page, { PageHeader } from '../../../../ui/page';
import { createRedirectToParam } from '../../../../util/use-redirect-to/use-redirect-to';
import { useLock } from '../../../lock/use-lock';
import useStaffAccount from '../../../staff-account/use-account/use-staff-account';
import DeletePlacementTemplateMenuItem from '../delete-placement-template-button/delete-placement-template-menu-item';
import usePlacementTemplate from '../placement-template-viewer/use-placement-template';

export default function PlacementTemplatePage() {
  const { locationId, placementTemplateId } = useParams<{ locationId: string; placementTemplateId: string }>();
  invariant(locationId != null && placementTemplateId != null, 'Missing location or placement template id');
  const { placementTemplate } = usePlacementTemplate(locationId, placementTemplateId);

  const lock = useLock(placementTemplateId);
  const { t } = useTranslation('placement_template');
  const { id: userId } = useStaffAccount();

  const location = useLocation();
  const href = useHref(location);

  const navigate = useNavigate();

  const canDelete = placementTemplate.permissions?.canDelete;
  const canEdit = placementTemplate.permissions?.canEdit;
  const showMenu = canDelete || canEdit;

  const createCopyLink = href.replace(
    placementTemplateId,
    `new?copyOf=${placementTemplateId}&${createRedirectToParam(href)}`,
  );

  function navigateToParent() {
    navigate(-1);
  }

  return (
    <Page>
      <Stack spacing={0} height="100vh">
        <PageHeader
          title={placementTemplate.name}
          actionHint={<PlacementTemplateLockHint lock={lock} userId={userId} />}
          actions={
            <ButtonGroup>
              {showMenu && (
                <Menu>
                  <BurgerMenuButton />
                  <MenuList>
                    {canEdit && (
                      <MenuItem as={RouterLink} to={createCopyLink} icon={<FontAwesomeIcon icon={faCopy} />}>
                        {t('action.create_from_copy')}
                      </MenuItem>
                    )}
                    {canEdit && canDelete && <MenuDivider />}
                    {canDelete && (
                      <DeletePlacementTemplateMenuItem
                        templateId={placementTemplateId}
                        templateName={placementTemplate.name}
                        locationId={locationId}
                        onSuccess={navigateToParent}
                      />
                    )}
                  </MenuList>
                </Menu>
              )}

              {placementTemplate.permissions?.canEdit && (
                <PlacementTemplateEditButton isDisabled={lock != null && lock.userReference.id !== userId} />
              )}
            </ButtonGroup>
          }
        />
        <Outlet />
      </Stack>
    </Page>
  );
}

interface PlacementTemplateLockHintProps {
  lock?: LockDto | null;
  userId: string;
}

function PlacementTemplateLockHint({ lock, userId }: PlacementTemplateLockHintProps) {
  const { t } = useTranslation(['common', 'placement_template']);
  const lockedBySomeone = lock != null && lock.userReference.id !== userId;

  return (
    <>
      {lock != null && (
        <>
          <Text fontSize="sm" textAlign="right" color={lockedBySomeone ? 'text.error' : undefined}>
            {lockedBySomeone
              ? t('placement_template:placement_template_locked', {
                  name: lock.userReference.displayName,
                })
              : t('placement_template:placement_template_locked_by_me')}
          </Text>
          <Text fontSize="sm" textAlign="right" color={lockedBySomeone ? 'text.error' : undefined}>
            {lockedBySomeone
              ? t('placement_template:placement_template_locked_until', {
                  time: t('common:format.date_time', { dateTime: lock.expiryDate }),
                })
              : t('placement_template:placement_template_locked_by_me_until', {
                  time: t('common:format.date_time', { dateTime: lock.expiryDate }),
                })}
          </Text>
        </>
      )}
    </>
  );
}

function PlacementTemplateEditButton({ isDisabled }: { isDisabled: boolean }) {
  const { t } = useTranslation('placement_template');

  return isDisabled ? (
    <Button variant="primary" isDisabled>
      {t('action.edit')}
    </Button>
  ) : (
    <Button as={RouterLink} to="edit" variant="primary">
      {t('action.edit')}
    </Button>
  );
}
