/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Metadata of an uploaded file.
 * @export
 * @interface FileMetadataDto
 */
export interface FileMetadataDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FileMetadataDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FileMetadataDto
     */
    version?: VersionDto;
    /**
     * The context name of the file
     * @type {string}
     * @memberof FileMetadataDto
     */
    namespace: string;
    /**
     * download url of the file
     * @type {string}
     * @memberof FileMetadataDto
     */
    url: string;
    /**
     * file size in bytes
     * @type {number}
     * @memberof FileMetadataDto
     */
    size: number;
    /**
     * file name
     * @type {string}
     * @memberof FileMetadataDto
     */
    originalFileName?: string;
    /**
     * The detected MIME-Type of the file
     * @type {string}
     * @memberof FileMetadataDto
     */
    mimeType: string;
    /**
     * height of the photo
     * @type {number}
     * @memberof FileMetadataDto
     */
    height?: number;
    /**
     * width of the photo
     * @type {number}
     * @memberof FileMetadataDto
     */
    width?: number;
}

/**
 * Check if a given object implements the FileMetadataDto interface.
 */
export function instanceOfFileMetadataDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('namespace' in value)) return false;
    if (!('url' in value)) return false;
    if (!('size' in value)) return false;
    if (!('mimeType' in value)) return false;
    return true;
}

export function FileMetadataDtoFromJSON(json: any): FileMetadataDto {
    return FileMetadataDtoFromJSONTyped(json, false);
}

export function FileMetadataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileMetadataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'namespace': json['namespace'],
        'url': json['url'],
        'size': json['size'],
        'originalFileName': json['originalFileName'] == null ? undefined : json['originalFileName'],
        'mimeType': json['mimeType'],
        'height': json['height'] == null ? undefined : json['height'],
        'width': json['width'] == null ? undefined : json['width'],
    };
}

export function FileMetadataDtoToJSON(value?: FileMetadataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'namespace': value['namespace'],
        'url': value['url'],
        'size': value['size'],
        'originalFileName': value['originalFileName'],
        'mimeType': value['mimeType'],
        'height': value['height'],
        'width': value['width'],
    };
}

