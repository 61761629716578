import { Button, Flex, Spacer, Tag } from '@chakra-ui/react';
import React, { KeyboardEvent, ReactNode } from 'react';
import { BussinessObjectIconDefinition, renderBusinessObjectIcon } from '../../ui/icons/business-objects';
import { useSearchFiltersContext, useSearchFiltersDescendant } from './use-search-filters-state';

export interface SearchFilterButtonProps {
  icon: BussinessObjectIconDefinition;
  onClick: () => void;
  children: ReactNode;
  isActive: boolean;
  results: number;
}

export default function SearchFilterButton({ icon, onClick, children, isActive, results }: SearchFilterButtonProps) {
  const { index, register } = useSearchFiltersDescendant();
  const {
    onFilterButtonFocus,
    onFilterButtonBlur,
    focusNextFilterButton,
    focusPrevFilterButton,
    focusedFilterButtonIndex,
  } = useSearchFiltersContext();

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const actions: Record<string, () => void> = {
      ArrowDown: focusNextFilterButton,
      ArrowUp: focusPrevFilterButton,
    };

    const action = actions[event.key];

    if (action != null) {
      event.preventDefault();
      event.stopPropagation();

      action();
    }
  };

  return (
    <Button
      role="tab"
      justifyContent="flex"
      size="sm"
      leftIcon={renderBusinessObjectIcon(icon, { fixedWidth: true })}
      onClick={onClick}
      onFocus={onFilterButtonFocus}
      onBlur={onFilterButtonBlur}
      onKeyDown={handleKeyDown}
      variant="unstyled"
      className={isActive ? 'is-active' : results === 0 ? 'no-results' : undefined}
      aria-selected={isActive ? 'true' : undefined}
      noOfLines={1}
      tabIndex={focusedFilterButtonIndex == null ? (isActive ? 0 : -1) : focusedFilterButtonIndex === index ? 0 : -1}
      sx={{
        display: 'inline-flex',
        textAlign: 'left',
        height: 'auto',
        py: 1,
        pl: 2,
        pr: 4,
        ml: -2,
        borderLeftRadius: 'md',
        borderRightRadius: '0',
        fontWeight: 'normal',
        _hover: {
          bg: 'background.highlight',
        },
        '&.is-active': {
          bg: 'background.hero',
          color: 'text.interactive',
          fontWeight: 'medium',
        },
        '&.no-results': {
          opacity: '50%',
        },
      }}
      ref={register}
    >
      <Flex width="full">
        {children}
        <Spacer />
        <Tag
          colorScheme={isActive ? 'teal' : 'gray'}
          variant={isActive ? 'solid' : undefined}
          size="sm"
          px={1}
          minWidth="auto"
        >
          {results}
        </Tag>
      </Flex>
    </Button>
  );
}
