import { Box, Button, ButtonGroup, Menu, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useParams } from 'react-router-dom';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { DisableableRouterLink } from '../../../ui/disableable-button/disableable-router-link';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import { getStandId } from '../common/get-stand-id';
import { fetchStand } from '../stand-queries';
import CopyStandButton from './copy-stand-button';

export interface StandTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const STAND_TAB_ITEM = new PluginToken<StandTabItem>('StandTabItem');

export const Component = StandPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchStand.preload({ id: getStandId(params) });
};

export default function StandPageRoute() {
  const { t } = useTranslation(['common', 'stand']);
  const stand = useFetcher(fetchStand, { id: getStandId(useParams()) });
  const tabs = <StandPageTabs />;

  return (
    <Page>
      <PageHeader
        title={stand.name}
        tabs={tabs}
        actions={
          <ButtonGroup>
            <Box>
              <Menu>
                <BurgerMenuButton />
                <Portal>
                  <MenuList>
                    <MenuItem as={CopyStandButton} icon={<FontAwesomeIcon icon={faCopy} fixedWidth />}>
                      {t('stand:action.copy')}
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
            <Button as={DisableableRouterLink} to="edit" variant="primary">
              {t('stand:action.edit')}
            </Button>
          </ButtonGroup>
        }
      />
      <Outlet />
    </Page>
  );
}

function StandPageTabs() {
  const { t } = useTranslation('common');
  const tabItems = usePlugins(STAND_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('history.label')}
      </PageTab>
    </>
  );
}
