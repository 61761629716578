import React from 'react';
import { sectionIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import SectionSearchResult from './section-search-result';

export default function SectionPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'SECTION', Component: SectionSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'SECTION',
    icon: sectionIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.SECTION" />,
  });
}
