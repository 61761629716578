import { useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import RichText from '../../rich-text/rich-text';
import { RichTextOptions } from '../../rich-text/rich-text-options';

export interface RichTextControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  labelId: string;
  options: RichTextOptions[];
}

function RichTextControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, labelId, options }: RichTextControlProps<TFieldValues, TName>, ref: React.ForwardedRef<HTMLTextAreaElement>) {
  const { field } = useController({ name });
  const mergedRef = useMergeRefs(field.ref, ref);

  return <RichText {...field} labelId={labelId} ref={mergedRef} defaultOptions={options} />;
}

export default React.forwardRef(RichTextControl);
