import React from 'react';
import { FieldValues } from 'react-hook-form';
import { RichTextOptions } from '../../rich-text/rich-text-options';
import FormControl, { FormControlProps } from '../form-control';
import FormControlContextConsumer from '../form-control-context-consumer';
import RichTextControl from './rich-text-control';

interface RichTextFormControlProps<TFieldValues extends FieldValues>
  extends Omit<FormControlProps<TFieldValues>, 'children'> {
  options: RichTextOptions[];
}

export default function RichTextFormControl<TFieldValues extends FieldValues>({
  name,
  options,
  ...props
}: RichTextFormControlProps<TFieldValues>) {
  return (
    <FormControl name={name} {...props}>
      <FormControlContextConsumer>
        {({ labelId }) => <RichTextControl name={name} labelId={labelId} options={options} />}
      </FormControlContextConsumer>
    </FormControl>
  );
}
