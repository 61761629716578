import { Button, ButtonGroup, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, LoaderFunctionArgs, Outlet, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import formatApi from '../../../data-access/format-api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import Page, { PageHeader, PageTab } from '../../../ui/page';
import usePlugins, { PluginToken } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import FormatDeleteButton from '../format-delete/format-delete-button';
import { fetchFormat } from '../format-queries';

export interface FormatTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const FORMAT_TAB_ITEM = new PluginToken<FormatTabItem>('FormatTabItem');

export const Component = FormatPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchFormat.mutate({ id: String(params.formatId) });
};

export function FormatPageRoute() {
  const { t } = useTranslation(['common', 'format']);
  const { formatId } = useParams<{ formatId: string }>();
  const navigate = useNavigate();

  const [isDeletable, setDeletable] = React.useState<boolean>(false);

  const checkStatus = async () => {
    setDeletable(false);
    invariant(format.id != null, 'Missing formatId');
    const statusCheck = await formatApi.checkFormatStatus({ formatId: format.id });
    setDeletable(statusCheck.isDeletable);
  };

  invariant(formatId, 'Empty formatId');
  const format = useFetcher(fetchFormat, { id: formatId });
  const tabs = <FormatPageTabs />;

  return (
    <Page>
      <PageHeader
        title={format.name}
        tabs={tabs}
        actions={
          <ButtonGroup>
            <Menu onOpen={checkStatus}>
              <BurgerMenuButton />
              <MenuList>
                <MenuItem
                  color="text.error"
                  as={FormatDeleteButton}
                  format={format}
                  isDeletable={isDeletable}
                  onSuccess={() => navigate(`/formats`)}
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                >
                  {t('format:action.delete')}
                </MenuItem>
              </MenuList>
            </Menu>
            <Button as={RouterLink} to="edit" variant="primary">
              {t('format:action.edit')}
            </Button>
          </ButtonGroup>
        }
      />
      <Outlet />
    </Page>
  );
}

function FormatPageTabs() {
  const { t } = useTranslation(['common', 'format']);

  const tabItems = usePlugins(FORMAT_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('common:history.label')}
      </PageTab>
    </>
  );
}
