import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const sectionMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.section" />,
    path: '/sections',
    hasAccess: (permissions) => permissions.includes('SECTION:STANDARD-READ'),
  },
];

export default sectionMenuItems;
