import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { mailingIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function MailingSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'MAILING', `${result.resultType} is no instance of MailingSearchResult`);

  const mailing = result.result;
  const { t } = useTranslation(['mailing', 'mailing_template', 'event']);

  return (
    <SearchResult
      icon={mailingIcon}
      title={mailing.name}
      link={`/mailings/${mailing.id}`}
      score={result.score}
      onClick={onClick}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('event:event'),
            element: mailing.connectedEntity?.title,
          },
          {
            label: t('mailing:status.label'),
            element: t(`mailing:statusOptions.${mailing.status}`),
          },
          {
            label: t('mailing:sender.label'),
            element: mailing.sender.title,
          },
          {
            label: t('mailing:sender.label'),
            element: mailing.sender.email,
          },
          {
            label: t('mailing:sender.label'),
            element: t(`mailing_template:senderTypeOptions.${mailing.sender.senderType}`),
          },
          {
            label: t('mailing:title.label_de'),
            element: mailing.title,
          },
          {
            label: t('mailing:title.label_en'),
            element: mailing.titleEnglish,
          },
          {
            label: t('mailing:subject.label_de'),
            element: mailing.subject,
          },
          {
            label: t('mailing:subject.label_en'),
            element: mailing.subjectEnglish,
          },
          {
            label: t('mailing:urgency.label'),
            element: t(`mailing:urgencyLabels.${mailing.urgency}`),
          },
        ]}
      />
    </SearchResult>
  );
}
