import { tableAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig, PartsStyleObject } from '@chakra-ui/theme-tools';

const defaultProps = {
  colorScheme: 'gray',
};

const variantSimple: PartsStyleObject<typeof tableAnatomy> = {
  table: {
    bg: 'layer.01',
    borderColor: 'border.01',
  },
  tr: {
    '&:last-of-type': {
      td: {
        border: 'none',
      },
    },
  },
  th: {
    borderColor: 'border.01',
  },
  td: {
    borderColor: 'border.01',
  },
  thead: {
    bgColor: 'layer.02',
  },
};

const variants = {
  simple: variantSimple,
};

const sizes: Record<string, PartsStyleObject<typeof tableAnatomy>> = {
  sm: {
    th: {
      h: 8,
    },
    td: {
      py: 2,
    },
  },
  md: {
    th: {
      py: 2,
      h: 10,
    },
    td: {
      py: 2,
    },
  },
};

const Table: MultiStyleConfig<typeof tableAnatomy> = {
  parts: tableAnatomy.keys,
  defaultProps,
  variants,
  sizes,
};

export default Table;
