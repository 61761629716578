import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  MenuItem as ChakraMenuItem,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  StackItem,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AccordionIcon from '../../../ui/accordion/accordion-icon';
import usePermission from '../../permission/use-permission';
import useStaffAccount from '../../staff-account/use-account/use-staff-account';
import AppNavigationButton from './app-navigation-button';
import { hasAccess } from './app-navigation-menu';
import AppNavigationSubMenu from './app-navigation-sub-menu';
import isActiveSubMenu from './is-active-sub-menu';
import MenuItem from './menu-item';

interface AppNavigationMenuItemProps {
  item: MenuItem;
  isCollapsed?: boolean;
}

export function AppNavigationMenuItem({ item, isCollapsed }: AppNavigationMenuItemProps) {
  const { pathname } = useLocation();
  const { permissions } = useStaffAccount();
  const hasPermission = usePermission();
  const visibleMenuItems = item.menuItems.filter((value) => hasAccess(value, permissions, hasPermission));

  if (isCollapsed) {
    const isActive = visibleMenuItems.some((item) => isActiveSubMenu(item, pathname));

    return (
      <Menu placement="right" gutter={4}>
        <MenuButton as={AppNavigationButton} className={isActive ? 'active' : undefined} icon={item.icon} isCollapsed>
          {item.name}
        </MenuButton>
        <Portal>
          <MenuList zIndex="sticky">
            {visibleMenuItems.map((item, index) => (
              <ChakraMenuItem key={index} as={RouterLink} to={item.path}>
                {item.name}
              </ChakraMenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <AccordionItem as={StackItem} border={0}>
      <AccordionButton px={6}>
        <Box as={FontAwesomeIcon} icon={item.icon} fixedWidth mr={3} color="teal.400" />
        <Text flex="1" textAlign="left" fontWeight="semibold">
          {item.name}
        </Text>
        <AccordionIcon color="whiteAlpha.500" />
      </AccordionButton>
      <AccordionPanel px={0} pt={0} pb={2}>
        <AppNavigationSubMenu items={visibleMenuItems} />
      </AccordionPanel>
    </AccordionItem>
  );
}
