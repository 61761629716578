import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const tagMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.tag" />,
    path: '/tags',
  },
];

export default tagMenuItems;
