/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
  VisaTemplateDto,
  VisaTemplatePageDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    VisaTemplateDtoFromJSON,
    VisaTemplateDtoToJSON,
    VisaTemplatePageDtoFromJSON,
    VisaTemplatePageDtoToJSON,
} from '../models/index';

export interface CreateVisaTemplateRequest {
    visaTemplateDto: VisaTemplateDto;
}

export interface DeleteVisaTemplateRequest {
    id: string;
}

export interface FetchVisaTemplateRequest {
    id: string;
}

export interface FetchVisaTemplateHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchVisaTemplatesRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SetVisaTemplateAsDefaultTemplateRequest {
    id: string;
}

export interface UpdateVisaTemplateRequest {
    id: string;
    visaTemplateDto: VisaTemplateDto;
}

export interface VisaTemplateExistForCountryRequest {
    visaTemplateId: string;
    countryIds: Array<string>;
}

/**
 * 
 */
export class VisaTemplateApi extends runtime.BaseAPI {

    /**
     * Create a new visa template
     */
    async createVisaTemplateRaw(requestParameters: CreateVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['visaTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'visaTemplateDto',
                'Required parameter "visaTemplateDto" was null or undefined when calling createVisaTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisaTemplateDtoToJSON(requestParameters['visaTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new visa template
     */
    async createVisaTemplate(requestParameters: CreateVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createVisaTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the visa template.
     */
    async deleteVisaTemplateRaw(requestParameters: DeleteVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteVisaTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the visa template.
     */
    async deleteVisaTemplate(requestParameters: DeleteVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVisaTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get the visa template business object.
     */
    async fetchVisaTemplateRaw(requestParameters: FetchVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaTemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVisaTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Get the visa template business object.
     */
    async fetchVisaTemplate(requestParameters: FetchVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaTemplateDto> {
        const response = await this.fetchVisaTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the visa template.
     */
    async fetchVisaTemplateHistoryRaw(requestParameters: FetchVisaTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVisaTemplateHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the visa template.
     */
    async fetchVisaTemplateHistory(requestParameters: FetchVisaTemplateHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchVisaTemplateHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for visa templates.
     */
    async searchVisaTemplatesRaw(requestParameters: SearchVisaTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaTemplatePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaTemplatePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for visa templates.
     */
    async searchVisaTemplates(requestParameters: SearchVisaTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaTemplatePageDto> {
        const response = await this.searchVisaTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make template the default template
     */
    async setVisaTemplateAsDefaultTemplateRaw(requestParameters: SetVisaTemplateAsDefaultTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling setVisaTemplateAsDefaultTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/{id}/set-as-default-template`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make template the default template
     */
    async setVisaTemplateAsDefaultTemplate(requestParameters: SetVisaTemplateAsDefaultTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setVisaTemplateAsDefaultTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch the template for creating a new visa template.
     */
    async templateVisaTemplateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VisaTemplateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisaTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new visa template.
     */
    async templateVisaTemplate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VisaTemplateDto> {
        const response = await this.templateVisaTemplateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing visa template.
     */
    async updateVisaTemplateRaw(requestParameters: UpdateVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateVisaTemplate().'
            );
        }

        if (requestParameters['visaTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'visaTemplateDto',
                'Required parameter "visaTemplateDto" was null or undefined when calling updateVisaTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VisaTemplateDtoToJSON(requestParameters['visaTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing visa template.
     */
    async updateVisaTemplate(requestParameters: UpdateVisaTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVisaTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get whether a visa template exists for a country, excluding the current one.
     */
    async visaTemplateExistForCountryRaw(requestParameters: VisaTemplateExistForCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['visaTemplateId'] == null) {
            throw new runtime.RequiredError(
                'visaTemplateId',
                'Required parameter "visaTemplateId" was null or undefined when calling visaTemplateExistForCountry().'
            );
        }

        if (requestParameters['countryIds'] == null) {
            throw new runtime.RequiredError(
                'countryIds',
                'Required parameter "countryIds" was null or undefined when calling visaTemplateExistForCountry().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['visaTemplateId'] != null) {
            queryParameters['visaTemplateId'] = requestParameters['visaTemplateId'];
        }

        if (requestParameters['countryIds'] != null) {
            queryParameters['countryIds'] = requestParameters['countryIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/visa-templates/visa-template-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether a visa template exists for a country, excluding the current one.
     */
    async visaTemplateExistForCountry(requestParameters: VisaTemplateExistForCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.visaTemplateExistForCountryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
