/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { MailingModuleContentDto } from './MailingModuleContentDto';
import {
    MailingModuleContentDtoFromJSON,
    MailingModuleContentDtoFromJSONTyped,
    MailingModuleContentDtoToJSON,
} from './MailingModuleContentDto';
import type { MailingSenderDto } from './MailingSenderDto';
import {
    MailingSenderDtoFromJSON,
    MailingSenderDtoFromJSONTyped,
    MailingSenderDtoToJSON,
} from './MailingSenderDto';
import type { MailingTemplateDataDto } from './MailingTemplateDataDto';
import {
    MailingTemplateDataDtoFromJSON,
    MailingTemplateDataDtoFromJSONTyped,
    MailingTemplateDataDtoToJSON,
} from './MailingTemplateDataDto';

/**
 * A mailing copy for the Berlinale festival.
 * @export
 * @interface MailingCopyDto
 */
export interface MailingCopyDto {
    /**
     * 
     * @type {string}
     * @memberof MailingCopyDto
     */
    name: string;
    /**
     * 
     * @type {MailingSenderDto}
     * @memberof MailingCopyDto
     */
    sender: MailingSenderDto;
    /**
     * 
     * @type {string}
     * @memberof MailingCopyDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MailingCopyDto
     */
    titleEnglish?: string;
    /**
     * Subject of the email
     * @type {string}
     * @memberof MailingCopyDto
     */
    subject?: string;
    /**
     * Subject of the email in english
     * @type {string}
     * @memberof MailingCopyDto
     */
    subjectEnglish?: string;
    /**
     * 
     * @type {Array<FileMetadataDto>}
     * @memberof MailingCopyDto
     */
    attachments?: Array<FileMetadataDto>;
    /**
     * 
     * @type {string}
     * @memberof MailingCopyDto
     */
    urgency: MailingCopyDtoUrgencyEnum;
    /**
     * 
     * @type {Array<MailingModuleContentDto>}
     * @memberof MailingCopyDto
     */
    modules: Array<MailingModuleContentDto>;
    /**
     * 
     * @type {MailingTemplateDataDto}
     * @memberof MailingCopyDto
     */
    template: MailingTemplateDataDto;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingCopyDto
     */
    connectionId?: string;
}


/**
 * @export
 */
export const MailingCopyDtoUrgencyEnum = {
    URGENT: 'URGENT',
    REGULAR: 'REGULAR'
} as const;
export type MailingCopyDtoUrgencyEnum = typeof MailingCopyDtoUrgencyEnum[keyof typeof MailingCopyDtoUrgencyEnum];


/**
 * Check if a given object implements the MailingCopyDto interface.
 */
export function instanceOfMailingCopyDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('sender' in value)) return false;
    if (!('urgency' in value)) return false;
    if (!('modules' in value)) return false;
    if (!('template' in value)) return false;
    return true;
}

export function MailingCopyDtoFromJSON(json: any): MailingCopyDto {
    return MailingCopyDtoFromJSONTyped(json, false);
}

export function MailingCopyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingCopyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sender': MailingSenderDtoFromJSON(json['sender']),
        'title': json['title'] == null ? undefined : json['title'],
        'titleEnglish': json['titleEnglish'] == null ? undefined : json['titleEnglish'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'subjectEnglish': json['subjectEnglish'] == null ? undefined : json['subjectEnglish'],
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(FileMetadataDtoFromJSON)),
        'urgency': json['urgency'],
        'modules': ((json['modules'] as Array<any>).map(MailingModuleContentDtoFromJSON)),
        'template': MailingTemplateDataDtoFromJSON(json['template']),
        'connectionId': json['connectionId'] == null ? undefined : json['connectionId'],
    };
}

export function MailingCopyDtoToJSON(value?: MailingCopyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'sender': MailingSenderDtoToJSON(value['sender']),
        'title': value['title'],
        'titleEnglish': value['titleEnglish'],
        'subject': value['subject'],
        'subjectEnglish': value['subjectEnglish'],
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(FileMetadataDtoToJSON)),
        'urgency': value['urgency'],
        'modules': ((value['modules'] as Array<any>).map(MailingModuleContentDtoToJSON)),
        'template': MailingTemplateDataDtoToJSON(value['template']),
        'connectionId': value['connectionId'],
    };
}

