import { forwardRef, HTMLChakraProps, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonDto, VisaCreateDto, VisaCreationCheckDto, VisaStatusDto } from '../../../api';
import visaApi from '../../../data-access/visa-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import VisaEditDateDialog from './visa-edit-date-dialog';

interface VisaCreateButtonProps extends HTMLChakraProps<'button'> {
  person: PersonDto;
  visaCreationCheckStatus?: VisaCreationCheckDto;

  onSuccess?(): void;
}

function VisaCreateButton(
  {
    children,
    person,
    visaCreationCheckStatus = {
      participationDataAvailableWithinActiveEdition: false,
      validVisaAlreadyAvailable: false,
      nationalityAvailable: false,
      visaRequired: false,
    },
    onSuccess,
    ...props
  }: VisaCreateButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('visa');

  const showSuccessToast = useToast({
    id: 'visa-create-success-toast',
    status: 'success',
  });

  const showWarningToast = useToast({
    id: 'visa-create-warning-toast',
    status: 'warning',
  });

  const createVisa = async (visaCreate: VisaCreateDto) => {
    const visas = await visaApi.createVisa({
      visaCreateDto: visaCreate,
    });

    if (visas.some((visa) => visa.status == VisaStatusDto.VALID)) {
      showSuccessToast({
        title: t('toast.create.header'),
        description: t('toast.create.message', { applicant: person.firstName + ' ' + person.surname }),
      });
    } else {
      showWarningToast({
        title: t('toast.warning.header'),
        description: t('toast.warning.message', { applicant: person.firstName + ' ' + person.surname }),
      });
    }

    onClose();
    onSuccess?.();
  };

  let disableReason = t('disableReasonMessages.duplicateValidVisa');
  if (!visaCreationCheckStatus.nationalityAvailable) {
    disableReason = t('disableReasonMessages.missingNationality');
  } else if (!visaCreationCheckStatus.visaRequired) {
    disableReason = t('disableReasonMessages.noVisaRequired');
  } else if (!visaCreationCheckStatus.participationDataAvailableWithinActiveEdition) {
    disableReason = t('disableReasonMessages.missingParticipationData');
  }

  return (
    <>
      <DisableableButton
        ref={ref}
        {...props}
        onClick={onOpen}
        disableReason={disableReason}
        isDisabled={
          !visaCreationCheckStatus.nationalityAvailable ||
          !visaCreationCheckStatus.visaRequired ||
          !visaCreationCheckStatus.participationDataAvailableWithinActiveEdition ||
          visaCreationCheckStatus.validVisaAlreadyAvailable
        }
      >
        {children}
      </DisableableButton>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <VisaEditDateDialog person={person} handleValid={createVisa} onClose={onClose} />
      </Modal>
    </>
  );
}

export default forwardRef<VisaCreateButtonProps, 'button'>(VisaCreateButton);
