import { Badge, Box, Button, ButtonGroup, HStack, Menu, MenuList, Portal, Spinner, Tag } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CompanyDtoStatusEnum } from '../../../api';
import { BurgerMenuButton } from '../../../ui/burger-menu-button/burger-menu-button';
import { formatIcon, tagIcon } from '../../../ui/icons/business-objects';
import { PageHeader, PageTab } from '../../../ui/page';
import Page from '../../../ui/page/page';
import { createBuilder } from '../../../util/builder/builder';
import usePlugins, { PluginToken, useBuilderPlugins } from '../../../util/plugin/use-plugins';
import useFetcher from '../../../util/swr/use-fetcher';
import usePermission from '../../staff-account/use-permission/use-permission';
import { COMPANY_ACTION_EXTENSION } from '../company-action-extension';
import { fetchCompany, fetchCompanyComments } from '../company-queries';
import CompanyPageTab, { COMPANY_PAGE_TAB } from './company-page-tab';

export interface CompanyTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const COMPANY_TAB_ITEM = new PluginToken<CompanyTabItem>('CompanyTabItem');

export default function CompanyPage() {
  const { t } = useTranslation('company');
  const actionExtensions = usePlugins(COMPANY_ACTION_EXTENSION);

  const { companyId } = useParams<{ companyId: string }>();
  const canEdit = usePermission('COMPANY:STANDARD-EDIT');

  invariant(companyId, 'Empty companyId');
  const company = useFetcher(fetchCompany, { id: companyId }, { suspense: true });

  return (
    <Page>
      <PageHeader
        title={
          <>
            {company.name}
            {company.status === CompanyDtoStatusEnum.HISTORIC && <Tag m={2}>{t('statusLabel.HISTORIC')}</Tag>}
          </>
        }
        actions={
          <HStack>
            <ButtonGroup>
              <Box>
                <Menu>
                  <BurgerMenuButton />
                  <Portal>
                    <MenuList>{actionExtensions.map((extension, index) => extension.item(company, index))}</MenuList>
                  </Portal>
                </Menu>
              </Box>
            </ButtonGroup>
            {canEdit && (
              <Button as={RouterLink} to="edit" variant="primary">
                {t('action.edit')}
              </Button>
            )}
          </HStack>
        }
        tabs={<CompanyPageTabs />}
      />
      <Outlet />
    </Page>
  );
}

function CompanyPageTabs() {
  const { companyId } = useParams<{ companyId: string }>();
  invariant(companyId, 'Empty companyId');
  const { t } = useTranslation(['common', 'format', 'tag']);
  const comments = useFetcher(
    fetchCompanyComments,
    {
      id: companyId,
      pageable: { size: 1 },
    },
    { suspense: false },
  );
  const tabBuilder = useMemo(
    () =>
      createBuilder<CompanyPageTab>([
        { key: 'home', element: <PageTab to="." icon={faHomeAlt} /> },
        {
          key: 'comments',
          element: (
            <PageTab to="./comments">
              {t('common:comments.label')}
              <Badge colorScheme="teal" variant="solid" ml={2} display="inline-flex">
                {comments?.totalElements ?? <Spinner color="white" size="xs" my={1} />}
              </Badge>
            </PageTab>
          ),
        },
        {
          key: 'formats',
          element: (
            <PageTab to="./formats" icon={formatIcon}>
              {t('format:companyConnections.formats')}
            </PageTab>
          ),
        },
        {
          key: 'tags',
          element: (
            <PageTab to="./tags" icon={tagIcon}>
              {t('tag:lister.title')}
            </PageTab>
          ),
        },
        {
          key: 'history',
          element: (
            <PageTab to="./history" icon={faHistory}>
              {t('common:history.label')}
            </PageTab>
          ),
        },
      ]),
    [comments?.totalElements, t],
  );
  const tabs = useBuilderPlugins(COMPANY_PAGE_TAB, tabBuilder);
  return (
    <>
      {tabs.map(({ key, element }) => (
        <Fragment key={key}>{element}</Fragment>
      ))}
    </>
  );
}
