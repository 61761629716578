import React from 'react';
import { accessGroupIcon, personIcon } from '../../../ui/icons/business-objects';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import ACCESS_GROUP_CHILD_ROUTES from '../../access-group/access-group-child-routes';
import {
  ACCESS_GROUP_TAB_ITEM,
  AccessGroupTabItem,
} from '../../access-group/access-group-page/access-group-page-route';
import { ACCESS_GROUP_HISTORY_EXTENSION } from '../../access-group/access-group-relation/access-group-history-extension';
import PERSON_CHILD_ROUTES from '../../person/person-child-routes';
import { PERSON_TAB_ITEM, PersonTabItem } from '../../person/person-page/person-page';
import accessGroupPersonRoutes from './access-group-to-person-viewer/access-group-person-routes';
import { personAccessGroupHistoryExtension } from './person-access-group-history/person-access-group-history';
import personAccessGroupRoutes from './person-to-access-group-viewer/person-access-group-routes';

export const personAccessGroupTabItem: PersonTabItem = {
  order: 2,
  label: <Translate ns="access_group" i18nKey="lister.title" />,
  icon: accessGroupIcon,
  to: './access-groups',
};

export const accessGroupToPersonTabItem: AccessGroupTabItem = {
  order: 2,
  label: <Translate ns="person" i18nKey="lister.title" />,
  icon: personIcon,
  to: './persons',
};

export default function PersonAccessGroupPluginProvider(registry: PluginRegistry) {
  registry.register(PERSON_TAB_ITEM, personAccessGroupTabItem);
  registry.register(PERSON_CHILD_ROUTES, personAccessGroupRoutes);
  registry.register(ACCESS_GROUP_TAB_ITEM, accessGroupToPersonTabItem);
  registry.register(ACCESS_GROUP_CHILD_ROUTES, accessGroupPersonRoutes);
  registry.register(ACCESS_GROUP_HISTORY_EXTENSION, personAccessGroupHistoryExtension);
}
