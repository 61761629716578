import { useTranslation } from 'react-i18next';
import { RoleReferenceDto } from '../../../api';
import { adminRoleIcon, sectionRoleIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface RoleReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  roleReference: RoleReferenceDto;
}

export default function RoleReference({ roleReference, ...props }: RoleReferenceProps) {
  const { t } = useTranslation('permission_role');

  return (
    <Reference icon={roleReference.isAdminRole ? adminRoleIcon : sectionRoleIcon} to={getUrl(roleReference)} {...props}>
      {roleReference.name ?? t('adminRoleName')}
    </Reference>
  );
}

function getUrl(role: RoleReferenceDto) {
  const roleId = role.isAdminRole ? 'admin' : role.id;
  return `/sections/${role.sectionId}/roles/${roleId}`;
}
