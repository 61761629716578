import { Grid, GridItem, Link } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  AccessGroupStatusDto,
  EditionDto,
  LiveTicketingPriorityDto,
  PersonAccessGroupListItemDto,
  PersonDto,
} from '../../../../api';
import accessGroupApi from '../../../../data-access/access-group-api';
import DataTable, {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../ui/data-table';
import DateFilter from '../../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import LastModifiedLabel from '../../../../ui/version/last-modified-label';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import { fetchPersonAccessGroupListItems } from '../person-access-group-queries';

function usePersonAccessGroupListItems(state: DataTableState, personId: string, editionId: string) {
  const requestParams = useRequestParams(state, [{ property: 'modifiedAt', direction: 'DESC' }]);
  return useFetcher(
    fetchPersonAccessGroupListItems,
    {
      ...requestParams,
      id: personId,
      filter: [...requestParams.filter, `edition.id,eq,${editionId}`],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function PersonAccessGroupViewer({ person, edition }: { person: PersonDto; edition: EditionDto }) {
  const { t } = useTranslation(['common', 'access_group', 'relations']);
  const [state, setState] = useDataTableState();
  const page = usePersonAccessGroupListItems(state, person.id!, edition.id!);

  const columns: DataTableColumn<PersonAccessGroupListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<PersonAccessGroupListItemDto>[] = [
      {
        key: 'title',
        sticky: true,
        name: t('access_group:form.title'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '40%',
        },
        isSortable: true,
        renderCell: (item) => (
          <DataTableTruncatedText>
            <Link as={RouterLink} to={`../../../access-groups/${item.id!}`}>
              {item.title}
            </Link>
          </DataTableTruncatedText>
        ),
        filter: (
          <StringFilter
            label={t('access_group:form.title')}
            loadOptions={async (value: string) => {
              const page = await accessGroupApi.searchAccessGroups({
                pageable: { size: 10 },
                filter: [`title,contain,${value}`],
              });

              return page.content.map((accessGroup) => accessGroup.title);
            }}
          />
        ),
      },
      {
        key: 'liveTicketingPriority',
        name: t('access_group:form.liveTicketingPrio'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (accessGroup) => (
          <DataTableTruncatedText>
            {t(`access_group:form.liveTicketingPrioValues.${accessGroup.liveTicketingPriority}`)}
          </DataTableTruncatedText>
        ),
        filter: (
          <EnumFilter
            label={t('access_group:form.liveTicketingPrio')}
            options={[LiveTicketingPriorityDto.ONE, LiveTicketingPriorityDto.TWO, LiveTicketingPriorityDto.THREE]}
            renderOptionLabel={(key) => t(`access_group:form.liveTicketingPrioValues.${key}`)}
            showOperatorOptions={false}
          />
        ),
      },
      {
        key: 'status',
        name: t('access_group:viewer.status'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        renderCell: (accessGroup) => t(`access_group:viewer.statusLabel.${accessGroup.status}`),
        filter: (
          <EnumFilter
            label={t('access_group:viewer.status')}
            options={[AccessGroupStatusDto.ACTIVE, AccessGroupStatusDto.INACTIVE]}
            renderOptionLabel={(key) => t(`access_group:viewer.statusLabel.${key}`)}
            showOperatorOptions={false}
          />
        ),
      },
      {
        key: 'modifiedAt',
        name: t('common:viewer.last_modification'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        renderCell: (item) => <LastModifiedLabel version={item.version} />,
        isSortable: true,
        sortProperty: 'modifiedAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [t]);

  const rowKey = React.useCallback((relation: PersonAccessGroupListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
