/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  MailingAnalyticsDto,
  MailingConnectionDto,
  MailingConnectionViewDto,
  MailingCopyDto,
  MailingCreateDto,
  MailingDto,
  MailingListViewDto,
  MailingPageDto,
  MailingSenderPageDto,
  MailingSendingInformationDto,
  MailingSendingValidationErrorDto,
  MailingUpdateDto,
  PageableDto,
  ResendMailingDataDto,
  SectionEmailAddressDto,
  SenderTypeDto,
  TestMailingDataDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    MailingAnalyticsDtoFromJSON,
    MailingAnalyticsDtoToJSON,
    MailingConnectionDtoFromJSON,
    MailingConnectionDtoToJSON,
    MailingConnectionViewDtoFromJSON,
    MailingConnectionViewDtoToJSON,
    MailingCopyDtoFromJSON,
    MailingCopyDtoToJSON,
    MailingCreateDtoFromJSON,
    MailingCreateDtoToJSON,
    MailingDtoFromJSON,
    MailingDtoToJSON,
    MailingListViewDtoFromJSON,
    MailingListViewDtoToJSON,
    MailingPageDtoFromJSON,
    MailingPageDtoToJSON,
    MailingSenderPageDtoFromJSON,
    MailingSenderPageDtoToJSON,
    MailingSendingInformationDtoFromJSON,
    MailingSendingInformationDtoToJSON,
    MailingSendingValidationErrorDtoFromJSON,
    MailingSendingValidationErrorDtoToJSON,
    MailingUpdateDtoFromJSON,
    MailingUpdateDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    ResendMailingDataDtoFromJSON,
    ResendMailingDataDtoToJSON,
    SectionEmailAddressDtoFromJSON,
    SectionEmailAddressDtoToJSON,
    SenderTypeDtoFromJSON,
    SenderTypeDtoToJSON,
    TestMailingDataDtoFromJSON,
    TestMailingDataDtoToJSON,
} from '../models/index';

export interface CopyMailingRequest {
    id: string;
    mailingCopyDto: MailingCopyDto;
}

export interface CreateMailingRequest {
    templateId: string;
    mailingCreateDto: MailingCreateDto;
}

export interface DeleteMailingRequest {
    id: string;
}

export interface FetchAvailableConnectionsRequest {
    templateId: string;
    editionId: string;
    q?: string;
    pageable?: PageableDto;
}

export interface FetchMailingRequest {
    id: string;
}

export interface FetchMailingAnalyticsRequest {
    id: string;
}

export interface FetchMailingHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchPossibleSendersRequest {
    senderTypeDto: Array<SenderTypeDto>;
    q?: string;
    pageable?: PageableDto;
}

export interface GetApiKeyBySectionRequest {
    id: string;
}

export interface ResendMailingRequest {
    resendMailingDataDto: ResendMailingDataDto;
}

export interface SaveApiKeyRequest {
    id: string;
    body?: string;
}

export interface SearchMailingsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SendMailingRequest {
    id: string;
}

export interface TestMailingRequest {
    testMailingDataDto: TestMailingDataDto;
}

export interface UpdateMailingRequest {
    id: string;
    mailingUpdateDto: MailingUpdateDto;
}

export interface ValidateApiKeyRequest {
    body: string;
}

/**
 * 
 */
export class MailingApi extends runtime.BaseAPI {

    /**
     * Create a copy of a mailing.
     */
    async copyMailingRaw(requestParameters: CopyMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling copyMailing().'
            );
        }

        if (requestParameters['mailingCopyDto'] == null) {
            throw new runtime.RequiredError(
                'mailingCopyDto',
                'Required parameter "mailingCopyDto" was null or undefined when calling copyMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/copies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingCopyDtoToJSON(requestParameters['mailingCopyDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDtoFromJSON(jsonValue));
    }

    /**
     * Create a copy of a mailing.
     */
    async copyMailing(requestParameters: CopyMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDto> {
        const response = await this.copyMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new mailing.
     */
    async createMailingRaw(requestParameters: CreateMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDto>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling createMailing().'
            );
        }

        if (requestParameters['mailingCreateDto'] == null) {
            throw new runtime.RequiredError(
                'mailingCreateDto',
                'Required parameter "mailingCreateDto" was null or undefined when calling createMailing().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingCreateDtoToJSON(requestParameters['mailingCreateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDtoFromJSON(jsonValue));
    }

    /**
     * Create a new mailing.
     */
    async createMailing(requestParameters: CreateMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDto> {
        const response = await this.createMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the existing mailing.
     */
    async deleteMailingRaw(requestParameters: DeleteMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the existing mailing.
     */
    async deleteMailing(requestParameters: DeleteMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailingRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch available entities to connect to create new mailing.
     */
    async fetchAvailableConnectionsRaw(requestParameters: FetchAvailableConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingConnectionViewDto>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling fetchAvailableConnections().'
            );
        }

        if (requestParameters['editionId'] == null) {
            throw new runtime.RequiredError(
                'editionId',
                'Required parameter "editionId" was null or undefined when calling fetchAvailableConnections().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/connections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingConnectionViewDtoFromJSON(jsonValue));
    }

    /**
     * Fetch available entities to connect to create new mailing.
     */
    async fetchAvailableConnections(requestParameters: FetchAvailableConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingConnectionViewDto> {
        const response = await this.fetchAvailableConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the Mailing business object.
     */
    async fetchMailingRaw(requestParameters: FetchMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDtoFromJSON(jsonValue));
    }

    /**
     * Get the Mailing business object.
     */
    async fetchMailing(requestParameters: FetchMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDto> {
        const response = await this.fetchMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchMailingAnalyticsRaw(requestParameters: FetchMailingAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingAnalyticsDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingAnalytics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/analytics`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingAnalyticsDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchMailingAnalytics(requestParameters: FetchMailingAnalyticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingAnalyticsDto> {
        const response = await this.fetchMailingAnalyticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of mailing.
     */
    async fetchMailingHistoryRaw(requestParameters: FetchMailingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchMailingHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of mailing.
     */
    async fetchMailingHistory(requestParameters: FetchMailingHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchMailingHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchPossibleSendersRaw(requestParameters: FetchPossibleSendersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingSenderPageDto>> {
        if (requestParameters['senderTypeDto'] == null) {
            throw new runtime.RequiredError(
                'senderTypeDto',
                'Required parameter "senderTypeDto" was null or undefined when calling fetchPossibleSenders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/possible-senders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['senderTypeDto']!.map(SenderTypeDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingSenderPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchPossibleSenders(requestParameters: FetchPossibleSendersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingSenderPageDto> {
        const response = await this.fetchPossibleSendersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the api key from a section
     */
    async getApiKeyBySectionRaw(requestParameters: GetApiKeyBySectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getApiKeyBySection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/section/{id}/api-key`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the api key from a section
     */
    async getApiKeyBySection(requestParameters: GetApiKeyBySectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApiKeyBySectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A mailing that is being resend
     */
    async resendMailingRaw(requestParameters: ResendMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailingSendingInformationDto>>> {
        if (requestParameters['resendMailingDataDto'] == null) {
            throw new runtime.RequiredError(
                'resendMailingDataDto',
                'Required parameter "resendMailingDataDto" was null or undefined when calling resendMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/resend-mailing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendMailingDataDtoToJSON(requestParameters['resendMailingDataDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailingSendingInformationDtoFromJSON));
    }

    /**
     * A mailing that is being resend
     */
    async resendMailing(requestParameters: ResendMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailingSendingInformationDto>> {
        const response = await this.resendMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * save api key
     */
    async saveApiKeyRaw(requestParameters: SaveApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SectionEmailAddressDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling saveApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/section/{id}/api-key`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionEmailAddressDtoFromJSON));
    }

    /**
     * save api key
     */
    async saveApiKey(requestParameters: SaveApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SectionEmailAddressDto>> {
        const response = await this.saveApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for mailings.
     */
    async searchMailingsRaw(requestParameters: SearchMailingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for mailings.
     */
    async searchMailings(requestParameters: SearchMailingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingPageDto> {
        const response = await this.searchMailingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send mailing.
     */
    async sendMailingRaw(requestParameters: SendMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailingSendingInformationDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailingSendingInformationDtoFromJSON));
    }

    /**
     * Send mailing.
     */
    async sendMailing(requestParameters: SendMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailingSendingInformationDto>> {
        const response = await this.sendMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A mailing with specific email addresses sent in the context of a test mailing.
     */
    async testMailingRaw(requestParameters: TestMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailingSendingInformationDto>>> {
        if (requestParameters['testMailingDataDto'] == null) {
            throw new runtime.RequiredError(
                'testMailingDataDto',
                'Required parameter "testMailingDataDto" was null or undefined when calling testMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/test-mailing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestMailingDataDtoToJSON(requestParameters['testMailingDataDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailingSendingInformationDtoFromJSON));
    }

    /**
     * A mailing with specific email addresses sent in the context of a test mailing.
     */
    async testMailing(requestParameters: TestMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailingSendingInformationDto>> {
        const response = await this.testMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing Mailing.
     */
    async updateMailingRaw(requestParameters: UpdateMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailingDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailing().'
            );
        }

        if (requestParameters['mailingUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'mailingUpdateDto',
                'Required parameter "mailingUpdateDto" was null or undefined when calling updateMailing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailingUpdateDtoToJSON(requestParameters['mailingUpdateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing Mailing.
     */
    async updateMailing(requestParameters: UpdateMailingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailingDto> {
        const response = await this.updateMailingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check api key and return senders
     */
    async validateApiKeyRaw(requestParameters: ValidateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SectionEmailAddressDto>>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling validateApiKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mailings/section/check-api-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionEmailAddressDtoFromJSON));
    }

    /**
     * Check api key and return senders
     */
    async validateApiKey(requestParameters: ValidateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SectionEmailAddressDto>> {
        const response = await this.validateApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
