import { Button, forwardRef, HTMLChakraProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { ProductDto, ProductListItemDto, ProductStatusDto } from '../../../api';
import productApi from '../../../data-access/product-api';
import useToast from '../../../ui/use-toast/use-toast';
import ProductStatusToggleDialog from './product-status-toggel-dialog';

interface ProductStatusToggleButtonProps extends HTMLChakraProps<'button'> {
  product: ProductDto | ProductListItemDto;

  onSuccess?(): void;
}

function ProductStatusToggleButton(
  { children, product, onSuccess, ...props }: ProductStatusToggleButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('product');

  const shouldActivate = product.status === ProductStatusDto.ARCHIVED;

  const showSuccessToast = useToast({
    id: 'product-status-toggle-success-toast',
    status: 'success',
  });

  const handleToggle = async () => {
    invariant(product.id != null, 'Missing product id');

    if (shouldActivate) {
      await productApi.activateProduct({ id: product.id });
    } else {
      await productApi.archiveProduct({ id: product.id });
    }

    showSuccessToast({
      title: shouldActivate ? t('toast.activate.header') : t('toast.archive.header'),
      description: shouldActivate
        ? t('toast.activate.message', { name: product.title })
        : t('toast.archive.message', { name: product.title }),
    });
    onSuccess?.();

    onClose();
  };

  return (
    <>
      <Button
        ref={ref}
        {...props}
        aria-label={shouldActivate ? t('action.reactivate') : t('action.archive')}
        onClick={onOpen}
      >
        {children}
      </Button>
      <ProductStatusToggleDialog
        product={product}
        shouldActivate={shouldActivate}
        isOpen={isOpen}
        onClose={onClose}
        onToggle={handleToggle}
      />
    </>
  );
}

export default forwardRef<ProductStatusToggleButtonProps, 'button'>(ProductStatusToggleButton);
